import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import QuizStart from './Prepare/QuizStart';
import QuizDocuments from './Prepare/QuizDocuments';
import QuizSmell from './Prepare/QuizSmell';
import QuizExtLeft from './Prepare/QuizExtLeft';
import QuizExtBack from './Prepare/QuizExtBack';
import QuizExtRight from './Prepare/QuizExtRight';
import QuizExtFront from './Prepare/QuizExtFront';
import QuizExtRoof from './Prepare/QuizExtRoof';
import QuizInternal from './Prepare/QuizInternal';
import QuizRefueling from './Prepare/QuizRefueling';
import QuizMissedRefueling from "./Prepare/QuizMissedRefueling";
import QuizAccessories from './Prepare/QuizAccessories';
import QuizWash from './Prepare/QuizWash';
import QuizCheckNFill from './Prepare/QuizCheckNFill';
import QuizFinish from './Prepare/QuizFinish';
import {previousQiuz} from '../../../actions/Technician/cars';
import dashboardStyles  from './QuizPrepareStyle'
import theme from './QuizPrepareStyleTheme'
import {quizPages} from "../../../reducers/Technician/cars";
import * as selectors from "../../../selectors/cars";
import QuizInspectionReport from "./Prepare/QuizInspectionReport";

const quizComponents = {
  // Открыть автомобиль
  [quizPages.Start]: {
    component: ({car}) => <QuizStart car={car.last_id} />,
  },
  // Ключи документы
  [quizPages.Documents]: {
    component: () => <QuizDocuments />,
  },
  // Аксессуары
  [quizPages.Accessories]: {
    component: () => <QuizAccessories />,
  },
  // Внутренние повреждения
  [quizPages.Internal]: {
    component: ({car}) => <QuizInternal car={car} />,
  },
  // Запах
  [quizPages.Smell]: {
    component: () => <QuizSmell />,
  },
  // Мойка и чистка мусора
  [quizPages.Wash]: {
    component: () => <QuizWash />,
  },
  // Внешние повреждения
  [quizPages.ExtLeft]: {
    component: ({car}) => <QuizExtLeft car={car} />,
  },
  [quizPages.ExtBack]: {
    component: ({car}) => <QuizExtBack car={car} />,
  },
  [quizPages.ExtRight]: {
    component: ({car}) => <QuizExtRight car={car} />,
  },
  [quizPages.ExtFront]: {
    component: ({car}) => <QuizExtFront car={car} />,
  },
  [quizPages.ExtRoof]: {
    component: ({car}) => <QuizExtRoof car={car} />,
  },
  // Акт осмотра
  [quizPages.InspectionReport]: {
    component: ({car}) => <QuizInspectionReport car={car} />,
  },
  // Проверка давления колес, проверка техничеких жидкостей.
  [quizPages.CheckNFill]: {
    component: () => <QuizCheckNFill />,
  },
  [quizPages.MissedRefueling]: {
    component: ({car}) => <QuizMissedRefueling car={car}/>,
  },
  [quizPages.Refueling]: {
    component: ({car}) => <QuizRefueling  car={car} />,
  },
  [quizPages.Finish]: {
    component: () => <QuizFinish />,
  },
  [quizPages.Dashboard]: {
    component: () => <Redirect to="/dashboard" />,
  },
};

class QuizPrepare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localQuiz: 0,
    };
    this.car = this.props.match.params.car;
  }

  render() {
    const { classes, cars, quizPage, skipRefuel } = this.props;
    const car = cars.find((element) => element.last_id === +this.car);

    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Paper
            elevation={0}
            classes={{
              root: classes.secondBar,
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="center" justifyContent="flex-start" >
              <Grid item xs={2} sm={1}>
                <div className={classes.carColor} style={{ backgroundColor: car.color }} />
              </Grid>
              <Grid item xs={5} sm={2}>
                <div className={classes.carModel}>
                  {car.model}
                </div>
              </Grid>
              <Grid item xs={5} sm={2}>
                <div className={classes.carLicense}>
                  {car.license}
                </div>
              </Grid>
              <Grid item xs sm>
                {(quizPage !== quizPages.Start) && <>
                  <div className={classes.carStateTimeText}>
                    { skipRefuel || car.fuelToFullTank <= 5 ? 'Заправка' : 'Заправить'}
                  </div>
                  <div className={classes.fuelToFullTank}>
                    { skipRefuel || car.fuelToFullTank <= 5 ? 'Не требуется' : `${car.fuelToFullTank} л`}
                  </div>
                </>}
              </Grid>
              {car.statuses &&
              <Grid item xs sm>
                <div className={classes.carStateTimeText}>
                  Доставка
                </div>
                <div className={classes.fuelToFullTank}>
                  {car.statuses.haveDelivery ? 'Заказана' : 'Не заказана'}
                </div>
              </Grid> || null}
              {car.info && car.info.delivery && car.info.delivery.coordinates &&
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.carStateTimeText}>
                  Адрес
                </div>
                <div className={classes.carStateTimeText}>
                  <a target="_blank" href={`https://yandex.ru/maps/?pt=${car.info.delivery.coordinates[0]},${car.info.delivery.coordinates[1]}&z=17&l=map`}>
                    {car.info.delivery_address}
                  </a>
                </div>
              </Grid> || null}
            </Grid>

          </Paper>
          <div className={classes.page}>
            { quizComponents[quizPage]?.component({ car })}
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cars: state.techCars.cars,
    carsFetching: state.techCars.carsFetching,
    carsError: state.techCars.carsError,
    quizPage: selectors.getQuizPage(state),
    skipRefuel: state.techCars.skipRefuel,
  };
}

export default withStyles(dashboardStyles)(connect(mapStateToProps, { previousQiuz })(QuizPrepare));
