export const actions = {
    GET_BOOKING_LIST: 'GET_BOOKING_LIST',
    GET_BOOKING_LIST_SUCCESS: 'GET_BOOKING_LIST_SUCCESS',
    GET_BOOKING_LIST_FAIL: 'GET_BOOKING_LIST_FAIL',
    GET_BOOKING_DETAILS: 'GET_BOOKING_DETAILS',
    GET_BOOKING_DETAILS_SUCCESS: 'GET_BOOKING_DETAILS_SUCCESS',
    GET_BOOKING_DETAILS_FAIL: 'GET_BOOKING_DETAILS_FAIL',
    UPDATE_STATUS: 'UPDATE_STATUS',
    UPDATE_STATUS_SUCCESS: 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAIL: 'UPDATE_STATUS_FAIL',
};

const initialState = {
    bookingList: null,
    bookingListFetching: false,
    bookingListError: null,
    bookingDetails: null,
    updateStatus: null,
    updateStatusFetching: null,
    updateStatusError: null,
};

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_BOOKING_LIST:
            return {
                ...state,
                bookingListFetching: true
            };
        case actions.GET_BOOKING_LIST_SUCCESS:
            return {
                ...state,
                bookingListFetching: false,
                bookingList: action.payload,
                bookingListError: null
            };
        case actions.GET_BOOKING_LIST_FAIL:
            return {
                ...state,
                bookingListFetching: false,
                bookingList: null,
                bookingListError: action.payload
            };
        case actions.GET_BOOKING_DETAILS:
            return {
                ...state,
                bookingFetching: true
            };
        case actions.GET_BOOKING_DETAILS_SUCCESS:
            return {
                ...state,
                bookingFetching: false,
                bookingDetails: action.payload,
                bookingError: null
            };
        case actions.GET_BOOKING_DETAILS_FAIL:
            return {
                ...state,
                bookingFetching: false,
                bookingDetails: null,
                bookingError: action.payload
            };
        case actions.UPDATE_STATUS:
            return {
                ...state,
                updateStatusFetching: true
            };
        case actions.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                updateStatusFetching: false,
                updateStatus: action.payload,
                updateStatusError: null
            };
        case actions.UPDATE_STATUS_FAIL:
            return {
                ...state,
                updateStatusFetching: false,
                updateStatus: null,
                updateStatusError: action.payload
            };
        default:
            return state;
    }
}