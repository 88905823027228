import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IntlProvider} from 'react-intl';
import { ThemeProvider} from '@material-ui/styles';
import * as pages from './pages';
import { TechnicianNav } from './components';
// import messages from './messages';
import { fetchMessages } from './actions/messages';
import { login, logout } from './actions/auth';
import { HyundaiStyle } from './styles';
import { technicianTheme, authTheme } from './appTheme';
import RefuelingNav from './components/RefuelingNav';

const MINUTES_UNITL_AUTO_LOGOUT = 120; // минуты
const CHECK_INTERVAL = 30000; // миллисекунды
const STORE_KEY = 'lastAction';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.check();
    this.initListener();
    this.initInterval();
  }

  componentWillMount() {
    this.props.login();
    this.props.fetchMessages();
  }

  getLastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY));
  }

  setLastAction(lastAction) {
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeLeft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      this.props.logout();
      sessionStorage.clear();
    }
  }

  render() {
    const { isAuthenticated, lang, messages, roles } = this.props;
    // console.log('App', this.props);
    return (
      <IntlProvider
        locale={lang}
        messages={messages[lang]}
        onError={(err) => {}}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {!!isAuthenticated ? (
            roles.includes('ROLE_REFUELING') ? (
                <ThemeProvider theme={technicianTheme}>
                  <HyundaiStyle />
                  <RefuelingNav />
                  <Switch>
                    <Route path="/" exact component={withRouter(pages.Auth)} />
                    <Route path="/dashboard" exact component={withRouter(pages.Refueling)} />
                    <Route path="/refueling/:orderId" exact component={withRouter(pages.OrderRefueling)} />
                  </Switch>
                </ThemeProvider>
            ) :
            roles.includes('TECHNICIAN') && (
              <ThemeProvider theme={technicianTheme}>
                <HyundaiStyle />
                <TechnicianNav />
                <Switch>
                  <Route path="/" exact component={withRouter(pages.Auth)} />
                  <Route path="/dashboard" exact component={withRouter(pages.DashboardTech)} />
                  {/* <Route path="/dashboard" exact component={withRouter(pages.TechnicianList)} /> */}
                  { roles.includes('TECHNICIAN') && (
                    <Route path="/technicians" exact component={withRouter(pages.TechnicianList)} />
                  )}
                  <Route path="/quiz/prepare/:car" exact component={withRouter(pages.QuizPrepare)} />
                  <Route path="/quiz/repair/:car" exact component={withRouter(pages.QuizRepair)} />
                  <Route path="/quiz/newcar/:car" exact component={withRouter(pages.QuizNewCar)} />
                </Switch>
              </ThemeProvider>
            )
        ) : (
          <ThemeProvider theme={authTheme}>
            <HyundaiStyle />
            <Switch>
              <Route path="/dashboard" component={withRouter(pages.Auth)} />
              <Redirect to="/dashboard" />
            </Switch>
          </ThemeProvider>
        )}
      </IntlProvider>
    );
  }
}


App.propTypes = {
  logout: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  messages: PropTypes.objectOf(PropTypes.object),
  roles: PropTypes.arrayOf(PropTypes.string),
};
App.defaultProps = {
  roles: 'NONE',
};

function mapStateToProps(state) {
  // console.log('App', !!state.auth.auth && !!state.auth.auth.authkey);
  // console.log('App', state.auth, state.auth.auth.authkey, !!state.auth.auth.authkey);
  // !!state.auth.auth ? console.log('roles', Array.isArray(state.auth.auth.roles)) : console.log('roles');
  return {
    isAuthenticated: !!state.auth.auth && !!state.auth.auth.authkey,
    roles: !!state.auth.auth && state.auth.auth.roles,
    lang: state.profile.data.language,
    messages: state.messages.messages,
  };
}


export default connect(mapStateToProps, { logout, fetchMessages, login })(App);
