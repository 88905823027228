import { createGlobalStyle } from 'styled-components';

const OldStyle = createGlobalStyle`
  @font-face {
    font-family: 'Cera Pro Regular';
    src: local('Cera Pro Regular'), url(/fonts/CeraProRegular.otf) format('opentype');
  }
  @font-face {
    font-family: 'Cera Pro Bold';
    src: local('Cera Pro Bold'), url(/fonts/CeraProBold.otf) format('opentype');
  }
  @font-face {
    font-family: 'Cera Pro Light';
    src: local('Cera Pro Light'), url(/fonts/CeraProLight.otf) format('opentype');
  }
  @font-face {
    font-family: 'Cera Pro Medium';
    src: local('Cera Pro Medium'), url(/fonts/CeraProMedium.otf) format('opentype');
  }
  body {
    font-family: 'Cera Pro Regular', sans-serif !important;
    text-rendering: optimizeLegibility;
  }
  /* html5doctor.com Reset v1.6.1 - http://cssreset.com */
  @font-face {
    /* font-family: 'Cera Pro Regular'; */
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/CeraProRegular.otf');
    src: local('Cera Pro Regular'), local('CeraProRegular'),
    url('/fonts/CeraProRegular.otf') format('opentype');
  }

  *{margin:0;padding:0;}
  html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}body{line-height:1}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{display:block}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}ins{background-color:#ff9;color:#000;text-decoration:none}mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}input,select{vertical-align:middle}
  ul, li { list-style: none; }
  hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

  i { font-style: normal; }
  a { text-decoration: none;  color:#4A90E2;}
  h1{ font-size: 23px; font-weight: 300;}
  h2{ font-size:20px; }
  h3{ font-size:18px; }
  h4{ font-size:16px; }
  h5{ font-size:16px;font-weight: 200;}
  /* html5doctor.com Reset v1.6.1 - http://cssreset.com */
`;

const HyundaiStyle = createGlobalStyle`
  @font-face {
    font-family: 'HyundaiSansHead';
    src: url(/fonts/HyundaiSansHead-Regular.otf) format('opentype');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: 'HyundaiSansHead';
    src: url(/fonts/HyundaiSansHead-Medium.otf) format('opentype');
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: 'HyundaiSansHead';
    src: url(/fonts/HyundaiSansHead-Bold.otf) format('opentype');
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: 'HyundaiSansHead';
    src: url(/fonts/HyundaiSansHead-Light.otf) format('opentype');
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-Regular.otf) format('opentype');
    font-style: normal;
    font-weight: 500;
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-Medium.otf) format('opentype');
    font-style: normal;
    font-weight: 600;
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-MediumItalic.otf) format('opentype');
    font-style: italic;
    font-weight: 600;
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-Bold.otf) format('opentype');
    font-style: normal;
    font-weight: 700;
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-BoldItalic.otf) format('opentype');
    font-style: italic;
    font-weight: 700;
  }
  @font-face {
    font-family: 'HyundaiSansText';
    src: url(/fonts/HyundaiSansText-Italic.otf) format('opentype');
    font-style: italic;
    font-weight: 500;
  }
  body {
    font-family: 'HyundaiSansText', sans-serif !important;
    text-rendering: optimizeLegibility;
  }
  /* html5doctor.com Reset v1.6.1 - http://cssreset.com */
  @font-face {
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/HyundaiSansText-Regular.otf');
  }

  *{margin:0;padding:0;}
  html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;vertical-align:baseline;background:transparent}body{line-height:1}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{display:block}nav ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent}ins{background-color:#ff9;color:#000;text-decoration:none}mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}del{text-decoration:line-through}abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}table{border-collapse:collapse;border-spacing:0}hr{display:block;height:1px;border:0;border-top:1px solid #ccc;margin:1em 0;padding:0}input,select{vertical-align:middle}
  ul, li { list-style: none; }
  hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

  i { font-style: normal; }
  a { text-decoration: none;  color:#4A90E2;}
  h1{ font-size: 23px; font-weight: 300;}
  h2{ font-size:20px; }
  h3{ font-size:18px; }
  h4{ font-size:16px; }
  h5{ font-size:16px;font-weight: 200;}
  /* html5doctor.com Reset v1.6.1 - http://cssreset.com */
`;

export { OldStyle, HyundaiStyle };
