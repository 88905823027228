import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';
// import Key from "@material-ui/icons/VpnKeyTwoTone";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import '../../../assets/css/style.css';
import { openCar } from '../../../../actions/Technician/cars';
import quizStyles from './style';
// import GearLoading from '../../../components/Technician/gearLoading';

const QuizStart = (props) => {
  const { classes, user, car, openCarsFetching } = props;
  // console.log('QuizStart', props);

  return (
    <React.Fragment>
      <div className={classes.body}>
        <div className={classes.title}>
          Подойдите к автомобилю и начните проверку.
        </div>
      </div>
      {/* <div> */}
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          variant="contained"
          disabled={openCarsFetching}
          classes={{
            root: classes.rootButton,
            label: classes.labelButton,
          }}
          onClick={() => props.openCar(car)}
        >
          Открыть автомобиль
        </Button>
        {openCarsFetching && <CircularProgress size={30} color="primary" />}
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    // cars: state.techCars.cars,
    openCarsFetching: state.techCars.openCarsFetching,
    // carsError: state.techCars.carsError,
    // quiz: state.quiz,
    // serviceFetching: state.techCars.serviceFetching,
    user: state.auth.auth.email,
  };
}

export default withTheme(withStyles(quizStyles)(connect(mapStateToProps, { openCar })(QuizStart)));
