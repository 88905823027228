import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Wrapper } from './styledComponents';
import { getButtonsByStatus } from '../../logic';

const OrderRefuelingButtons = props => {
    const {
        status,
        classes,
        vehicleId,
        refuelingId,
        orderFinishTime,
        openDoorVehicleById,
        closeDoorVehicleById,
        getControlButtonsDisabling,
        startRefuelingVehicleById,
        finishRefuelingVehicleById,
        turnOnEmergencyVehicleById,
    } = props;

    const buttons = useMemo(() => getButtonsByStatus(status, orderFinishTime), [status, orderFinishTime]);

    const onClickButton = useCallback((button) => {
        switch (button) {
            case 'emergency': {
                turnOnEmergencyVehicleById(vehicleId);
                break;
            }
            case 'startRefueling': {
                startRefuelingVehicleById(refuelingId);
                break;
            }
            case 'finishRefueling': {
                finishRefuelingVehicleById({refuelingId, vehicleId});
                break;
            }
            case 'openDoor': {
                openDoorVehicleById(vehicleId);
                break;
            }
            case 'closeDoor': {
                closeDoorVehicleById(vehicleId);
                break;
            }
            default: {
                break;
            }
        }
    }, [
        vehicleId,
        refuelingId,
        openDoorVehicleById,
        closeDoorVehicleById,
        startRefuelingVehicleById,
        finishRefuelingVehicleById,
        turnOnEmergencyVehicleById,
    ]);

    return (
        <Wrapper>
            <Grid container
                  spacing={2}
                  className={classes.buttons}
            >
                {buttons.map(button => (
                    <Grid item
                          key={button.value}
                    >
                        <Button style={{ backgroundColor: button.color }}
                                variant="contained"
                                onClick={() => onClickButton(button.value)}
                                disabled={getControlButtonsDisabling}
                        >
                            {button.name}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Wrapper>
    );
};

OrderRefuelingButtons.propTypes = {
    status: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    vehicleId: PropTypes.string.isRequired,
    refuelingId: PropTypes.string.isRequired,
    orderFinishTime: PropTypes.string,
    openDoorVehicleById: PropTypes.func.isRequired,
    closeDoorVehicleById: PropTypes.func.isRequired,
    getControlButtonsDisabling: PropTypes.bool.isRequired,
    startRefuelingVehicleById: PropTypes.func.isRequired,
    finishRefuelingVehicleById: PropTypes.func.isRequired,
    turnOnEmergencyVehicleById: PropTypes.func.isRequired,
};

export default OrderRefuelingButtons;
