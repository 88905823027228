const dashboardStyles = () => ({
  secondBar: {
    backgroundColor: '#f6f3f2',
    // height: '85px',
    // display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    top: '92px',
    position: 'relative',
    padding: '1em',
    fontSize: '18px',
    '@media (max-width: 376px)': {
      fontSize: 14,
    },
  },
  carInfo: {
    // display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    // width: '50%',
  },
  carColor: {
    width: '32px',
    height: '32px',
    borderRadius: '7px',
    // flex: '0 1 auto',
    boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
  },
  carModel: {
    // paddingLeft: '10px',
    // flex: '1 1 auto',
    whiteSpace: 'nowrap',
    fontSize: '1.2em',
    fontWeight: '600',
    letterSpacing: '-0.39px',
    color: '#333355',
    padding: '0 0.5em',
  },
  carLicense: {
    whiteSpace: 'nowrap',
    padding: '0.5em 1em',
    // flex: '0 1 auto',
    borderRadius: '5px',
    backgroundColor: 'white',
    fontSize: '1em',
    fontWeight: '700',
    letterSpacing: '-0.39px',
    // textAlign: 'right',
    color: '#2a2a2a',
    display: 'inline',
  },
  fuelToFullTank:{
    fontSize: '1.2em',
    fontWeight: '600',
    letterSpacing: '-0.39px',
    color: '#000000',
    textAlign: 'center',
    padding: '0 0.5em',
  },
  carStateTimeBlock: {},
  carStateTime: {
    fontSize: '1.5em',
    fontWeight: '600',
    textAlign: 'center',
    color: '#333355',
  },
  carStateTimeText: {
    fontSize: '1em',
    fontWeight: '700',
    textAlign: 'center',
    color: '#333355',
  },
  page: {
    top: '92px',
    position: 'relative',
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 290px)',
  },
  loading: {
    top: '177px',
    width: '100%',
    height: 'calc(100% - 177px)',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'rgba(255,255,255,0.7)',
  }
});
export default dashboardStyles;
