import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import AddAPhotoRounded from "@material-ui/icons/AddAPhotoRounded";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import quizStyles from "../../pages/Technician/Quiz/Prepare/style";
import {apiPrefix} from "../../api";

const PhotoInput = withStyles(quizStyles)((props) => {
    const {
        classes, photoKey, fileInput, errorMessage, photoDescr, errorCond, photoPath, authkey, maxHeight, saveClicked, photoIdx,
        addPhotoHandler, removePhotoHandler, handleFullScreenPhoto,
    } = props;
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} className={classes.addPhotoFrame}>
                    <input
                        accept="image/*"
                        className={classes.input}
                        style={{display: 'none'}}
                        id={photoKey}
                        multiple
                        type="file"
                        ref={fileInput}
                        onClick={event => event.target.value = ''}
                        onChange={event => addPhotoHandler(event, photoKey)}
                    />
                    <label htmlFor={photoKey}
                           style={{textAlign: 'center'}}>
                        <ButtonBase
                            variant="outlined"
                            component="span"
                            className={classes.addPhotoButton}
                            style={saveClicked && !photoPath
                                ? {border: '1px solid #f50057'}
                                : !photoPath
                                    ? {border: '1px solid #ccc'}
                                    : {border: '1px solid #00FF00'}}
                        >
                            <AddAPhotoRounded className={classes.addPhoto}/>
                        </ButtonBase>
                        {errorCond && errorMessage &&
                        <FormHelperText error={true}>{`${errorMessage}`}</FormHelperText>}
                    </label>
                    <div className={classes.textAddPhoto}>
                        {`${photoDescr}`}
                    </div>

                </Grid>
                {photoPath &&
                <Grid item xs={12} sm={4} key={'miniImage' + photoPath}>
                    <div className={classes.photoFrame}>
                        <ButtonBase
                            className={classes.paperButton}
                            onClick={() => handleFullScreenPhoto(photoIdx)}
                            focusRipple
                        >
                            <img
                                // src={process.env.REACT_APP_API_SERVER + '/api/tech/images/' + photoPath + '?token=' + authkey}
                                src={`${process.env.REACT_APP_API_SERVER}${apiPrefix}/images/${photoPath}?token=${authkey}`}
                                alt=""
                                style={{maxHeight: maxHeight || '150px'}}
                            />
                            <IconButton
                                component="span"
                                size="small"
                                color="secondary"
                                className={classes.removePhotoButton}
                                onClick={(event) => removePhotoHandler(event, photoKey)}
                            >
                                <Close color="secondary"
                                       className={classes.removePhotoIcon}/>
                            </IconButton>
                        </ButtonBase>
                    </div>
                </Grid>
                }

            </Grid>
        </Grid>
    );
});

export default (PhotoInput);