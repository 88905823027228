import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import editIcon from '../../../../assets/images/icMenuCheck.svg';
import Button from '@material-ui/core/Button';
import {sendQuizData} from '../../../../actions/Technician/cars';
import QuizButton from '../../../../components/Technician/QuizButton';
import quizStyles from './style';

// import GearLoading from '../../../components/Technician/gearLoading';

class QuizRefueling extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fuelCardSelected: null, // !!props.service.fuelCardSelected,
            fuelToFullTank: null,
            tank_opacity: null,
            deviceLevel_fuel1: null
        };
        // this.car = this.props.match.params.car;
    }


    render() {
        // const [value, setValue] = React.useState(null);
        const {classes, user, quiz, carVin, carsFetching, lastUsedFuelCard, fuelCards, service, car} = this.props;
        // console.log('QuizCheckNFill', this);
        // const fuelCards = this.state.fuelCards || [];
        //     [
        //     '6546523123',
        //     '8132113654',
        //     '6413584342',
        //     '4946584684',
        // ];
        // console.log({fuelCards})
        // if (car.fuelToFullTank > 6) {
        this.state.fuelToFullTank = car.fuelToFullTank;
        this.state.tank_opacity = car.tank_opacity;
        this.state.deviceLevel_fuel1 = car.deviceLevel_fuel1;
        // }
        if (lastUsedFuelCard && !this.state.fuelCardSelected) {
            console.warn('select last card')
            this.state.fuelCardSelected = lastUsedFuelCard;
        }
        if (fuelCards.length === 1 && !this.state.fuelCardSelected) {
            console.warn('set first card')
            this.state.fuelCardSelected = fuelCards[0];
        }
        const handlefuelCardsSelect = (event, newValue) => {
            console.log({event, newValue})
            this.state.fuelCardSelected = newValue;
        };

        return (
            <React.Fragment>
                <div className={classes.body}>
                    <div className={classes.title}>
                        Топливо
                    </div>
                    <div className={classes.boxContainer}>
                        <div className={classes.boxInfo1}>
                            <div className={classes.boxTtl}>Долить</div>
                            <div className={classes.boxValue}>{car.fuelToFullTank} л</div>
                            <div className={classes.boxCaption}>{car.fuelType}</div>
                        </div>
                        <div className={classes.boxInfo2}>
                            <div className={classes.boxTtl}></div>
                            <div className={classes.boxValue}>{car.deviceLevel_fuel1}/<span
                                className={classes.boxValue2}>{car.tank_opacity}</span>л
                            </div>
                            <div className={classes.boxCaption}>Осталось</div>
                        </div>
                    </div>

                    <div className={classes.text} style={{margin: '36px 0 28px 0'}}>
                        {(car.fuelToFullTank < 6) ? 'Заправка автомобиля не требуется.' : 'Выбирите топливную карту и заправьте автомобиль.'}
                    </div>
                    {(car.fuelToFullTank > 6) ?

                        <div className={classes.cardSelectBox}>
                            {fuelCards.map((fuelCard, cardIdx) =>
                                <Button key={'fuelCardBtn-' + fuelCard}
                                    //in={this.fuelCardSelected === fuelCard}
                                        value={fuelCard}
                                        onChange={handlefuelCardsSelect}
                                        onClick={() => this.setState({fuelCardSelected: fuelCard})}
                                        className={this.state.fuelCardSelected === fuelCard ? classes.cardSelectBtnSelected : classes.cardSelectBtnAllow}>
                                    <div
                                        className={this.state.fuelCardSelected === fuelCard ? classes.cardBoxBtnTextSelected : classes.cardBoxBtnText}>
                                        {this.state.fuelCardSelected === fuelCard ?
                                            <img src={editIcon} height="18" width="20" alt=""
                                                 style={{margin: '0 7px 0 0'}}/> : ''}


                                        {fuelCard}</div>
                                </Button>
                            )}
                        </div>
                        : null}
                </div>


                <QuizButton
                    service={{...service, ...this.state}}
                    label="Продолжить"
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // cars: state.techCars.cars,
        // carsFetching: state.techCars.carsFetching,
        // carsError: state.techCars.carsError,
        lastUsedFuelCard: state.techCars.lastUsedFuelCard,
        fuelCards: state.techCars.fuelCards,
        serviceFetching: state.techCars.serviceFetching,
        quiz: state.techCars.quiz,
        service: state.techCars.service,
        user: state.auth.auth.email,
    };
}

export default withStyles(quizStyles)(connect(mapStateToProps, {sendQuizData})(QuizRefueling));
