import api from '../api';
import { actions } from '../reducers/devices';


export const getDevices = () => (dispatch) => {
    dispatch({type: actions.GET_DEVICES});
    return api.devices.fetchAll()
        .then(response => {
            dispatch({type: actions.GET_DEVICES_SUCCESS, payload: response.data});
            return response.data
        })
        .catch(error => {
            dispatch({type: actions.GET_DEVICES_FAIL, payload: error})
        })
};

export const getDeviceDetails = (id) => (dispatch) => {
    dispatch({type: actions.DEVICE_DETAILS});
    api.devices.fetchOne(id)
        .then(response => {
            dispatch({type: actions.DEVICE_DETAILS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.DEVICE_DETAILS_FAIL, payload: error})
        })
};

export const saveDevice = (device_id) => (dispatch) => {
    dispatch({type: actions.SAVE_DEVICE});
    return api.devices.saveDevice(device_id)
        .then(response => {
            dispatch({type: actions.SAVE_DEVICE_SUCCESS, payload: response.data});
            return response;
        })
        .catch(error => {
            dispatch({type: actions.SAVE_DEVICE_FAIL, payload: error})
        })
};

export const deleteDevice = (device_id) => (dispatch) => {
    dispatch({type: actions.DELETE_DEVICE});
    return api.devices.deleteDevice(device_id)
        .then(response => {
            dispatch({type: actions.DELETE_DEVICE_SUCCESS, payload: response});
            return response;
        })
        .catch(error => {
            dispatch({type: actions.DELETE_DEVICE_FAIL, payload: error});
            return error;
        })
};