import api from '../api';
import { actions } from '../reducers/booking';


export const getBookingList = () => (dispatch) => {
    dispatch({type: actions.GET_BOOKING_LIST});
    api.booking.fetchAll()
        .then(response => {
            dispatch({type: actions.GET_BOOKING_LIST_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.GET_BOOKING_LIST_FAIL, payload: error})
        })
};

export const getBookingDetails = (id) => (dispatch) => {
    dispatch({type: actions.GET_BOOKING_DETAILS});
    api.booking.fetchOne(id)
        .then(response => {
            dispatch({type: actions.GET_BOOKING_DETAILS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.GET_BOOKING_DETAILS_FAIL, payload: error})
        })
};

export const updateStatus = (order_id) => (dispatch) => {
    dispatch({type: actions.UPDATE_STATUS});
    api.booking.updateStatus(order_id)
        .then(response => {
            dispatch({type: actions.UPDATE_STATUS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.UPDATE_STATUS_FAIL, payload: error})
        })
};