const delayTime = async (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const retryWithDelay = async (source, countRetry, delay, conditionFn) => {
    if (countRetry === 0) {
        throw 'ConditionFalse';
    }

    try {
        const response = await source();
        if (conditionFn(response)) {
            return response;
        }

        await delayTime(delay);
        return retryWithDelay(source, --countRetry, delay, conditionFn);
    } catch (e) {
        throw 'NetworkError';
    }
}

