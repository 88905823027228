import Refueling from './Refueling';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getRefuelingStyles } from './logic';
import { getOrdersRefueling } from '../../actions/Refueling/refueling';
import * as selectors from '../../selectors/refueling';

export const mapStateToProps = state => ({
    ordersFetching: selectors.getOrdersFetching(state),
    ordersRefueling: selectors.getOrdersRefueling(state),
    ordersRefuelingByFilters: selectors.getOrdersRefuelingByFilters(state),
    selectedStatusRefueling: selectors.getSelectedStatusRefueling(state),
});

export default withStyles(getRefuelingStyles)(connect(
    mapStateToProps,
    { getOrdersRefueling }
)(Refueling));
