export const actions = {
    GET_DEVICES: 'GET_DEVICES',
    GET_DEVICES_SUCCESS: 'GET_DEVICES_SUCCESS',
    GET_DEVICES_FAIL: 'GET_DEVICES_FAIL',
    DEVICE_DETAILS: 'DEVICE_DETAILS',
    DEVICE_DETAILS_SUCCESS: 'DEVICE_DETAILS_SUCCESS',
    DEVICE_DETAILS_FAIL: 'DEVICE_DETAILS_FAIL',
    SAVE_DEVICE: 'SAVE_DEVICE',
    SAVE_DEVICE_SUCCESS: 'SAVE_DEVICE_SUCCESS',
    SAVE_DEVICE_FAIL: 'SAVE_DEVICE_FAIL',
    DELETE_DEVICE: 'DELETE_DEVICE',
    DELETE_DEVICE_SUCCESS: 'DELETE_DEVICE_SUCCESS',
    DELETE_DEVICE_FAIL: 'DELETE_DEVICE_FAIL',
};

const initialState = {
    devices: null,
    devicesFetching: false,
    devicesError: null,
    device: null,
    deviceFetching: null,
    deviceError: null,
    saveDevice: null,
    saveDeviceFetching: null,
    saveDeviceError: null,
    deleteDevice: null,
    deleteDeviceFetching: null,
    deleteDeviceError: null,
};

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_DEVICES:
            return {
                ...state,
                devicesFetching: true
            };
        case actions.GET_DEVICES_SUCCESS:
            return {
                ...state,
                devicesFetching: false,
                devices: action.payload,
                devicesError: null
            };
        case actions.GET_DEVICES_FAIL:
            return {
                ...state,
                devicesFetching: false,
                devices: null,
                devicesError: action.payload
            };
        case actions.DEVICE_DETAILS:
            return {
                ...state,
                deviceFetching: true
            };
        case actions.DEVICE_DETAILS_SUCCESS:
            return {
                ...state,
                deviceFetching: false,
                device: action.payload,
                deviceError: null
            };
        case actions.DEVICE_DETAILS_FAIL:
            return {
                ...state,
                deviceFetching: false,
                device: null,
                deviceError: action.payload
            };
        case actions.SAVE_DEVICE:
            return {
                ...state,
                saveDeviceFetching: true
            };
        case actions.SAVE_DEVICE_SUCCESS:
            return {
                ...state,
                saveDeviceFetching: false,
                saveDevice: action.payload,
                saveDeviceError: null
            };
        case actions.SAVE_DEVICE_FAIL:
            return {
                ...state,
                saveDeviceFetching: false,
                saveDevice: null,
                saveDeviceError: action.payload
            };
        case actions.DELETE_DEVICE:
            return {
                ...state,
                deleteDeviceFetching: true
            };
        case actions.DELETE_DEVICE_SUCCESS:
            return {
                ...state,
                deleteDeviceFetching: false,
                deleteDevice: action.payload,
                deleteDeviceError: null
            };
        case actions.DELETE_DEVICE_FAIL:
            return {
                ...state,
                deleteDeviceFetching: false,
                deleteDevice: null,
                deleteDeviceError: action.payload
            };
        default:
            return state;
    }
}