import Statuses from './Statuses';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getRefuelingStyles } from '../../logic';
import { getOrdersRefueling, setSelectedStatusRefueling } from '../../../../actions/Refueling/refueling';
import * as selectors from '../../../../selectors/refueling';

export const mapStateToProps = state => ({
    ordersRefueling: selectors.getOrdersRefueling(state),
    statusesRefueling: selectors.getStatusesRefueling(state),
    selectedStatusRefueling: selectors.getSelectedStatusRefueling(state),
});

export default withStyles(getRefuelingStyles)(connect(
    mapStateToProps,
    { getOrdersRefueling, setSelectedStatusRefueling }
)(Statuses));
