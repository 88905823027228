import { actions } from '../../reducers/Technician/technicians';
import api from '../../api';

export const getTechnicians = () => (dispatch) => {
  dispatch({ type: actions.GET_TECHS });
  const params = { params: {}, id: 1 };
  console.log('getTechnicians');
  return api.techAdmin.getTechnicians(params)
    .then((response) => {
      // console.log('response.result', response.result);
      dispatch({ type: actions.GET_TECHS_SUCCESS, payload: response.result });
      return true;
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({ type: actions.GET_TECHS_FAIL, payload: error });
      return false;
    });
};

export const createTechnician = data => (dispatch) => {
  dispatch({ type: actions.EDIT_TECH });
  const number = parseInt(data.phoneNum, 10);
  const params = Number.isNaN(number) ? {
    params: { data },
    id: 1,
  } : {
    params: {
      ...data,
      phoneNum: number,
    },
    id: 1,
  };
  // console.log('getTechnicians');
  return api.techAdmin.createTechnician(params)
    .then((response) => {
      // console.log('response.result', response.result);
      if (response.result !== 'ok') {
        throw response.error.message;
      } else {
        dispatch({ type: actions.EDIT_TECH_SUCCESS });
        return true;
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({ type: actions.EDIT_TECH_FAIL, payload: error });
      return error;
    });
};

export const editTechnician = data => (dispatch) => {
  dispatch({ type: actions.EDIT_TECH });
  const number = parseInt(data.phoneNum, 10);
  const params = Number.isNaN(number) ? {
    params: { data },
    id: 1,
  } : {
    params: {
      ...data,
      phoneNum: number,
    },
    id: 1,
  };
  // console.log('getTechnicians');
  return api.techAdmin.editTechnician(params)
    .then((response) => {
      if (response.result !== 'ok') {
        throw response.error.message;
      } else {
        dispatch({ type: actions.EDIT_TECH_SUCCESS });
        return true;
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({ type: actions.EDIT_TECH_FAIL, payload: error });
      return error;
    });
};

export const removeTechnician = lastId => (dispatch) => {
  dispatch({ type: actions.EDIT_TECH });
  const params = {
    params: {
      last_id: lastId,
    },
    id: 1,
  };
  // console.log('getTechnicians');
  return api.techAdmin.removeTechnician(params)
    .then((response) => {
      console.log('response.result', response.result);
      if (response.result !== 'ok') {
        throw response.error.message;
      } else {
        dispatch({ type: actions.EDIT_TECH_SUCCESS });
        return true;
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({ type: actions.EDIT_TECH_FAIL, payload: error });
      return false;
    });
};
