import api from '../api';
import { actions } from '../reducers/documents'

export const getDocuments = () => (dispatch) => {
    dispatch({type: actions.GET_DOCUMENTS});
    return api.documents.fetchAll()
        .then(response => {
            dispatch({type: actions.GET_DOCUMENTS_SUCCESS, payload: response.data});
            return response.data;
        })
        .catch(error => {
            dispatch({type: actions.GET_DOCUMENTS_FAIL, payload: error})
        })
}

export const getSingleDocument = (id) => (dispatch) => {
    dispatch({type: actions.GET_ONE_DOCUMENT});
    api.documents.fetchOne(id)
        .then(response => {
            dispatch({type: actions.GET_ONE_DOCUMENT_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.GET_ONE_DOCUMENT_FAIL, payload: error})
        })
}

export const getDocumentData = () => (dispatch) => {
    dispatch({type: actions.GET_DOCUMENT_DATA});
    api.documents.fetchDocumentData()
        .then(response => {
            dispatch({type: actions.GET_DOCUMENT_DATA_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.GET_DOCUMENT_DATA_FAIL, payload: error})
        })
}

export const disallowAccess = (last_id) => (dispatch) => {
    dispatch({type: actions.DISALLOW_ACCESS});
    api.documents.disallowAccess(last_id)
        .then(response => {
            dispatch({type: actions.DISALLOW_ACCESS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.DISALLOW_ACCESS_FAIL, payload: error})
        })
}

export const allowAccess = (last_id) => (dispatch) => {
    dispatch({type: actions.ALLOW_ACCESS});
    api.documents.allowAccess(last_id)
        .then(response => {
            dispatch({type: actions.ALLOW_ACCESS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.ALLOW_ACCESS_FAIL, payload: error})
        })
}

export const saveAccess = (last_id) => (dispatch) => {
    dispatch({type: actions.SAVE_ACCESS});
    api.documents.saveAccess(last_id)
        .then(response => {
            dispatch({type: actions.SAVE_ACCESS_SUCCESS, payload: response.data})
        })
        .catch(error => {
            dispatch({type: actions.SAVE_ACCESS_FAIL, payload: error})
        })
}