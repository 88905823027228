export const actions = {
  GET_PROFILE: 'GET_PROFILE',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAIL: 'GET_PROFILE_FAIL',
  SET_LANGUAGE: 'SET_LANGUAGE',
};

const initialState = {
  data: {
    locationCoordinates: { lat: 55.7558, lng: 37.6173 },
    language: 'ru',
  },
  profileFetching: false,
  profileError: null,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case actions.GET_PROFILE:
      return {
        ...state,
        profileFetching: true,
      };
    case actions.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profileFetching: false,
        data: action.payload,
        profileError: null,
      };
    case actions.GET_PROFILE_FAIL:
      return {
        ...state,
        profileFetching: false,
        data: null,
        profileError: action.payload,
      };
    case actions.SET_LANGUAGE:
      return {
        ...state,
        data: {
          ...state.profile,
          language: action.payload,
        },
      };
    default:
      return state;
  }
}
