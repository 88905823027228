import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getRefuelingStyles } from '../../logic';
import {
    setPaginationPageRefueling,
    setSearchTermRefueling,
    setSortRefueling,
} from '../../../../actions/Refueling/refueling';
import * as selectors from '../../../../selectors/refueling';
import NavigationRefueling from "./NavigationRefueling";

export const mapStateToProps = state => ({
    paginationCountRefueling: selectors.getPaginationCountRefueling(state),
    paginationPageRefueling: selectors.getPaginationPageRefueling(state),
    searchTermRefueling: selectors.getSearchTermRefueling(state),
    sortRefueling: selectors.getSortRefueling(state),
});

export default withStyles(getRefuelingStyles)(connect(
    mapStateToProps,
    { setSearchTermRefueling, setPaginationPageRefueling, setSortRefueling }
)(NavigationRefueling));
