import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import Exit from '@material-ui/icons/ExitToApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout, updateApp } from '../actions/auth';
import { previousQiuz, setSelectedStatus } from '../actions/Technician/cars';
import { technicianTheme } from '../appTheme';
import menuIcon from '../assets/images/icon_mainmenu.svg';
import logo from '../assets/images/logo.svg';
import Statuses from './Technician/Statuses';

// const theme = createMuiTheme({

//   palette: {
//     primary: {
//       light: blue[50],
//       main: '#fff',
//       dark: blue[200],
//       contrastText: '#333355',
//     },
//     // primary: blue,
//     secondary: {
//       light: '#33bbdb',
//       main: '#00aad2',
//       dark: '#007693',
//       // contrastText: '#333355',
//     },
//     default: {
//       light: '#33bbdb',
//       main: '#00aad2',
//       dark: '#007693',
//     },
//     success: {
//       main: '#ff0000',
//       contrastText: '#0000ff',
//     },
//   },
// });


const styles = () => ({
  appBar: {
    width: '100vw',
    backgroundColor: 'white',
    // height: '177px',
    position: 'fixed',
    textAlign: 'center',
    // zIndex: '5',
    boxShadow: 'none',
    // boxShadow: '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
    // '-webkit-box-shadow': '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
    // '-moz-box-shadow': '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
  },
  topBar: {
    height: '92px',
    display: 'flex',
  },
  title: {
    margin: 'auto',
    fontFamily: 'HyundaiSansHead',
    fontSize: '24px',
    fontWeight: '600',
    color: '#333355',
  },
  drawer: {
    justifyContent: 'space-between',
    maxWidth: '60%',
    width: '300px',
  },
  menuTitle: {
    fontFamily: 'HyundaiSansHead',
    fontSize: '24px',
    fontWeight: '600',
    color: '#333355',
    margin: '0 auto 10px',
  },
  menuBar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // height: '100%',
  },
  techniciansList: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '20px 0',
  },
  exitButton: {
    margin: '20px auto',
  },
  menuButtonRoot: {
    color: '#00aad2',
    padding: '4px',
  },
  backButtonRoot: {
    color: '#00aad2',
  },
  backButtonIcon: {
    width: '40px',
    height: '40px',
  },
  img: {
    width: '86px',
    height: '86px',
    alignSelf: 'center',
  },
  buttonIcon: {
    paddingLeft: 5,
  },
  drawerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});


class TechnicianNav extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      sidebarOpen: false,
      // modalOpen: false,
      // selectedFile: null,
    };
  }

  componentDidMount() {
    // this.props.getSideBarBadges();
  }

    openDrawer = () => {
      this.setState({ sidebarOpen: true });
    };

    closeDrawer = () => {
      this.setState({ sidebarOpen: false });
    };


    fileChangedHandler = (event) => {
      this.setState({ selectedFile: event.target.files[0] });
    }

    goBack = () => {
      this.props.quiz === 1 ? this.props.history.goBack() : this.props.previousQiuz();
    }

    render() {
      const {
        classes, statuses, roles, setSelectedStatus, selectedStatus,
      } = this.props;
      // console.log('TechnicianNav', this.props);
      let title = 'Очередь';
      let backButtonShow = false;
      const { pathname } = this.props.location;
      if (typeof pathname === 'string') {
        if (pathname.indexOf('prepare') !== -1) {
          title = 'Подготовка';
          backButtonShow = true;
        } else if (pathname.indexOf('repair') !== -1) {
          title = 'Ремонт';
          backButtonShow = true;
        } else if (pathname.indexOf('newcar') !== -1) {
          title = 'Новая';
          backButtonShow = true;
        } else if (pathname.indexOf('technicians') !== -1) {
          title = 'Техники';
          backButtonShow = true;
        }
      }

      return (
        <ThemeProvider theme={technicianTheme}>
          <AppBar
            position="static"
            color="primary"
            classes={{
              root: classes.appBar,
            }}
          >
            <Toolbar
                        // disableGutters
              className={classes.topBar}
            >
              {backButtonShow
                ? (
                  <IconButton
                    classes={{
                      root: classes.backButtonRoot, // class name, e.g. `classes-nesting-root-x`
                    }}
                    aria-label="Back"
                    onClick={this.goBack}
                  >
                    <ArrowBack className={classes.backButtonIcon} />
                  </IconButton>
                ) : (
                  <div className={classes.backButtonIcon} />
                )
                        }
              <Typography variant="h6" color="inherit" className={classes.title}>
                {title}
              </Typography>
              <IconButton
                            // children
                classes={{
                  root: classes.menuButtonRoot, // class name, e.g. `classes-nesting-root-x`
                }}
                aria-label="Menu"
                onClick={this.openDrawer}
              >
                <img src={menuIcon} height="46" width="46" alt="" />
              </IconButton>
            </Toolbar>

          </AppBar>
          <Drawer
            open={this.state.sidebarOpen}
            onClose={this.closeDrawer}
            anchor="right"
            classes={{
              paper: classes.drawer,
            }}
          >
            <img src={logo} alt="" className={classes.img} />
            <div className={classes.menuBar}>
              <Typography variant="h6" color="inherit" className={classes.menuTitle}>
                Очередища
              </Typography>
              <Statuses
                statuses={statuses}
                statusClick={setSelectedStatus}
                selectedStatus={selectedStatus}
              />
            </div>
            {roles.includes('TECHNICIAN_ADMIN') && (
            <div className={classes.techniciansList}>
              <Link to="/technicians">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.setState({ sidebarOpen: false })}
                >
                  Список техников
                  <SupervisorAccount className={classes.buttonIcon} />
                </Button>
              </Link>
            </div>
            )}
            <div className={classes.drawerButtons}>
              <Button color="primary" onClick={() => this.props.updateApp()}>
                Обновить приложение
                <RefreshIcon className={classes.buttonIcon} />
              </Button>
              <Button color="primary" onClick={() => this.props.logout()}>
                Выход
                <Exit className={classes.buttonIcon} />
              </Button>
            </div>
          </Drawer>
        </ThemeProvider>
      );
    }
}

// TechnicianNav.propTypes = {
//   logout: PropTypes.func.isRequired,
//   intl: intlShape.isRequired,
// };

function mapStateToProps(state) {
  return {
    // user: state.user,
    // documents: state.documents.documents,
    // devices: state.devices.devices,
    // bookingList: state.booking.bookingList,
    // badges: state.badges.badges,
    roles: !!state.auth.auth && state.auth.auth.roles,
    quiz: state.techCars.quiz,
    statuses: state.techCars.statuses,
    selectedStatus: state.techCars.selectedStatus,
  };
}

export default injectIntl(withRouter(withStyles(styles)(connect(mapStateToProps, {
  // getDocuments,
  // getDevices,
  // getBookingList,
  previousQiuz,
  logout,
  updateApp,
  setSelectedStatus,
})(TechnicianNav))));
