import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import '../../../assets/css/style.css';
import { sendQuizData } from '../../../../actions/Technician/cars';
import QuizButton from '../../../../components/Technician/QuizButton';
import quizStyles from './style';
// import GearLoading from '../../../components/Technician/gearLoading';

class QuizWash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    // this.car = this.props.match.params.car;
  }

  render() {
    const { classes, user, quiz, carVin, carsFetching, service } = this.props;
    // console.log('QuizWash', this);

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title}>
            Мойка автомобиля и уборка салона
          </div>
          <div className={classes.text}>
            Проведите комплексную мойку (кузов, коврики, пропылесосить) автомобиля и уборку салона.
          </div>
        </div>

        <QuizButton
          service={{ ...service, ...this.state }}
          label="Подтвердить"
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    // cars: state.techCars.cars,
    // carsFetching: state.techCars.carsFetching,
    // carsError: state.techCars.carsError,
    serviceFetching: state.techCars.serviceFetching,
    quiz: state.techCars.quiz,
    service: state.techCars.service,
    user: state.auth.auth.email,
  };
}

export default withStyles(quizStyles)(connect(mapStateToProps, { sendQuizData })(QuizWash));
