import produce from 'immer';
import { actions, emptyDamage } from '../../reducers/Technician/cars';
import api from '../../api';

export const setSelectedStatus = data => (dispatch) => {
  // console.log("setSelectedStatus", data)
  dispatch({ type: actions.SET_SELECTED_STATUS, payload: data });
};

export const getCars = () => (dispatch) => {
  dispatch({ type: actions.GET_CARS });
  const params = { params: {}, id: 1 };
  console.log('getCars');
  api.tech.getVehicles(params)
    .then((response) => {
      // console.log('response.result', response.result);
      dispatch({ type: actions.GET_CARS_SUCCESS, payload: response.result });
      const statuses = response.result.vehicleList.reduce((accumulator, currentValue) => {
        accumulator[currentValue.status] = accumulator[currentValue.status] ? accumulator[currentValue.status] + 1 : 1;
        accumulator['ALL'] += 1;
        // console.log('accumulator', accumulator);
        return accumulator;
      }, { ALL: 0 });
      dispatch({ type: actions.SET_STATUSES, payload: statuses });
      dispatch({ type: actions.SET_QUIZ, payload: 1 });
    })
    .catch((error) => {
      console.log('error', error);
      dispatch({ type: actions.GET_CARS_FAIL, payload: error });
    });
};

export const openCar = data => (dispatch) => {
  console.log('openCar', data);

  // запрос на сервер на создание новой записи service для данного авто и одновременное изменение last_service_id
  // в ответе не заполненный опросник, данные из него уходят в stor
  const params = { params: { vehicle_id: data }, id: 1 };
  dispatch({ type: actions.OPEN_CAR });
  api.tech.getOrNewService(params)
    .then((response) => {
      dispatch({
        type: actions.OPEN_CAR_SUCCESS,
        payload: {
          service: response.result.service,
          damages: response.result.damages,
          damages_old: response.result.damages_old,
          isPreCheck: response.result.isPreCheck,
          skipRefuel: response.result.skipRefuel,
          showMissedRefuelPage: response.result.showMissedRefuelPage,
          techPhotos: response.result.techPhotos,
          withMileage: response.result?.withMileage,
          manufacturer: response.result?.manufacturer,
        }
      });
      dispatch({ type: actions.NEXT_QUIZ });
    })
    .catch((error) => {
      dispatch({ type: actions.OPEN_CAR_FAIL, payload: error });
    });
};


export const saveOneDamage = (damages, damage) => (dispatch) => {
  const change = !!damages.find(val => val.last_id === damage.last_id);
  let newArray = [];
  if (change) {
    newArray = damages.map(element => (element.last_id === damage.last_id ? damage : element));
  } else {
    newArray = produce(damages, (draft) => { draft.push(damage); });
  }
  dispatch({ type: actions.SET_DAMAGE, payload: newArray });
};

export const removeOneDamage = (damages, damageId) => (dispatch) => {
  const newArray = damages.filter(element => (element.last_id !== damageId));
  dispatch({ type: actions.REMOVE_DAMAGE, payload: newArray });
};

export const sendQuizData = (service, quiz, allDamages, stateDamages, damageClass) => (dispatch) => { //
  console.log('sendQuizData', service, quiz, allDamages, stateDamages);
  let otherDamages = [];
  let changedDamages = [];
  if (allDamages && stateDamages) {
    otherDamages = allDamages.filter(element => element.class !== damageClass);
    changedDamages = [...otherDamages, ...stateDamages];
  }

  // запрос на сервер на создание новой записи service для данного авто и одновременное изменение last_service_id
  // в ответе не заполненный опросник, данные из него уходят в stor
  const { lastStep, ...servicePatch } = service;
  const params = { params: { ...servicePatch, quiz_state: quiz }, id: 1 };
  dispatch({ type: actions.EDIT_SERVICE });

  console.log('sendQuizData params', params);

  api.tech.editService(params)
    .then((response) => {
      console.log('sendQuizData response', response.result.success);

      if (response.result.success) {
        dispatch({ type: actions.EDIT_SERVICE_SUCCESS, payload: { ...servicePatch, quiz_state: quiz } });
        if (allDamages && stateDamages) { dispatch({ type: actions.SET_DAMAGES, payload: changedDamages }); }
        if (!lastStep) { dispatch({ type: actions.NEXT_QUIZ }); }
      }
    })
    .catch((error) => {
      console.log('sendQuizData error', error);
      dispatch({ type: actions.EDIT_SERVICE_FAIL });
    });
};

export const setDamages = (allDamagesArray, damageClass) => (dispatch) => {
  console.log('setDamages', allDamagesArray);
  // const {
  //   nestedDamagesFieldsReadyArray,
  //   damagesFieldsReadyArray,
  //   noErrors,
  // } = CheckErrors(allDamagesArray, damageClass);
  dispatch({
    type: actions.SET_DAMAGES,
    payload: allDamagesArray,
  });
  // dispatch({
  //   type: actions.SET_ERRORS,
  //   damagesFieldsReady: nestedDamagesFieldsReadyArray,
  //   // damagesErrors: damagesFieldsReadyArray,
  //   noErrors,
  // });
};

export const addDamage = (serviceId, allDamagesArray, damageClass, addNewDamage) => (dispatch) => {
  console.log('addDamage', serviceId, allDamagesArray, damageClass, addNewDamage);
  dispatch({
    type: actions.SET_DAMAGES,
    payload: allDamagesArray.concat({ ...emptyDamage, class: damageClass }),
  });
};

export const setExpansion = expansion => (dispatch) => {
  // console.log('setExpansion', expansion);
  dispatch({
    type: actions.SET_EXPANSION,
    payload: expansion,
  });
};

export const previousQiuz = () => (dispatch) => {
  // console.log('previousQiuz', data);
  dispatch({ type: actions.PREVIOUS_QUIZ });
};

export const closeCar = (service, quiz) => (dispatch) => {
  const params = { params: { ...service, status: 'COMPLETED', quiz_state: quiz }, id: 1 };
  console.warn('closeCar', params);
  dispatch({ type: actions.CLOSE_CAR });
  api.tech.completeService(params)
    .then((response) => {
      // dispatch({ type: actions.OPEN_CAR_SUCCESS, payload: response.result });
      console.log('response', response);
      // const { damages, damages_old, service } = response.result.damages;
      dispatch({ type: actions.CLOSE_CAR_SUCCESS });
      // dispatch({ type: actions.NEXT_QUIZ });
    })
    .catch((error) => {
      // console.log('error', error);
      dispatch({ type: actions.CLOSE_CAR_FAIL, payload: error });
      // dispatch({
      //   type: actions.OPEN_CAR_FAIL,
      //   error,
      // });
    });
  // window.location.reload();
  // console.log(credentials);
};
