import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Statuses from './components/Statuses';
import { Wrapper } from './styledComponents';
import GearLoading from '../../components/Technician/gearLoading';
import Orders from './components/Orders/Orders';
import NavigationRefueling from "./components/NavigationRefueling";

const Refueling = props => {
    const {
        classes,
        ordersFetching,
        getOrdersRefueling,
        ordersRefuelingByFilters,
    } = props;

    useEffect(() => {
        getOrdersRefueling();
    }, []);

    return (
        <Wrapper>
            <Wrapper.status>
                <Statuses/>
            </Wrapper.status>
            <Wrapper.loading ordersFetching={ordersFetching}>
                <GearLoading/>
            </Wrapper.loading>
            <Wrapper.orders>
                <NavigationRefueling/>
                <Orders
                    classes={classes}
                    orders={ordersRefuelingByFilters}
                />
            </Wrapper.orders>
        </Wrapper>
    );
};

Refueling.propTypes = {
    ordersFetching: PropTypes.bool.isRequired,
    getOrdersRefueling: PropTypes.func.isRequired,
};

export default Refueling;
