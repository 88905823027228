import React from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import { ThemeProvider, withTheme } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// import CloseIcon from '@material-ui/icons/Close';
import closeIcon from '../../assets/images/icon_closemenu.svg';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { withTheme } from '@material-ui/core/styles';
import PhoneField from '../../components/Technician/PhoneField';
import InputField from '../../components/Technician/InputField';
// import { technicianTheme } from '../../appTheme';
import {createTechnician, editTechnician, removeTechnician, getTechnicians} from '../../actions/Technician/technicians';


const styles = theme => ({
    technicians: {
        top: '92px',
        width: '100%',
        position: 'relative',
        margin: '0',
        // padding: '0 8px',
        textAlign: 'center',
    },
    menuButtonRoot: {
        padding: '4px',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: 92,
    },
    dialogAction: {
        justifyContent: 'space-around',
    },
    field: {
        margin: '24px 0',
    },
});

const REGEX_EMAIL_PATTERN = /^[\w\d\.-]+@[a-zA-Z-\.]+\.[a-z][a-z]+$/;
const REGEX_PHONE_PATTERN = /^\d{11}$/;
const FORM_ERRORS_REF = {
    fio: 'Введите ФИО',
    email: 'Введите корректный email',
    phoneNum: 'Введите корректный номер телефона',
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class TechniciansCRUD extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // modalOpen: props.open,
            fio: '',
            email: '',
            phoneNum: '',

            submitUntouched: true,
            fioErrorText: '',
            emailErrorText: '',
            phoneNumErrorText: '',
            snackOpened: false,
            snackMsg: '111',
        };
        // this.data = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            if (this.props.technicianId && this.props.technicians.length !== 0) {
                const data = this.props.technicians.find((value) => value.last_id === this.props.technicianId);
                // this.data = { fio: data.fio, email: data.email, phoneNum: data.number };
                this.setState({fio: data.fio, email: data.email, phoneNum: data.number});
            } else {
                this.setState({fio: '', email: '', phoneNum: ''});
            }

            if (this.props.open !== prevProps.open) {
                this.setState({
                    submitUntouched: true,
                    fioErrorText: '',
                    emailErrorText: '',
                    phoneNumErrorText: '',
                    snackOpened: false,
                });
            }
        }
    }

    handleChange = (data) => {
        let send;
        if (Array.isArray(data)) {
            send = data.reduce((acc, curr) => ({...acc, [curr.name]: curr.value}), {});
            this.setState({...send});
        } else {
            this.setState({[data.name]: data.value});
            if (data && data.name) {
                this.isFormValid(data.name, data.value)
            }
        }
    }

    isFieldValid = (fieldName, fieldValue) => {
        const stateKeyName = `${fieldName}ErrorText`
        let isFieldIncorrect;
        switch (fieldName) {
            case 'fio':
                isFieldIncorrect = !this.state.fio
                break
            case 'email':
                isFieldIncorrect = !REGEX_EMAIL_PATTERN.test(this.state.email)
                break
            case 'phoneNum':
                isFieldIncorrect = !REGEX_PHONE_PATTERN.test(fieldValue || this.state.phoneNum) // ошибка была с проверкой данных из ref
                break
        }

        if (isFieldIncorrect) {
            this.setState({
                [stateKeyName]: FORM_ERRORS_REF[fieldName]
            });
            return false;
        } else {
            this.setState({
                [stateKeyName]: ''
            });
            return true;
        }
    }

    isFormValid = (fieldName, fieldValue) => {
        // type FieldName = 'fio' | ' email' | 'phoneNum' | undefined
        switch (true) {
            case fieldName === 'fio':
            case fieldName === 'email':
            case fieldName === 'phoneNum':
                return this.isFieldValid(fieldName, fieldValue)

            default: // no fieldName passed to func props -> check all fields
                const isFieldValidChecksArr = [];
                Object.keys(FORM_ERRORS_REF).forEach(formFieldName => {
                    isFieldValidChecksArr.push(this.isFieldValid(formFieldName))
                })
                return !isFieldValidChecksArr.includes(false);
        }
    }

    handleCreate = (data) => {
        this.props.createTechnician(data)
            .then((response) => {
                if (this.checkIfErrorResponse(response)) {
                    return
                }
                this.props.getTechnicians();
                this.props.closeHandle();
            });
    }

    handleEdit = (data) => {
        this.props.editTechnician(data)
            .then((response) => {
                if (this.checkIfErrorResponse(response)) {
                    return
                }
                this.props.getTechnicians();
                this.props.closeHandle();
            });
    }

    checkIfErrorResponse = (response) => {
        if (response !== true) {
            const message = this.translateResponseMsg(response);
            this.openSnack(message)
            return true
        }
        return false
    }

    translateResponseMsg = (message) => {
        switch (message) {
            case 'incorrect fio':
                return 'Некорректное значение ФИО'
            case 'incorrect phoneNum':
                return 'Некорректное значение номера телефона'
            case 'incorrect email':
                return 'Некорректное значение email'
            case 'missing transport_park':
                return 'ДЦ пользователя отсутствует'
            case 'user with this email already exists':
                return 'Пользователь с таким email уже существует'
            case 'user not found':
                return 'Пользователь не найден'
            default:
                return message
        }
    }

    trySubmit = (submitType, stateData) => {
        // type SubmitType = 'edit' | 'create'
        this.setState({submitUntouched: false})
        if (!this.isFormValid()) {
            return
        }

        switch (submitType) {
            case 'edit':
                this.handleEdit(stateData)
                break
            case 'create':
                this.handleCreate(stateData)
                break
        }
    }

    handleRemove = (lastId) => {
        this.props.removeTechnician(lastId)
            .then(() => {
                this.props.getTechnicians();
                this.props.closeHandle();
            });
    }

    isSubmitDisabled() {
        return !this.state.submitUntouched &&
            (!!this.state.emailErrorText || !!this.state.fioErrorText || !!this.state.phoneNumErrorText)
    }

    openSnack = (message) => {
        this.setState({
            snackOpened: true,
            snackMsg: message,
        })
    };

    handleClose = (event, reason) => {
        if (reason === 'closed') {
            return;
        }

        this.setState({
            snackOpened: false,
            snackMsg: '',
        })
    };

    render() {
        const {classes, technicianId, remove, techFetching} = this.props;
        console.log('TechniciansCRUD', this);
        const isNew = technicianId === null && !remove;
        const isEdit = technicianId !== null && !remove;

        return (
            <div className={classes.technicians}>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.props.closeHandle}
                    aria-labelledby="responsive-dialog-title"
                >
                    <Toolbar className={classes.toolbar}>
                        <div style={{width: 46}}/>
                        <Typography variant="h5" className={classes.title}>
                            Техники
                        </Typography>
                        <IconButton
                            classes={{
                                root: classes.menuButtonRoot,
                            }}
                            aria-label="close"
                            onClick={this.props.closeHandle}
                        >
                            <img src={closeIcon} height="46" width="46" alt=""/>
                        </IconButton>
                    </Toolbar>

                    <DialogContent>
                        {isNew &&
                        <Typography variant="h6" style={{textAlign: 'center'}}>Добавить нового техника</Typography>}
                        {isEdit &&
                        <Typography variant="h6" style={{textAlign: 'center'}}>Изменить данные техника</Typography>}
                        {remove &&
                        <Typography variant="h6" style={{textAlign: 'center'}}>Удалить техника из системы</Typography>}
                        <InputField handleInputChange={this.handleChange}
                                    className={classes.field}
                                    name="fio"
                                    disabled={remove}
                                    label="ФИО"
                                    value={this.state.fio}
                                    helperText={this.state.fioErrorText}
                                    error={!this.state.submitUntouched && !!this.state.fioErrorText}
                        />
                        <InputField handleInputChange={this.handleChange}
                                    className={classes.field}
                                    name="email"
                                    disabled={true}
                                    label="Email"
                                    value={this.state.email}
                                    helperText={this.state.emailErrorText}
                                    type='email'
                                    error={!this.state.submitUntouched && !!this.state.emailErrorText}
                        />
                        <TextField
                            className={classes.field}
                            label="Телефон"
                            disabled={remove}
                            value={this.state.phoneNum}
                            onChange={event => this.handleChange({name: 'phoneNum', value: event.target.value})}
                            id="formatted-numberformat-input"
                            InputProps={{
                                inputComponent: PhoneField,
                            }}
                            fullWidth
                            helperText={this.state.phoneNumErrorText}
                            error={!this.state.submitUntouched && !!this.state.phoneNumErrorText}
                        />
                        {/*<Button onClick={this.handleClick}>SNACK</Button>*/}
                        <Snackbar open={this.state.snackOpened} autoHideDuration={6000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity="success">
                                {this.state.snackMsg}
                            </Alert>
                        </Snackbar>

                    </DialogContent>
                    <DialogActions className={classes.dialogAction}>
                        <Button onClick={this.props.closeHandle} color="default">
                            Отмена
                        </Button>
                        {techFetching ? <CircularProgress size={30} color="primary"/> :
                            <div style={{width: 30, height: 30}}></div>}
                        {remove ? (
                            <Button onClick={() => this.handleRemove(technicianId)} color="secondary" autoFocus>
                                Удалить
                            </Button>
                        ) : (
                            isNew ? (
                                <Button
                                    disabled={this.isSubmitDisabled()}
                                    onClick={() => this.trySubmit('create', this.state)}
                                    color="primary"
                                    autoFocus
                                >
                                    Создать
                                </Button>
                            ) : (
                                <Button
                                    disabled={this.isSubmitDisabled()}
                                    onClick={() => this.trySubmit('edit', {...this.state, last_id: technicianId})}
                                    color="primary"
                                    autoFocus
                                >
                                    Сохранить
                                </Button>
                            )
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        techFetching: state.technicians.techFetching,
    };
}

export default withStyles(styles)(connect(mapStateToProps, {
    createTechnician,
    getTechnicians,
    editTechnician,
    removeTechnician,
})(TechniciansCRUD));
