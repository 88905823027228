import { actions } from '../../reducers/Technician/components';

export const showGalleryPhotos = (photos, key, title, description) => (dispatch) => {
  console.log('showGalleryPhotos', photos, key, title, description);
  dispatch({
    type: actions.SHOW_GALLERY_PHOTOS,
    photos,
    key,
    title: title || '',
    description: description || '',
  });
};

export const closeGalleryPhotos = () => (dispatch) => {
  dispatch({
    type: actions.CLOSE_GALLERY_PHOTOS,
  });
};

// export const userLoggedIn = user => ({
//   type: actions.USER_LOGGED_IN,
//   user,
//   // payload: user,
// });

// function localStorageValid() {
//   // проверять expiration_date токена
//   const valid = true;
//   return !!localStorage.dpJWT && !!localStorage.roles && valid ? localStorage.dpJWT : false;
// }

// export const login = credentials => (dispatch) => {
//   let auth;
//   if (localStorageValid() && !credentials) {
//     auth = {
//       authkey: JSON.parse(localStorage.dpJWT),
//       email: JSON.parse(window.atob(JSON.parse(localStorage.dpJWT))).email,
//       roles: JSON.parse(localStorage.roles),
//     };
//     setAuthorizationHeader(JSON.parse(localStorage.dpJWT));
//     dispatch({
//       type: actions.USER_LOGGED_IN_SUCCESS,
//       auth,
//     });
//   } else if (credentials) {
//     console.log('api credentials', credentials);
//     dispatch({ type: actions.USER_LOGGED_IN });
//     api.user.login(credentials)
//       .then((response) => {
//         console.log('api resp', response);
//         console.log('api resp status', response.status);
//         console.log('api resp data', response.data.data);
//         // console.log('api resp', response.data.roles, Array.isArray(response.data.roles));
//         if (response.status == '200') {
//           const data = response.data.data;
//           auth = {
//             authkey: data.authkey,
//             // email: JSON.parse(window.atob(data.authkey)).email,
//             email: credentials.email,
//             roles: data.roles,
//           };
//           localStorage.dpJWT = JSON.stringify(data.authkey);
//           localStorage.roles = JSON.stringify(data.roles);
//           setAuthorizationHeader(data.authkey);
//           dispatch({ type: actions.USER_LOGGED_IN_SUCCESS, auth });
//         }
//       })
//       .catch((error) => {
//         console.log('error', error);
//         if (error.response) {
//           // The request was made and the server responded with a status code
//           // that falls out of the range of 2xx
//           // console.log('error.response.data', error.response.data);
//           if (error.response.data.text === 'NOT_POST_EMAIL') {
//             dispatch({ type: actions.USER_LOGGED_IN_FAIL, error: 'wrong credentials' });
//           } else {
//             dispatch({ type: actions.USER_LOGGED_IN_FAIL, error: 'something goes wrong' });
//           }
//           // console.log('error.response.status', error.response.status);
//           // console.log('error.response.headers', error.response.headers);
//         } else if (error.request) {
//           // The request was made but no response was received
//           // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//           // http.ClientRequest in node.js
//           console.log('error.request', error.request);
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           console.log('Error', error.message);
//         }
//         console.log('error.config', error.config);
//         // dispatch({ type: actions.USER_LOGGED_IN_FAIL, error });
//       });
//   }
// };

// export const userLoggedOut = () => ({
//   type: actions.USER_LOGGED_OUT,
// });

// export const logout = () => (dispatch) => {
//   localStorage.removeItem('dpJWT');
//   localStorage.removeItem('roles');
//   localStorage.removeItem('environment');
//   localStorage.removeItem('intl');
//   setAuthorizationHeader();
//   dispatch(userLoggedOut());
//   window.location.reload();
// };
