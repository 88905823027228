export const actions = {
  SHOW_GALLERY_PHOTOS: 'SHOW_GALLERY_PHOTOS',
  CLOSE_GALLERY_PHOTOS: 'CLOSE_GALLERY_PHOTOS',
};

const initialState = {
  photosToShow: [],
  activePhoto: 0,
  photoTitle: '',
  photoDescription: '',
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case actions.SHOW_GALLERY_PHOTOS:
      return {
        ...state,
        photosToShow: action.photos,
        activePhoto: action.key,
        photoTitle: action.title,
        photoDescription: action.description,
      };
    case actions.CLOSE_GALLERY_PHOTOS:
      return {
        ...state,
        photosToShow: null,
        activePhoto: 0,
        photoTitle: '',
        photoDescription: '',
      };
    default:
      return state;
  }
}
