import {MESSAGES_FETCHED} from '../types';
import api from '../api';

const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
export const messagesFetched = messages => ({
    type: MESSAGES_FETCHED,
    messages
});

export const fetchMessages = () => dispatch => {
    const intl = localStorage.getItem('intl');

    if (!isJson(intl) || !intl) {
        return api.messages.fetchAll()
            .then(messages => {
                dispatch(messagesFetched(messages.result));
                localStorage.setItem('intl', JSON.stringify(messages.result));
                return messages.result;
            })
    } else {
        // localStorage.getItem('intl');

        return dispatch(messagesFetched(JSON.parse(intl)));
    }
};
