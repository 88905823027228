import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 15px;
  box-sizing: border-box;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const SearchBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 1199px) {
    margin-bottom: 20px;
  }
`;

export const RightBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
`;

export const PaginationBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export const SortBlock = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 20px;
  @media (max-width: 1199px) {
    padding-left: 0;
  }
  @media (max-width: 640px) {
    padding-bottom: 15px;
  }
`;