import React from 'react';
import TextField from '@material-ui/core/TextField';

class DamageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: props.value,
    };
  }

  handleChangeTextField = (str, key) => {
    this.setState({ text: str });
    this.props.handleChangeDebounce(str, key);
  };

  render() {
    const { label, error, helperText, damageKey } = this.props;
    // console.log('DamageInput', this.props, this.state);
    return (
      <React.Fragment>
        <TextField
          label={label}
          // className={classes.textField}
          error={error}
          helperText={error ? helperText : ''}
          margin="dense"
          variant="outlined"
          multiline
          minRows={2}
          maxRows={4}
          fullWidth
          value={this.state.text}
          onChange={event => this.handleChangeTextField(event.target.value, damageKey)}
        />
      </React.Fragment>
    );
  }
}

export default DamageInput;
