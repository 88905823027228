import {retryWithDelay} from "../../../utils/api/retry-with-delay";

const openDoorsStatuses = [
    { 'ssDoorControlDriver': 'Открыта водительская дверь' },
    { 'ssDoorControlPassenger': 'Открыта пассажирская дверь' },
    { 'ssHoodDoorStatus': 'Багажник в открытом состоянии' },
    { 'ssTrunkDoorStatus': 'Капот в открытом состоянии' },
    { 'ssPassengerDoorStatus': 'Двери пассажира в открытом состоянии' },
    { 'ssDriverDoorStatus': 'Открытое состояние двери водителя' },
    { 'sscPassengerFrontDoorOpen': 'Открыта дверь переднего пассажира' },
    { 'sscPassengerRearDoorOpen': 'Открыты двери задних пассажиров' },
    { 'sscDriverDoorOpen': 'Открыта дверь водителя' },
    { 'sscPassengerAllDoorOpen': 'Открыты все пассажирские двери' },
    { 'sscTrunkOpen': 'Открыт багажник' },
    { 'sscHoodOpen': 'Открыт капот' },
];

// Проверяем время, что время пакета с информацией о машине сформировалось после запроса
export const checkTimePackage = (timeInit) => {
    return (response) => {
        return +timeInit < +response.timegetpacket;
    }
}

// Получаем список названий открытых дверей
export const getOpenDoorsStatuses = (packet) => {
    return openDoorsStatuses.filter((status) => {
        const key = Object.keys(status)[0];
        return +packet[key] > 0
    }).map((status) => {
        return Object.values(status)[0];
    });
}

export const closeDoorVehicle = async (id, api) => {
    let state = {
        error: '',
        data: ''
    };

    const statusCloseDoor = await api.closeDoorVehicleById(id);

    // Если машина ответила, что запрос на закрытие дверей прошел
    if (statusCloseDoor) {
        const timeInit = Math.floor(+new Date() / 1000);
        try {
            const packet = await retryWithDelay(
                () => api.getVehicleLastPacket(id),
                5,
                5000,
                checkTimePackage(timeInit)
            );

            const openDoors = getOpenDoorsStatuses(packet);
            if (openDoors.length) {
                state = {
                    error: 'OpenDoors',
                    data: openDoors
                }
            }
        } catch (error) {
            state = { error };
        }
    } else {
        state = {
            error: 'OpenDoorsRequestFailed'
        }
    }

    return state;
}

export const getCloseDoorErrors = (state) => {
    let msg = '';
    if (state.error) {
        msg = 'Проблема связи с автомобилем!';

        if (state.error === 'OpenDoorsRequestFailed') {
            msg = 'Запрос на закрытие машины не прошёл!';
        }

        if (state.error === 'ConditionFalse') {
            msg = 'Нет связи с машиной! Проверьте двери!';
        }

        if (state.error === 'OpenDoors') {
            msg = `Двери не закрыты: \n${state.data.join(`\n`)}`;
        }
    }
    return msg;
}