import React from 'react';
import clsx from 'clsx';
import produce from 'immer';
import debounce from "lodash.debounce";
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import AddAPhotoRounded from '@material-ui/icons/AddAPhotoRounded';
import Close from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import SvgIcon from '@material-ui/core/SvgIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import api, {apiPrefix} from '../../api';
import {setDamages, addDamage, sendQuizData, saveOneDamage, removeOneDamage} from '../../actions/Technician/cars';
import {showGalleryPhotos} from '../../actions/Technician/components';
import {emptyDamage, damagedParts} from '../../reducers/Technician/cars';
import DamageInput from './DamageInput';
import NotificationRefueling from "../../pages/Refueling/components/Notification/Notification";

const styles = theme => ({
    damageAccordion: {
        margin: '20px 0',
        // margin: '20px 60px 20px 0',
    },
    // damageExpanded: {
    //   backgroundColor: 'rgba(0,0,0,0.05) !important',
    // },
    expansionPanelError: {
        border: '1px solid rgba(245, 0, 87, 0.5)',
    },
    expansionPanelChange: {
        border: '1px solid rgba(0, 170, 210, 0.5)',
    },
    addButtonRoot: {
        backgroundColor: '#F9F9F9',
        height: '40px',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    addButtonRootNotSingle: {
        borderTop: '1px solid #f50057',
    },
    addButton: {
        height: '100%',
        width: '60px',
        backgroundColor: '#f50057',
        // borderRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        fontSize: '44px',
        lineHeight: '44px',
        color: 'white',
        textShadow: '-1px -1px 0 rgba(0,0,0,0.3), 0 1px 0 rgba(68,68,68,0.3)',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    },
    disabledButton: {
        backgroundColor: '#ccc',
    },
    addButtonSingle: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    addButtonText: {
        padding: '6px 16px 0',
        fontFamily: 'HyundaiSansHead',
        color: '#002C5F',
        fontWeight: '600',
    },
    titleRoot: {
        width: 'calc(100vw - 240px)',
    },
    title: {
        color: '#002c5f',
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: 'HyundaiSansHead',
    },
    formControl: {
        width: '90%',
    },
    shortDescription: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    removeButton: {
        color: '#666',
    },
    saveButton: {
        padding: '4px 30px 0',
        fontFamily: 'HyundaiSansHead',
        fontWeight: 600,
    },
    trashIcon: {
        width: 24,
        padding: '0 8px 0 0',
        fontFamily: 'HyundaiSansHead',
        // position: 'relative',
        // right: -110,
    },
    addPhotoFrame: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 120,
    },
    addPhotoButton: {
        width: 70,
        height: 70,
        backgroundColor: '#f4f4f4',
        // border: '1px solid #ccc',
        borderRadius: '4px',
        position: 'relative',
    },
    addPhoto: {
        fill: '#666',
        width: '1.5em',
        height: '1.5em',
    },
    photoFrame: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '100%',
        display: 'flex',
        position: 'relative',
    },
    removePhotoButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 4,
        left: '25%',
        width: '50vw',
        display: 'flex',
        alignItems: 'center',
    },
    rootButton: {
        width: '40vw',
        margin: '10px auto',
        padding: '10px 16px 6px;',
        fontFamily: 'HyundaiSansHead',
        letterSpacing: '0.4px',
    },
    labelButton: {
        fontWeight: '700',
    },
    uploadDesc: {
        paddingTop: '10px',
        fontSize: '12px',
        color: '#ccc',
        lineHeight: '16px'
    },
});

class ViewEditDamage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            damages: props.damageNew.filter(element => element.class === props.damageClass),
            saveDamageFetch: [],
            removeDamageFetch: [],
            expansion: [],
            // errors: [],
            changed: [],
            saveClicked: [],
            // textFileds: [],
            errorNotification: '',
            authkey: JSON.parse(localStorage.dpJWT)
        };
        this.fileInput = React.createRef();
        this.damages = [];
        console.log('ViewEditDamage', {props})
    }

    addDamage = () => {
        const damages = produce(this.state.damages, (draft) => {
            draft.push({...emptyDamage, class: this.props.damageClass, last_id: 0});
        });
        const newExpansion = this.state.expansion.length === 0 ? [true] : this.state.expansion.map((element, index) => index === 0);
        this.setState({damages, expansion: newExpansion});
    }

    // Если есть описание или если опционально, то игнорируем
    isDamageDetailOptional = (detail = '') => {
        return !!detail || this.props.damageDetailOptional;
    }

    saveDamage = (damage, damageId) => {
        if (
            (!!damage.part || !damagedParts[this.props.damageClass]) &&
            damage.photo.length !== 0 &&
            this.isDamageDetailOptional(damage.description)
        ) {
            const damageForSend = {
                ...damage,
                vehicle_id: this.props.service.vehicle_id,
                service_id: this.props.service.last_id,
                last_id: damage.last_id === 0 ? undefined : damage.last_id,
                type: 'other',
                size: '',
            };
           const params = {params: damageForSend, id: 1};

            let saveDamageFetch = produce(this.state.saveDamageFetch, (draft) => {
                draft[damageId] = true;
            });

            this.setState({saveDamageFetch});

            api.tech.setDamage(params)
                .then((res) => {
                     console.log('Component send setDamage', params);
                    if (res.result.success) {
                        let damageWithId = [];
                        saveDamageFetch = produce(this.state.saveDamageFetch, (draft) => {
                            draft[damageId] = false;
                        });
                        if (res.result.last_id) {
                            damageWithId = this.state.damages.map(val => (val.last_id === damageId ? {
                                ...val,
                                last_id: res.result.last_id
                            } : val));
                            this.props.saveOneDamage(this.props.damageNew, {
                                ...damageForSend,
                                last_id: res.result.last_id
                            });
                        } else {
                            damageWithId = this.state.damages;
                            this.props.saveOneDamage(this.props.damageNew, damageForSend);
                        }
                        this.setState({
                            damages: damageWithId,
                            saveDamageFetch,
                            changed: produce(this.state.changed, (draft) => {
                                draft[damageId] = false;
                            }),
                            saveClicked: produce(this.state.saveClicked, (draft) => {
                                draft[damageId] = false;
                            }),
                        });
                    } else {
                        throw res;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.setState({
                saveClicked: produce(this.state.saveClicked, (draft) => {
                    draft[damageId] = true;
                })
            });
        }
    };

    removeDamage = (damage, damageId) => {
        if (damage.last_id) {
            const params = {params: {last_id: damage.last_id}, id: 1};
            const removeDamageFetch = produce(this.state.removeDamageFetch, (draft) => {
                draft[damageId] = true;
            });
            this.setState({removeDamageFetch});
            api.tech.removeDamage(params)
                .then((res) => {
                    // console.log(res);
                    if (res.result.success) {
                        this.props.removeOneDamage(this.props.damageNew, damage.last_id);
                        this.setState({
                            damages: this.state.damages.filter((val) => val.last_id !== damageId),
                            changed: produce(this.state.changed, (draft) => {
                                draft[damageId] = false;
                            }),
                        });
                    } else {
                        throw res;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            this.setState({
                damages: this.state.damages.filter((val) => val.last_id !== damageId),
                changed: produce(this.state.changed, (draft) => {
                    draft[damageId] = false;
                }),
            });
        }
    }

    handleChangeSelect = (event, key) => {
        this.setState({
            damages: this.state.damages.map(val => (val.last_id === key ? {
                ...val,
                part: damagedParts[this.props.damageClass][event.target.value]
            } : val)),
            changed: produce(this.state.changed, (draft) => {
                draft[key] = true;
            }),
        });
    }

    handleChangeDebounce = debounce((str, key) => {
        this.setState({
            damages: this.state.damages.map(val => (val.last_id === key ? {...val, description: str} : val)),
            changed: produce(this.state.changed, (draft) => {
                draft[key] = true;
            }),
        });
    }, 500);

    addPhotoHandler = (event, key) => {
        this.setState({errorNotification: ''});
        var reader = new FileReader();
        const carVin = this.props.carVin;
        const self = this;
        reader.onloadend = function () {
            const formData = {
                image: reader.result, // event.target.files[0],
                module: 'vehicle',
                vin: carVin,
                type: 'damage'
            };
            api.photo.add(formData)
                .then((res) => {
                    console.log(res)
                    if (!res?.error) {
                        self.setState({
                            damages: self.state.damages.map(val => (val.last_id === key ? {
                                ...val,
                                photo: [...val.photo, res.result]
                            } : val)),
                            changed: produce(self.state.changed, (draft) => {
                                draft[key] = true;
                            }),
                        });
                    } else {
                        self.setState({errorNotification: 'Ошибка загрузки, попробуйте ещё раз!'});
                    }
                })
                .catch((error) => {
                    self.setState({errorNotification: 'Ошибка загрузки по сети, попробуйте ещё раз!'});
                    console.log(error);
                });

        };
        reader.readAsDataURL(event.target.files[0]);


    };

    removePhotoHandler = (event, damageId, pathKey) => {
        this.setState({
            damages: this.state.damages.map(val => (val.last_id === damageId ? {
                ...val,
                photo: val.photo.filter((element, index) => index !== pathKey)
            } : val))
        });
        event.stopPropagation();
    };

    handleFullScreenPhoto = (damageId, pathKey) => {
        this.props.showGalleryPhotos(this.state.damages.find(val => val.last_id === damageId).photo, pathKey);
    };

    handleExpansionPanel = (damageId) => {
        const newArray = produce(this.state.expansion, (draft) => {
            draft[damageId] = !draft[damageId];
        });
        this.setState({expansion: newArray});
    };

    get disabledNextBtn() {
        const isChange = this.props.serviceFetching ||
        this.state.changed.reduce((accumulator, currentValue) => (accumulator || currentValue), false);
        return isChange ||
        // Когда флаг блокирования кнопки когда нет добавленных актов
        (this.props.disableIfEmpty && !this.state.damages.filter(({photo}) => photo.length).length)
    }

    render() {
        const {
            classes,
            damageClass,
            serviceFetching,
            quiz,
            damageNew,
            service,
            sendQuizData,
            naming = {}
        } = this.props;

        const {
            addButtonText = 'Добавить повреждения',
            damageTitleText = 'Новое повреждение',
            damageInputLabelText = 'Описание повреждения',
            damageInputHelperText = '* Описание повреждения обязательно к заполнению'
        } = naming;

        console.log('this.state.damages', this.state.damages);

        return (
            <div className={classes.damageAccordion}>
                {this.state.damages.map(damage => (
                    <ExpansionPanel
                        key={'ExpansionPanel' + damage.last_id}
                        // defaultExpanded
                        classes={{
                            root: clsx(
                                this.state.saveClicked[damage.last_id] ? classes.expansionPanelError : undefined,
                                this.state.changed[damage.last_id] && !this.state.saveClicked[damage.last_id] ? classes.expansionPanelChange : undefined,
                            ),
                        }}
                        expanded={this.state.expansion[damage.last_id]}
                        onChange={() => this.handleExpansionPanel(damage.last_id)}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            IconButtonProps={{
                                disabled: true,
                                color: 'secondary',
                            }}
                            aria-controls="panel1c-content"
                            // id={'expansion' + damage.last_id}
                        >
                            <div className={classes.titleRoot}>
                                <Typography className={classes.title}>
                                    {!!damage.part ? this.props.intl.formatMessage({id: damage.part}) : damageTitleText}
                                </Typography>
                                <div>
                                    <Typography className={classes.shortDescription}>
                                        {damage.description}
                                    </Typography>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>

                            <Grid container spacing={2}>
                                {damagedParts[damageClass] && (
                                    <Grid item xs={12}>
                                        <FormControl
                                            className={classes.formControl}
                                            error={this.state.saveClicked[damage.last_id] && !this.state.damages.find(val => val.last_id === damage.last_id).part}
                                        >
                                            <InputLabel>Повреждено</InputLabel>
                                            <Select
                                                value={damage.part !== '' ? damagedParts[damageClass].indexOf(damage.part) : ''}
                                                onChange={event => this.handleChangeSelect(event, damage.last_id)}
                                            >
                                                <MenuItem value="">
                                                    <em>Не выбрано</em>
                                                </MenuItem>
                                                {damagedParts[damageClass].map((part, partKey) => (
                                                    <MenuItem value={partKey}
                                                              key={partKey}>{this.props.intl.formatMessage({id: part,})}</MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>
                                                * Укажите место повреждения
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {Array.isArray(damage.photo) && damage.photo.length < 3
                                        && (
                                            <Grid item xs={4} className={classes.addPhotoFrame}>
                                                <input
                                                    accept="image/*"
                                                    className={classes.input}
                                                    style={{display: 'none'}}
                                                    id={"raised-button-file" + damage.last_id}
                                                    multiple
                                                    type="file"
                                                    ref={this.fileInput}
                                                    onChange={event => this.addPhotoHandler(event, damage.last_id)}
                                                />
                                                <label htmlFor={"raised-button-file" + damage.last_id}
                                                       style={{textAlign: 'center'}}>

                                                    <div>
                                                        <ButtonBase
                                                            variant="outlined"
                                                            component="span"
                                                            className={classes.addPhotoButton}
                                                            style={
                                                                this.state.saveClicked[damage.last_id] && this.state.damages.find(val => val.last_id === damage.last_id).photo.length === 0
                                                                    ? {border: '1px solid #f50057'}
                                                                    : this.state.damages.find(val => val.last_id === damage.last_id).photo.length === 0
                                                                        ? {border: '1px solid #ccc'}
                                                                        : {border: '1px solid #00FF00'}

                                                            }
                                                        >
                                                            <AddAPhotoRounded className={classes.addPhoto}/>
                                                        </ButtonBase>

                                                        <div className={classes.uploadDesc}>
                                                            Доступные форматы файлов: .jpeg, .png, .jpg.<br/>
                                                            Максимальный размер файла 10 Мбайт.
                                                        </div>
                                                    </div>

                                                    {this.state.saveClicked[damage.last_id] && this.state.damages.find(val => val.last_id === damage.last_id).photo.length === 0 &&
                                                    <FormHelperText error={true}>* Обязательно хотя бы одно фото
                                                        повреждения</FormHelperText>}
                                                </label>
                                            </Grid>
                                        )}
                                        {Array.isArray(damage.photo) && damage.photo.map((path, pathKey) => (
                                            <Grid item xs={4} key={'miniImage' + pathKey}>
                                                <div className={classes.photoFrame}>
                                                    <ButtonBase
                                                        // className={classes.paperButton}
                                                        onClick={() => this.handleFullScreenPhoto(damage.last_id, pathKey)}
                                                        focusRipple
                                                    >
                                                        <img
                                                            src={`${process.env.REACT_APP_API_SERVER}${apiPrefix}/images/${path}?token=${this.state.authkey}`}
                                                            alt=""
                                                            style={{width: '100%', height: '100%'}}/>
                                                        <IconButton
                                                            component="span"
                                                            size="small"
                                                            color="secondary"
                                                            className={classes.removePhotoButton}
                                                            onClick={event => this.removePhotoHandler(event, damage.last_id, pathKey)}
                                                        >
                                                            <Close color="secondary"
                                                                   className={classes.removePhotoIcon}/>
                                                        </IconButton>
                                                    </ButtonBase>
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DamageInput
                                        label={damageInputLabelText}
                                        // className={classes.textField}
                                        error={this.state.saveClicked[damage.last_id] && !this.state.damages.find(val => val.last_id === damage.last_id).description}
                                        helperText={damageInputHelperText}
                                        value={damage.description}
                                        damageKey={damage.last_id}
                                        handleChangeDebounce={this.handleChangeDebounce}
                                        // onChange={event => this.handleChangeTextField(event.target.value, damageKey)}
                                    />
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                        <Divider/>
                        {this.state.saveDamageFetch[damage.last_id] && <LinearProgress color='primary'/>}
                        <ExpansionPanelActions
                            // style={{ justifyContent: 'start' }}
                            style={{justifyContent: 'space-between'}}
                        >
                            <Button
                                size="small"
                                className={classes.removeButton}
                                onClick={() => this.removeDamage(damage, damage.last_id)}
                            >
                                <SvgIcon viewBox='0 -256 1792 1792' className={classes.trashIcon}>
                                    <g transform="matrix(1 0 0 -1 197.42 1255.1)">
                                        <path
                                            d="m512 800v-576q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v576q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-576q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v576q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-576q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v576q0 14 9 23t23 9h64q14 0 23-9t9-23zm128-724v948h-896v-948q0-22 7-40.5t14.5-27 10.5-8.5h832q3 0 10.5 8.5t14.5 27 7 40.5zm-672 1076h448l-48 117q-7 9-17 11h-317q-10-2-17-11zm928-32v-64q0-14-9-23t-23-9h-96v-948q0-83-47-143.5t-113-60.5h-832q-66 0-113 58.5t-47 141.5v952h-96q-14 0-23 9t-9 23v64q0 14 9 23t23 9h309l70 167q15 37 54 63t79 26h320q40 0 79-26t54-63l70-167h309q14 0 23-9t9-23z"/>
                                    </g>
                                </SvgIcon>
                                Удалить запись
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                disabled={!this.disabledNextBtn}
                                onClick={() => this.saveDamage(damage, damage.last_id)}
                                classes={{
                                    label: classes.saveButton,
                                }}
                            >
                                Сохранить
                            </Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                ))}

                <div className={clsx(
                    classes.addButtonRoot,
                    this.damages.length ? classes.addButtonRootNotSingle : null,
                    'MuiPaper-root MuiPaper-elevation1 MuiExpansionPanel-root MuiExpansionPanel-rounded MuiPaper-rounded',
                )}
                >
                    <div className={classes.addButtonText}>
                        {addButtonText}
                    </div>
                    <ButtonBase
                        classes={{
                            root: clsx(classes.addButton, this.damages.length === 0 ? classes.addButtonSingle : null),
                            disabled: classes.disabledButton,
                        }}
                        disabled={this.state.damages.some(val => val.last_id === 0)}
                        onClick={this.addDamage}
                        // onClick={() => this.props.addDamage(service.last_id, this.props.damageNew, this.props.damageClass, true)}
                        // onClick={() => this.props.addDamage(this.props.damageNew, this.props.damageClass, true)}
                    >+</ButtonBase>
                </div>

                <div className={classes.buttonContainer}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={this.disabledNextBtn}
                        classes={{
                            root: classes.rootButton,
                            label: classes.labelButton,
                            // focusVisible: classes.focusButton,
                        }}
                        onClick={() => sendQuizData(
                            service,
                            quiz,
                            damageNew,
                            // this.state.damages,
                            this.state.damages.filter(val => {
                                return this.isDamageDetailOptional(val.description) && val.photo.length !== 0;
                            }),
                            damageClass,
                        )}
                    >
                        Далее
                    </Button>
                    {serviceFetching && <CircularProgress size={30} color="primary"/>}
                </div>

                {this.state.errorNotification &&
                    <NotificationRefueling
                        notification={{message: this.state.errorNotification, type: 'error'}}
                        autoHideDuration={6000}
                        setNotification={() => {}}
                    />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        service: state.techCars.service,
        damageNew: state.techCars.damages,
        // damageOld: state.techCars.damages_old,
        expansion: state.techCars.expansion,
        showErrors: state.techCars.damagesFieldsReady,
        serviceFetching: state.techCars.serviceFetching,
        quiz: state.techCars.quiz,
    };
}

export default injectIntl(withStyles(styles)(connect(mapStateToProps, {
    showGalleryPhotos,
    setDamages,
    addDamage,
    sendQuizData,
    saveOneDamage,
    removeOneDamage
})(ViewEditDamage)));
