export const actions = {
    GET_DOCUMENTS: 'GET_DOCUMENTS',
    GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
    GET_DOCUMENTS_FAIL: 'GET_DOCUMENTS_FAIL',
    GET_ONE_DOCUMENT: 'GET_ONE_DOCUMENT',
    GET_ONE_DOCUMENT_SUCCESS: 'GET_ONE_DOCUMENT_SUCCESS',
    GET_ONE_DOCUMENT_FAIL: 'GET_ONE_DOCUMENT_FAIL',
    GET_DOCUMENT_DATA: 'GET_DOCUMENT_DATA',
    GET_DOCUMENT_DATA_SUCCESS: 'GET_DOCUMENT_DATA_SUCCESS',
    GET_DOCUMENT_DATA_FAIL: 'GET_DOCUMENT_DATA_FAIL',
    DISALLOW_ACCESS: 'DISALLOW_ACCESS',
    DISALLOW_ACCESS_SUCCESS: 'DISALLOW_ACCESS_SUCCESS',
    DISALLOW_ACCESS_FAIL: 'DISALLOW_ACCESS_FAIL',
    ALLOW_ACCESS: 'ALLOW_ACCESS',
    ALLOW_ACCESS_SUCCESS: 'ALLOW_ACCESS_SUCCESS',
    ALLOW_ACCESS_FAIL: 'ALLOW_ACCESS_FAIL',
    SAVE_ACCESS: 'SAVE_ACCESS',
    SAVE_ACCESS_SUCCESS: 'SAVE_ACCESS_SUCCESS',
    SAVE_ACCESS_FAIL: 'SAVE_ACCESS_FAIL',
};

const initialState = {
    documents: null,
    documentsFetching: false,
    documentsError: null,
    singleDocument: null,
    documentData: null,
    documentDataFetching: null,
    documentDataError: null,
    disallowAccess: null,
    disallowAccessFetching: null,
    disallowAccessError: null,
    allowAccess: null,
    allowAccessFetching: null,
    allowAccessError: null,
};

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_DOCUMENTS:
            return {
                ...state,
                documentsFetching: true
            };
        case actions.GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documentsFetching: false,
                documents: action.payload,
                documentsError: null
            };
        case actions.GET_DOCUMENTS_FAIL:
            return {
                ...state,
                documentsFetching: false,
                documents: null,
                documentsError: action.payload
            };
        case actions.GET_ONE_DOCUMENT:
            return {
                ...state,
                documentsFetching: true
            };
        case actions.GET_ONE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documentsFetching: false,
                singleDocument: action.payload,
                documentsError: null
            };
        case actions.GET_ONE_DOCUMENT_FAIL:
            return {
                ...state,
                documentsFetching: false,
                singleDocument: null,
                documentsError: action.payload
            };
        case actions.GET_DOCUMENT_DATA:
            return {
                ...state,
                documentDataFetching: true
            };
        case actions.GET_DOCUMENT_DATA_SUCCESS:
            return {
                ...state,
                documentDataFetching: false,
                documentData: action.payload,
                documentDataError: null
            };
        case actions.GET_DOCUMENT_DATA_FAIL:
            return {
                ...state,
                documentDataFetching: false,
                documentData: null,
                documentDataError: action.payload
            };
        case actions.DISALLOW_ACCESS:
            return {
                ...state,
                disallowAccessFetching: true
            };
        case actions.DISALLOW_ACCESS_SUCCESS:
            return {
                ...state,
                disallowAccessFetching: false,
                disallowAccess: action.payload,
                disallowAccessError: null
            };
        case actions.DISALLOW_ACCESS_FAIL:
            return {
                ...state,
                disallowAccessFetching: false,
                disallowAccess: null,
                disallowAccessError: action.payload
            };
        case actions.ALLOW_ACCESS:
            return {
                ...state,
                allowAccessFetching: true
            };
        case actions.ALLOW_ACCESS_SUCCESS:
            return {
                ...state,
                allowAccessFetching: false,
                allowAccess: action.payload,
                allowAccessError: null
            };
        case actions.ALLOW_ACCESS_FAIL:
            return {
                ...state,
                allowAccessFetching: false,
                allowAccess: null,
                allowAccessError: action.payload
            };

        case actions.SAVE_ACCESS:
            return {
                ...state,
                saveAccessFetching: true
            };
        case actions.SAVE_ACCESS_SUCCESS:
            return {
                ...state,
                saveAccessFetching: false,
                saveAccess: action.payload,
                saveAccessError: null
            };
        case actions.SAVE_ACCESS_FAIL:
            return {
                ...state,
                saveAccessFetching: false,
                saveAccess: null,
                saveAccessError: action.payload
            };

        default:
            return state;
    }
}
