import React from 'react';
// import '../assets/css/style.css';
// import '../assets/css/booking.css';


class Range extends React.Component {
    constructor(props) {
        super(props);
        this.monthNames = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
        this.daysNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
    }

    dateInfo(date, title) {
        let dateRow;
        let rangeMonthText = 'Укажите дату';
        if (date) {
            dateRow = ((date.getDate() < 10) && '0') + date.getDate();
            rangeMonthText = this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
        }
        let day = (date) ? (this.daysNames[date.getDay()])
            : null;
        return (
            <div className="calendar__from-date">
                <div className="calendar__range-h">{title}</div>
                <div className='flex-row'>
                    <p className="calendar__range-date">{dateRow}</p>
                    <p className="calendar__range-month">
                        {rangeMonthText}
                        <span className="calendar__range-day">{day}</span>
                    </p>
                </div>
            </div>
        )
    }

    render() {
        let {dateFrom, dateTo} = this.props;
        dateTo = dateTo ? new Date(dateTo) : dateTo;
        dateFrom = dateFrom ? new Date(dateFrom) : dateFrom;
        return (<div className="calendar__range">
            {this.dateInfo(dateFrom, 'Начало')}
            {this.dateInfo(dateTo, 'Завершение')}
        </div>)
    }
}

/*Header Table*/
class Header extends React.Component {
    constructor(props) {
        super(props);
        this.monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
        this.dayNames = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.date !== this.props.date;
    }

    render() {
        let date = new Date(this.props.date);
        return (<div className="calendar__header">
            <div className="calendar__month-chooser">
                <span className="calendar__prev-month" onClick={this.props.prevMonth}/>
                <span>{this.monthNames[date.getMonth()]}</span>
                <span className="calendar__next-month" onClick={this.props.nextMonth}/>
            </div>
            <table className="calendar__days-names" cellSpacing="0">
                <tbody>
                <tr>
                    {this.dayNames.map((i, key) => <td className="calendar__day-name" key={key}>{i}</td>)}
                </tr>
                </tbody>
            </table>
        </div>)
    }
}


class Calendar extends React.Component {
    constructor(props) {
        super(props);
        this.animationDirection = "forward";
        this.currentDate = new Date();
    }

    handleClick(index) {
        let {setRange, indexStart, indexEnd} = this.props;
        if (this.selectionEnabled) {
            if ((index === indexStart)) {
                this.selectionEnabled = false;
                setRange();
            } else {
                this.selectionEnabled = false;
                setRange(this.props.indexStart, index);
            }
        } else {
            if (index === indexEnd) {
                this.selectionEnabled = true;
            } else {
                this.selectionEnabled = true;
                setRange(index);
            }
        }
    }

    mouseOver(index) {
        if (this.selectionEnabled) {
            this.props.setRange(this.props.indexStart, index);
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.date !== this.props.date) {
            this.animationDirection = (nextProps.date - this.props.date > 0) ? "forward" : "backward"
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.refs.calendar.classList.add(`animate--${this.animationDirection}`);
        }
    }

    getDay(date, key, month) {
        let time = date.getTime();
        let currentDate = this.currentDate;
        let {indexEnd, indexStart} = this.props;
        let dayClass = (time > indexStart && time < indexEnd) ? 'selected' : '';
        dayClass += (date < currentDate.setHours(0)) ? ' out--range' : '';
        dayClass += (time === indexStart && indexEnd > indexStart) ? ' sel--start' : '';
        dayClass += (time === indexEnd && time > indexStart) ? ' sel--end' : '';
        dayClass += (date.getMonth() === month) ? ' calendar__day' : ' calendar__day dis';
        return (<td className={dayClass}
                    onClick={this.handleClick.bind(this, time)}
                    onMouseOver={this.mouseOver.bind(this, time)}
                    key={key}>
            <div className="calendar__inner-day">
                {date.getDate()}
            </div>
        </td>)
    }

    render() {
        let date = new Date(this.props.date);
        let month = date.getMonth();
        date.setDate(1);
        if (this.animationDirection === "forward") {
            date.setMonth(date.getMonth() - 1);
        }
        let firstDay = date.getDay();
        if (firstDay !== 1) {
            (firstDay === 0) ?
                date.setDate(date.getDate() - 6)
                : date.setDate(date.getDate() - (firstDay - 1));
        }
        date.setDate(date.getDate() - 1);
        return (<div className="calendar__wrap">
            <table className="calendar__table" ref="calendar" key={this.props.date} cellSpacing="0">
                <tbody>
                {Array(12).fill(0).map((i, key) => {
                    return <tr key={key}>
                        {Array(7).fill(0).map((_i, _key) => {
                            date.setDate(date.getDate() + 1);
                            return this.getDay(date, _key, month);
                        })}
                    </tr>
                })}
                </tbody>
            </table>
        </div>)
    }
}


class BookingCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: Date.now(),
            selectionStart: 0,
            selectionEnd: 0
        }
    }

    prevMonth() {
        let date = new Date(this.state.date);
        date.setMonth(date.getMonth() - 1);
        this.setState({date: date.getTime()});
    }

    nextMonth() {
        let date = new Date(this.state.date);
        date.setMonth(date.getMonth() + 1);
        this.setState({date: date.getTime()});
    }

    setRange(selectionStart = 0, selectionEnd = 0) {
        this.setState({selectionStart, selectionEnd});
    }

    render() {
        let {date, selectionStart, selectionEnd} = this.state;
        console.log('calendar', date, selectionStart, selectionEnd);
        return (
            <div className="calendar">
                <Header
                    date={date} prevMonth={this.prevMonth.bind(this)}
                    nextMonth={this.nextMonth.bind(this)}
                />
                <Calendar
                    date={date}
                    indexStart={selectionStart}
                    indexEnd={selectionEnd}
                    setRange={this.setRange.bind(this)}
                />
                <div className='flex-row'>
                    <Range dateFrom={selectionStart} dateTo={selectionEnd}/>
                    <div className='flex-column space-evenly mt'>
                        <div className='flex-row'>
                            <span className="time-icon"/>
                            <input className='time-input' type="time" min="9:00" max="18:00"/></div>
                        <div className='flex-row'>
                            <span className="time-icon"/>
                            <input className='time-input' type="time"/></div>
                    </div>
                </div>
            </div>
        )
    }
}


export default BookingCalendar;