import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper/Paper';
import { Link } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Application, Wrapper, Model } from './styledComponents';
import OrderRefuelingContent from '../OrderRefuelingContent/OrderRefuelingContent';

const Orders = props => {
    const {
        orders,
        classes,
    } = props;

    const LinkRef = React.forwardRef((props, ref) => <div ref={ref}><Link {...props} /></div>);

    return (
        <Wrapper>
            <Grid container spacing={2} className={classes.dashboard}>
                {orders.length ? orders.map(order => (
                    <Grid item
                          xs={12}
                          sm={6}
                          md={4}
                          xl={3}
                          key={order._id}>
                        <ButtonBase className={classes.Button}
                                    component={LinkRef}
                                    to={`/refueling/${order._id}`}
                        >
                            <Paper elevation={3} className={classes.paper}>
                                <Wrapper.Application>
                                    <Application.Model>
                                        <Model.vehicle>
                                            {order.vehicle}
                                        </Model.vehicle>
                                        <Model.grz>
                                            {order.grz}
                                        </Model.grz>
                                    </Application.Model>
                                    <OrderRefuelingContent
                                        order={order}
                                    />
                                </Wrapper.Application>
                            </Paper>
                        </ButtonBase>
                    </Grid>
                ))
                : (
                    <Grid item xs={12}>
                        Заявок на заправку автомобилей нет
                    </Grid>
                )}
            </Grid>
        </Wrapper>
    );
};

Orders.propTypes = {
    orders: PropTypes.array.isRequired,
};

export default Orders;
