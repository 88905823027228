import {useCallback, useEffect, useState} from "react";
import {useDebouncedCallback} from "use-debounce";

export default function useSearch(searchTerm = '', setSearchTerm) {
    const [searchValue, setSearchValue] = useState(searchTerm);

    useEffect(() => {
        setSearchValue(() => searchTerm);
    }, [searchTerm])

    const setSearchFilter = useDebouncedCallback((term) => setSearchTerm(term), 400);

    const onSearch = useCallback((e) => {
        const term = e.target.value || '';
        setSearchFilter(term)
        setSearchValue(() => term);
    }, [setSearchFilter]);

    const onSearchClear = useCallback(() => {
        setSearchFilter('')
        setSearchValue(() => '');
    }, [setSearchFilter]);

    return [searchValue, onSearch, onSearchClear]
}