import React from 'react';
import { connect } from 'react-redux';
import { formatMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import { setDamages, addDamage, setExpansion } from '../../actions/Technician/cars';
import { showGalleryPhotos } from '../../actions/Technician/components';

const styles = theme => ({
  paperButton: {
    width: '100%',
    fontFamily: 'HyundaiSansText',
    '&:hover': {
      boxShadow: '0px 0px 5px 3px rgba(0,177,210,0.5)',
      borderRadius: '8px',
    },
  },
  paper: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'rgba(200, 200, 200 ,0.1)',
  },
  avatar: {
    border: '2px solid #ccc',
    width: '60px',
    height: '60px',
    borderRadius: '20%',
    margin: '8px' ,
  },
  title: {
    color: 'rgb(0, 170, 210)',
    padding: '8px 0',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '1.08',
  },
  description: {
    fontSize: '14px',
    lineHeight: '1.08',
  },
});

class ViewDamage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // damages: props.damageNew.filter(element => element.class === props.damageClass),
      // expansion: [],
      // errors: [],
    };
  }

  handleFullScreenPhoto = (damageId, title, description) => {
    this.props.showGalleryPhotos(this.props.damageOld.find(val => val.last_id === damageId).photo, 0, title, description);
  }

  render() {
    const { classes, damageOld, damageClass, carVin, title, expansion, showErrors } = this.props;
    // console.log('ViewDamage', this.props, this.state);
    return (
      <Grid container spacing={2} style={{ margin: '8px -8px' }}>
        {damageOld.filter(val => val.class === damageClass).map((damage, key) => (
          <Grid item xs={12} md={6} key={key}>
            <ButtonBase
              className={classes.paperButton}
              onClick={() => this.handleFullScreenPhoto(damage.last_id, this.props.intl.formatMessage({ id: damage.part }), damage.description)}
              focusRipple
            >
              <Paper elevation={2} className={classes.paper}>
                <Avatar
                  src={Array.isArray(damage.photo) ? process.env.REACT_APP_API_SERVER + damage.photo[0] : process.env.REACT_APP_API_SERVER + damage.photo}
                  className={classes.avatar}
                />
                <div style={{ padding: '0 8px' }}>
                  <div className={classes.title}>
                    {this.props.intl.formatMessage({ id: damage.part })}
                  </div>
                  <div className={classes.description}>{damage.description}</div>
                </div>
              </Paper>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    damageOld: state.techCars.damages_old,
  };
}

export default injectIntl(withStyles(styles)(connect(mapStateToProps, { showGalleryPhotos, setDamages, addDamage, setExpansion })(ViewDamage)));
