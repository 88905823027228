import axios from 'axios';

export default (authkey = null) => {
  if (authkey) {
    axios.defaults.headers.common.authorization = `Bearer ${authkey}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
  } else {
    delete axios.defaults.headers.common.authorization;
  }
};
