import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {sendQuizData} from '../../../../actions/Technician/cars';
import QuizButton from '../../../../components/Technician/QuizButton';
import quizStyles from './style';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const QuizCheckboxCheckNFill = (withStyles(quizStyles)((props) => {
    const {classes, label, access, checked, handleChange} = props;
    return (
        <FormControlLabel className={classes.checkboxes}
                          control={(
                              <Checkbox
                                  color="primary"
                                  checked={checked}
                                  onChange={handleChange(access)}
                                  icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon}/>}
                                  checkedIcon={<CheckBoxIcon className={classes.sizeIcon}/>}
                              />
                          )}
                          label={label}
                          classes={{label: classes.checkboxes}}
                          style={{display: 'flex'}}
        />
    );
}));

class QuizCheckNFill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allChecked: false,

            carter: false,
            tyres: false,
            pressure: false,
            windshieldWasher: false,
            brakeFluid: false,
            oilLevel: false,
            coolant: false,
            headlights: false,
        };
        // this.car = this.props.match.params.car;
    }

    toggleField = access => (event) => {
        const {allChecked, ...prevState} = this.state;
        const newState = {
            ...prevState,
            [access]: event.target.checked,
        };
        this.setState({
            ...newState,
            allChecked: !Object.values(newState).includes(false),
        });
    }

    render() {
        const {classes, user, quiz, carVin, carsFetching, service} = this.props;
        const {allChecked, carter, tyres, pressure, windshieldWasher, brakeFluid, oilLevel, coolant, headlights} = this.state;

        return (
            <React.Fragment>
                <div className={classes.body}>
                    <div className={classes.title}>
                        Проверка переключателя фар:
                    </div>
                    <QuizCheckboxCheckNFill
                        label='Переключатель фар в положении AUTO'
                        access={Object.keys({headlights})[0]}
                        checked={headlights}
                        handleChange={this.toggleField}
                    />
                    <div className={classes.title}>
                        Проверка защиты картера, шин и техничеких жидкостей:
                    </div>
                    <QuizCheckboxCheckNFill
                        label='Защита картера проверена'
                        access={Object.keys({carter})[0]}
                        checked={carter}
                        handleChange={this.toggleField}
                    />
                    <QuizCheckboxCheckNFill
                        label='Установлены сезонные шины'
                        access={Object.keys({tyres})[0]}
                        checked={tyres}
                        handleChange={this.toggleField}
                    />
                    <QuizCheckboxCheckNFill
                        label='Давление в шинах в норме'
                        access={Object.keys({pressure})[0]}
                        checked={pressure}
                        handleChange={this.toggleField}
                    />
                    <div className={classes.title}>
                        Проверьте уровень технических жикостей, долейте если требуется:
                    </div>
                    <QuizCheckboxCheckNFill
                        label='Сезонная жидкость стеклоомывателя'
                        access={Object.keys({windshieldWasher})[0]}
                        checked={windshieldWasher}
                        handleChange={this.toggleField}
                    />
                    <QuizCheckboxCheckNFill
                        label='Тормозная жидкость'
                        access={Object.keys({brakeFluid})[0]}
                        checked={brakeFluid}
                        handleChange={this.toggleField}
                    />
                    <QuizCheckboxCheckNFill
                        label='Уровень масла'
                        access={Object.keys({oilLevel})[0]}
                        checked={oilLevel}
                        handleChange={this.toggleField}
                    />
                    <QuizCheckboxCheckNFill
                        label='Охлаждающая жидкость'
                        access={Object.keys({coolant})[0]}
                        checked={coolant}
                        handleChange={this.toggleField}
                    />
                </div>

                <QuizButton
                    service={{...service}}
                    label="Подтвердить"
                    disabled={!allChecked}
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        // cars: state.techCars.cars,
        // carsFetching: state.techCars.carsFetching,
        // carsError: state.techCars.carsError,
        serviceFetching: state.techCars.serviceFetching,
        quiz: state.techCars.quiz,
        service: state.techCars.service,
        user: state.auth.auth.email,
    };
}

export default withStyles(quizStyles)(connect(mapStateToProps, {sendQuizData})(QuizCheckNFill));
