export const getRefuelingStyles = () => ({
    dashboard: {
        width: '100%',
        margin: '0',
        padding: '0 8px',
    },
    Button: {
        width: '100%',
        '&:hover': {
            boxShadow: '0px 0px 5px 3px rgba(0,177,210,0.5)',
            borderRadius: '8px',
        },
    },
    paper: {
        width: '100%',
        padding: '15px',
    },
    status: {
        display: 'flex',
        justifyContent: 'space-evenly',
        fontSize: '1.1em',
        fontFamily: 'HyundaiSansHead',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '3em',
        boxShadow: '0 1px 4px 0 #e4dcd3',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'center',
    },
    widthFull: {
        width: '100%'
    }
});

export const getStatusString = (key) => {
    switch (key) {
        case 'CREATED': return 'Новый';
        case 'STARTED': return 'Выполняется';
        case 'CANCEL': return 'Отменен';
        case 'COMPLETED': return 'Завершен';
        default: return key;
    }
};

export const getStatusColor = (key) => {
    switch (key) {
        case 'CREATED': return '#94e664';
        case 'STARTED': return '#37a6e5';
        case 'CANCEL': return '#ff0000';
        case 'COMPLETED': return '#f5915c';
        default: return '#cccccc';
    }
};

export const getButtonsByStatus = (status, orderFinishTime) => {
    switch (status) {
        case 'CREATED': return [
            {
                value: 'emergency',
                name: 'Моргнуть аварийкой',
                color: '#f5915c',
            },
            {
                value: 'startRefueling',
                name: 'Начать заправку',
                color: '#94e664',
            },
        ];
        case 'STARTED': return [
            {
                value: 'openDoor',
                name: 'Открыть автомобиль',
                color: '#37a6e5',
            },
            {
                value: 'closeDoor',
                name: 'Закрыть автомобиль',
                color: '#37a6e5',
            },
            {
                value: 'finishRefueling',
                name: 'Закончить заправку',
                color: '#94e664',
            },
        ];
        case 'COMPLETED': {
            if (checkNeedButtonCloseDoor(orderFinishTime)) {
                return [
                    {
                        value: 'closeDoor',
                        name: 'Закрыть автомобиль',
                        color: '#37a6e5',
                    },
                ];
            } else {
                return [];
            }
        }
        default: return [];
    }
};


export const checkNeedButtonCloseDoor = (orderFinishTime) => {
    if (!orderFinishTime) {
        return false;
    }

    const nowDateMilliseconds = new Date().getTime();
    const orderFinishTimeMilliseconds = new Date(orderFinishTime).getTime();

    return nowDateMilliseconds - orderFinishTimeMilliseconds < 600000;
};
