import React, {useEffect, useState, useCallback} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {withStyles} from "@material-ui/core";
import Slide from "@material-ui/core/Slide/Slide";
import PropTypes from "prop-types";
import {createNotification} from "../../../../utils/createNotification";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const notificationStyle = {
    success: {
        background: '#94e664',
        color: '#444',
        fontSize: '16px',
        cursor: 'pointer',
    },
    warning: {
        background: '#f5915c',
        color: '#444',
        fontSize: '16px',
        cursor: 'pointer',
    },
    error: {
        background: '#ff6161',
        color: '#444',
        fontSize: '16px',
        cursor: 'pointer',
    },
}

const styles = theme => ({
    ...notificationStyle,
    message: {
        maxWidth: 'calc(100% - 40px)',
        minWidth: '280px',
        display: 'block',
        whiteSpace: 'break-spaces'
    },
    closeButton: {
        position: 'absolute',
        top: '2px',
        right: '0',
    }
});

function TransitionLeft(props) {
    return <Slide {...props} direction="left"/>;
}

const NotificationRefueling = props => {
    const {
        notification: { message, type },
        classes,
        setNotification,
        autoHideDuration = 60000
    } = props;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(() => !!message);
    }, [message]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setNotification(createNotification());
    }, [setOpen, setNotification]);

    return (<>
            {message && <Snackbar
                autoHideDuration={autoHideDuration}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                onClose={handleClose}
                TransitionComponent={TransitionLeft}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes[type]
                    },
                }}
                action={
                    <div className={classes.closeButton}>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.5 }}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                }
                message={<span className={classes.message} id="message-id">{message}</span>}
            />}
        </>
    )
};

NotificationRefueling.propTypes = {
    classes: PropTypes.object.isRequired,
    setNotification: PropTypes.func.isRequired,
};

export default withStyles(styles)(NotificationRefueling);
