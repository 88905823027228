import React, {useCallback, useState, useEffect} from 'react';
import {Label, Wrapper} from './styledComponents';
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const Sort = props => {
    const {
        setSort = () => {},
        sort = false
    } = props;

    const [sortValue, setSortValue] = useState(sort);

    const onSort = useCallback(() => {
        return setSortValue((prev) => {
            const sortState = !prev;
            setSort(sortState);
            return sortState;
        });
    }, [setSort]);

    return (
        <Wrapper>
            <Label>Время заказа</Label>
            <IconButton size={'small'} onClick={onSort}>
                {!sortValue && <ArrowDownwardIcon fontSize={'small'}/>}
                {sortValue && <ArrowUpwardIcon fontSize={'small'}/>}
            </IconButton>
        </Wrapper>
    );
};

export default Sort;
