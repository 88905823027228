import {createNotification} from "../../utils/createNotification";

export const actions = {
    NOTIFICATION: 'NOTIFICATION',
    SET_CONTROL_BUTTONS: 'SET_CONTROL_BUTTONS',
    SET_ORDERS_FETCHING: 'SET_ORDERS_FETCHING',
    SET_ORDERS_REFUELING: 'SET_ORDERS_REFUELING',
    SET_SELECTED_STATUS_REFUELING: 'SET_SELECTED_STATUS_REFUELING',
    CHANGE_STATUS_REFUELING_BY_ID: 'CHANGE_STATUS_REFUELING_BY_ID',
    SET_SEARCH_TERM_REFUELING: 'SET_SEARCH_TERM_REFUELING',
    SET_PAGINATION_PAGE_REFUELING: 'SET_PAGINATION_PAGE_REFUELING',
    SET_SORT_REFUELING: 'SET_SORT_REFUELING',
};

const initialState = {
    statusesRefueling: [
        {
            name: 'Все',
            value: 'ALL',
        },
        {
            name: 'Новые',
            value: 'CREATED',
        },
        {
            name: 'Выполняются',
            value: 'STARTED',
        },
        {
            name: 'Отменены',
            value: 'CANCEL',
        },
        {
            name: 'Завершены',
            value: 'COMPLETED',
        },
    ],
    ordersFetching: false,
    ordersRefueling: [],
    selectedStatusRefueling: 'ALL',
    searchTermRefueling: '',
    paginationSizeRefueling: 24,
    paginationPageRefueling: 1,
    sortRefueling: [
        {
            name: 'orderTime',
            order: false,
            type: 'date'
        }
    ],
    controlButtonsFetching: {
        openDoor: false,
        closeDoor: false,
        emergency: false,
        startRefueling: false,
        finishRefueling: false,
    },
    notification: createNotification()
};

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case actions.SET_ORDERS_FETCHING:
            return {
                ...state,
                ordersFetching: action.payload,
            };
        case actions.SET_ORDERS_REFUELING:
            return {
                ...state,
                ordersRefueling: action.payload,
                ordersFetching: false,
            };
        case actions.SET_SELECTED_STATUS_REFUELING:
            return {
                ...state,
                selectedStatusRefueling: action.payload,
            };
        case actions.SET_SEARCH_TERM_REFUELING:
            return {
                ...state,
                searchTermRefueling: action.payload,
            };
        case actions.SET_SORT_REFUELING:
            const sortItem = action.payload || {};
            return {
                ...state,
                sortRefueling: state.sortRefueling.map((item) => item.name === sortItem.name ? sortItem: item),
            };
        case actions.SET_PAGINATION_PAGE_REFUELING:
            return {
                ...state,
                paginationPageRefueling: action.payload,
            };
        case actions.SET_CONTROL_BUTTONS:
            return {
                ...state,
                controlButtonsFetching: {
                    ...state.controlButtonsFetching,
                    ...action.payload,
                }
            };
        case actions.CHANGE_STATUS_REFUELING_BY_ID:
            const newOrdersRefueling = [...state.ordersRefueling];
            newOrdersRefueling.forEach(order => {
                if (order._id === action.payload?.refuelingId) {
                    order.status = action.payload?.status;
                }
            });

            return {
                ...state,
                ordersRefueling: newOrdersRefueling,
            };
        case actions.NOTIFICATION: {
            return {
                ...state,
                notification: action.payload,
            }
        }
        default:
            return state;
    }
}
