import React from 'react';
import PropTypes from 'prop-types';
import cn from 'clsx';
import { connect } from 'react-redux';
import Validator from 'validator';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withTheme } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import ErrorIcon from '@material-ui/icons/Error';
// import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button/Button';
// import logo from '../../../assets/images/logo-big.svg';
import logo from '../../assets/images/logo-big.svg';
import { login } from '../../actions/auth';
import AuthInput from '../../components/Auth/AuthInput';
// import BootstrapInput from '../../../components/Auth/BootstrapInput';
// import '../../../assets/nocss/auth.css';
// import '../../../assets/nocss/style.css';
import authStyles from './style';

// const theme = createMuiTheme({
//   typography: {
//     // Use the system font.
//     fontFamily: 'HyundaiSansText',
//   },
//   palette: {
//     primary: {
//       // light: will be calculated from palette.primary.main,
//       main: '#00aad2',
//       // main: '#ff0000',
//       dark: 'rgba(255, 255, 255, 0.3)',
//       contrastText: '#ff0000',
//     },
//     error: {
//       // light: will be calculated from palette.primary.main,
//       main: '#f50057',
//       // dark: 'rgba(255, 255, 255, 0.3)',
//       contrastText: '#ffffff',
//     },
//   },
// });
// const authStyles = theme => ({
//   authSection: {
//     backgroundColor: "black",
//   },
//   button: {
//     margin: '0',
//     padding: '12px 40px',
//     fontSize: '14px',
//     fontFamily: 'Cera Pro Regular',
//     textTransform: 'none',
//     color: theme.palette.getContrastText(blue[600]),
//     backgroundColor: blue[600],
//     '&:hover': {
//       backgroundColor: blue[700],
//     },
//     width: '100%',
//   },
// });


class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: '',
        password: '',
      },
      loading: false,
      errors: false,
      popover: null,
      environment: 'production',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleChange = event => {
  //   this.setState({ environment: event.target.value });
  // };

  componentDidMount() {
    localStorage.removeItem('environment');
  }

  componentDidUpdate() {
    localStorage.removeItem('environment');
    localStorage.setItem('environment', JSON.stringify(this.state.environment));
  }

  onChange = e => this.setState({
    data: { ...this.state.data, [e.target.name]: e.target.value },
  });

  // onSubmit = () => {
  //   const errors = this.validate(this.state.data);
  //   this.setState({errors});
  //   if (Object.keys(errors).length === 0) {
  //     this.setState({loading: true});
  //     this.submit(this.state.data)
  //     //   .then(err =>
  //     //   // this.setState({error: err, loading: false})
  //     //   console.log('err', err)
  //     // );
  //   }
  // };

  validate = (data) => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = 'Некорректный адрес E-mail';
    if (!data.password) errors.password = 'Не указан пароль';

    return errors;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // console.log('data', this.state.data);
    // const parameters = {
    //   method: 'POST',
    //   headers: {
    //     'Accept': 'application/json',
    //     'Content-Type': 'application/json',
    //   },
    //   body: this.state.data,
    // };
    // fetch('/api/wi/v1/user/auth', parameters)
    //   .then(response => response.json())
    //   .then((myJson) => {
    //     console.log(JSON.stringify(myJson));
    //   })
    //   .catch(error => console.log(error));

    this.props.login(this.state.data);
    // .then(() => {
    //   console.log('thennn');
    //   this.props.history.push('/dashboard');
    //   window.location.reload();
    // })
    // .catch(() => {
    //   console.log('Неверный логин или пароль.', this.state.data);
    //   this.setState({ errors: true });
    //   this.setState({
    //     data: { ...this.state.data, password: '' },
    //   });
    //   setTimeout(() => this.setState({ errors: false }), 9000);
    // });
  }

  // submit = (data) => {
  //   this.props.login(data)
  //     .then((e) => {
  //       if (e !== undefined) {
  //         this.props.history.push('/dashboard');
  //         window.location.reload();
  //       } else {
  //         console.log('Неверный логин или пароль.', e, data);
  //         this.setState({ errors: true });
  //         this.setState({
  //           data: { ...this.state.data, password: '' },
  //         });
  //         setTimeout(() => this.setState({ errors: false }), 9000);
  //       }
  //     });
  // };
  popoverCLick = (event) => {
    // this.setState({ popover: true });
    this.setState({ popover: event.currentTarget });
  }

  popoverClose = () => {
    this.setState({ popover: null });
  }

  render() {
    const { data, errors, environment } = this.state;
    const { isAuthenticated, authError, classes } = this.props;
    // console.log('Auth', this);
    // console.log('isAuthenticated', isAuthenticated);
    return (
      !isAuthenticated ? (
        // <ThemeProvider theme={theme}>
        <div>
          <div className={classes.authMain}>
            <div className={classes.authForm}>
              <form onSubmit={this.handleSubmit}>
                <div className={classes.authFormContainer}>
                  <div className={classes.formCol}>
                    <img src={logo} alt="" className={classes.logo} />
                  </div>
                  <div className={cn(classes.formCol, classes.title)}>
                    {/* <h3 className={classes.formColFirstChildH3}>Привет! С возвращением!</h3> */}
                    <p>
                      Добро пожаловать в Hyundai Mobility!
                      <br />
                      Пожалуйста авторизуйтесь
                    </p>
                  </div>
                  <div className={cn(classes.formCol, classes.email)}>
                    <FormControl fullWidth>
                      <InputLabel shrink htmlFor="email" className={classes.inputLabel}>
                        E-mail
                      </InputLabel>
                      <AuthInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Ваш email"
                        value={data.email}
                        onChange={this.onChange}
                        inputProps={{ style: { border: !!authError ? '1px solid red' : '' } }}
                        autoFocus
                      />
                    </FormControl>
                    {/*{errors.email && <InlineError text={errors.email}/>}*/}
                  </div>
                  <div className={cn(classes.formCol, classes.password)}>
                    <FormControl fullWidth>
                      <InputLabel
                        shrink
                        htmlFor="password"
                        // className={classes.inputLabel}
                        classes={{
                          root: classes.inputLabel,
                          focused: classes.inputLabelFocused,
                        }}
                      >
                        Пароль
                      </InputLabel>
                      <AuthInput
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Пароль"
                        value={data.password}
                        onChange={this.onChange}
                        inputProps={{ style: { border: !!authError ? '1px solid red' : '' } }}
                      />
                    </FormControl>
                    {/* {errors && <InlineError text="Неверный логин или пароль." />} */}
                    {!!authError
                    && (
                      <FormHelperText className={classes.error}>
                        <ErrorIcon className={classes.errorIcon} />
                        Неверный пароль!
                      </FormHelperText>
                    )}
                    <div className={classes.forgot} onClick={this.popoverCLick}>Я забыл пароль</div>
                    <Popover
                      id="password_recovery"
                      open={Boolean(this.state.popover)}
                      anchorEl={this.state.popover}
                      onClose={this.popoverClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      classes={{
                        paper: classes.forgotPaper,
                      }}
                    >
                      <div className={classes.typography}>Если Вы забыли пароль от Личного Кабинета, свяжитесь со службой поддержки по телефону 8 800 551-01-21</div>
                    </Popover>
                  </div>
                  <div className={cn(classes.formCol, classes.colButton)}>
                    {/*<button onClick={() => this.submit(data)} className='btn'>Войти</button>*/}
                    <Button
                      // onClick={() => {
                      //   this.submit(data);
                      // }}
                      onClick={this.handleSubmit}
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      className={classes.button}
                      classes={{
                        root: classes.rootButton,
                        label: classes.labelButton,
                        focusVisible: classes.focusButton,
                      }}
                      // disabled={submitted}
                    >
                      Войти
                    </Button>
                  </div>
                  {/* <div className={classes.formCol}>
                    <div className={classes.authSelectEnvironment}>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="position"
                          name="environment"
                          value={environment}
                          onChange={this.handleChange}
                          row
                        >
                          <FormControlLabel
                            value="production"
                            control={<Radio color="default" />}
                            label="Production"
                            labelPlacement="end"
                          />
                          <FormControlLabel
                            value="development"
                            control={<Radio color="default" />}
                            label="Development"
                            labelPlacement="end"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div> */}
                  {/* <div className={classes.formCol}>
                    <div className={classes.formColSublinks}>
                      <a className={classes.formColSublinksA} href="/">Условия использования</a> <a className={classes.formColSublinksA} href="/">Конфиденциальность</a>
                    </div>
                  </div> */}
                  {/* </MuiThemeProvider> */}
                </div>
              </form>
            </div>
            <div className={classes.authBanner} />
          </div>
        {/* </ThemeProvider> */}
        </div>
      ) : (
        <Redirect to="/dashboard" />
      )
    );
  }
}


Auth.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.auth && !!state.auth.auth.authkey,
    authError: state.auth.authError,
  };
}


export default withTheme(withStyles(authStyles)(connect(mapStateToProps, { login })(Auth)));
