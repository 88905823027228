// import authBanner from './../../assets/images/background.jpg';

const quizStyles = theme => ({
    dashboard: {
        top: '177px',
        position: 'relative',
        padding: '8px 16px',
    },
    title: {
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '1.08',
        color: '#002c5f',
        padding: '20px 0 10px',
        // textAlign: 'center',
    },
    buttonContainer: {
        position: 'absolute',
        bottom: 4,
        left: '50%',
        transform: 'translate(-50%, 0)',
        width: 'auto',
        minWidth: '50vw',
        display: 'flex',
        alignItems: 'center',
    },
    rootButton: {
        // width: '40vw',
        width: '40vw',
        minWidth: '200px',
        margin: '10px auto',
        padding: '10px 16px 6px;',
        fontFamily: 'HyundaiSansHead',
        letterSpacing: '0.4px',
    },
    secondaryButton: {
        bottom: 56,
        // position: 'relative',
    },
    labelButton: {
        fontWeight: '700',
    },
    body: {
        // minHeight: 'calc(100vh - 310px)',
        // margin: '20px',
        margin: '16px 16px 60px',
    },
    loading: {
        top: '177px',
        width: '100%',
        height: 'calc(100% - 177px)',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)',
    },
    checkboxes: {
        fontSize: '20px',
        display: 'flex',
    },
    checkboxGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        fontSize: '18px',
        lineHeight: '1.11',
        letterSpacing: '-0.15px',
        color: '#000000',
        paddingBottom: '0.5em',
    },
    textAddPhoto: {
        fontSize: '18px',
        lineHeight: '1.11',
        letterSpacing: '-0.15px',
        color: '#000000',
    },
    sizeIcon: {
        height: '50px',
        width: '50px',
    },
    leftIcon: {
        margin: '5px 20px 10px',
    },
    editIcon: {
        color: 'rgb(0, 170, 210)',
        backgroundColor: 'rgba(0, 170, 210, 0.1)',
        borderRadius: '50%',
        padding: '10px',
        width: '30px',
        height: '30px',
        margin: '10px',
    },
    paperButton: {
        width: '100%',
        fontFamily: 'HyundaiSansText',
        '&:hover': {
            boxShadow: '0px 0px 5px 3px rgba(0,177,210,0.5)',
            borderRadius: '8px',
        },
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    boxContainer: {
        display: 'flex',
        flexDirection: 'row',

    },
    boxInfo1: {
        margin: '3px',
        width: '136px',
        height: '133px',
        borderRadius: '6px',
        backgroundColor: '#f6f3f2',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxInfo2: {
        margin: '3px',
        width: '136px',
        height: '133px',
        borderRadius: '6px',
        border: 'solid 1px #979797',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxTtl: {
//    width: '65px',
        height: '23px',
        fontFamily: 'HyundaiSansHead',
        fontSize: '18px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#323232'
    },
    boxValue: {
//  width: '120px',
//  height: '44px',
        fontFamily: 'HyundaiSansHead',
        fontSize: '36px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#323232'
    },
    boxCaption: {
//  width: '38px',
//  height: '23px',
        fontFamily: 'HyundaiSansHead',
        fontSize: '18px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#323232'
    },
    boxValue2: {
        color: '#8f8d8d'
    },
    cardSelectBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '15px',
        margin: '3px'

    },
    cardSelectBtnAllow: {
        width: '208px',
        //height: '50px',
        borderRadius: '25px',
        boxShadow: '0 1px 5px 0 rgba(170, 166, 205, 0.46)',
        backgroundColor: '#ffffff',
        margin: '3px',
        justifyContent: 'center',
        padding: '15px'

    },
    cardBoxBtnText: {
        // width: '100px',
        height: '20px',
        fontFamily: 'HyundaiSansText',
        fontSize: '18px',
        fontWeight: '500',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.11',
        letterSpacing: '-0.15px',
        textAlign: 'center',
        color: '#000000'
    },
    cardSelectBtnSelected: {
        width: '208px',
        //height: '50px',
        borderRadius: '30px',
        boxShadow: '0 1px 5px 0 rgba(170, 166, 205, 0.46)',
        border: 'solid 1px #00aad2',
        margin: '3px',
        justifyContent: 'center',
        padding: '15px'
    },
    cardBoxBtnTextSelected: {
        //width: '98px',
        height: '20px',
        fontFamily: 'HyundaiSansText',
        fontSize: '18px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.11',
        letterSpacing: '-0.15px',
        textAlign: 'center',
        color: '#00aad2'
    },

    // стили из компонента ViewEditDamage
    damageAccordion: {
        margin: '20px 0',
    },
    expansionPanelError: {
        border: '1px solid rgba(245, 0, 87, 0.5)',
    },
    expansionPanelChange: {
        border: '1px solid rgba(0, 170, 210, 0.5)',
    },
    addButtonRoot: {
        backgroundColor: '#F9F9F9',
        height: '40px',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    addButtonRootNotSingle: {
        borderTop: '1px solid #f50057',
    },
    addButton: {
        height: '100%',
        width: '60px',
        backgroundColor: '#f50057',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        fontSize: '44px',
        lineHeight: '44px',
        color: 'white',
        textShadow: '-1px -1px 0 rgba(0,0,0,0.3), 0 1px 0 rgba(68,68,68,0.3)',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
    },
    disabledButton: {
        backgroundColor: '#ccc',
    },
    addButtonSingle: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
    },
    addButtonText: {
        padding: '6px 16px 0',
        fontFamily: 'HyundaiSansHead',
        color: '#002C5F',
        fontWeight: '600',
    },
    titleRoot: {
        width: 'calc(100vw - 240px)',
    },
    formControl: {
        width: '90%',
    },
    shortDescription: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    removeButton: {
        color: '#666',
    },
    saveButton: {
        padding: '4px 30px 0',
        fontFamily: 'HyundaiSansHead',
        fontWeight: 600,
    },
    trashIcon: {
        width: 24,
        padding: '0 8px 0 0',
        fontFamily: 'HyundaiSansHead',
    },
    addPhotoFrame: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        minHeight: 90,
    },
    addPhotoButton: {
        width: 70,
        height: 70,
        backgroundColor: '#f4f4f4',
        borderRadius: '4px',
        position: 'relative',
        margin: '0 15px 0 0',
    },
    addPhoto: {
        fill: '#666',
        width: '1.5em',
        height: '1.5em',
    },
    photoFrame: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        height: '100%',
        width: 'fit-content',
        display: 'flex',
        position: 'relative',
    },
    removePhotoButton: {
        position: 'absolute',
        top: 4,
        right: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },

});

export default quizStyles;
