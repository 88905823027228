import OrderRefueling from './OrderRefueling';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getRefuelingStyles } from '../../logic';
import { setNotification } from '../../../../actions/Refueling/refueling';
import * as selectors from '../../../../selectors/refueling';

export const mapStateToProps = state => ({
    notification: selectors.getNotification(state),
    ordersRefueling: selectors.getOrdersRefueling(state),
});

export default withStyles(getRefuelingStyles)(connect(
    mapStateToProps,
    { setNotification }
)(OrderRefueling));
