import React from 'react';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Button from '@material-ui/core/Button';
import {apiPrefix} from "../../api";

const styles = theme => ({
    image: {
        margin: 8,
        // width: 'calc(100% - 16px)',
        height: '100%',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
});

class PhotoGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // dialog: props.open,
            activeStep: props.activePhoto,
            authkey: JSON.parse(localStorage.dpJWT)
        };
        // this.fileInput = React.createRef();
        this.maxSteps = props.photos.length;
    }

    // const[activeStep, setActiveStep] = React.useState(0);
    // const maxSteps = tutorialSteps.length;

    handleNext = () => {
        this.setState(prevActiveStep => {
            return {activeStep: prevActiveStep.activeStep + 1}
        });
    };

    handleBack = () => {
        this.setState(prevActiveStep => {
            return {activeStep: prevActiveStep.activeStep - 1}
        });
    };

    render() {
        const {classes, photos, activePhoto} = this.props;
        // const { damage_size, damaged_part, description, photo_url, type } = damage;
        console.log('photoGallery', this.props, this.state);

        return (
            <React.Fragment>
                <div
                    style={{
                        backgroundImage: `url(${process.env.REACT_APP_API_SERVER}${apiPrefix}/images/${photos[this.state.activeStep]}?token=${this.state.authkey})`,
                    }}
                    className={classes.image}
                />
                <MobileStepper
                    steps={this.maxSteps}
                    position="static"
                    // position="bottom"
                    variant="dots"
                    activeStep={this.state.activeStep}
                    nextButton={(
                        <Button size="small" color="primary" onClick={this.handleNext}
                                disabled={this.state.activeStep === this.maxSteps - 1}>
                            Следующая
                            <KeyboardArrowRight/>
                        </Button>
                    )}
                    backButton={(
                        <Button size="small" color="primary" onClick={this.handleBack}
                                disabled={this.state.activeStep === 0}>
                            <KeyboardArrowLeft/>
                            Предыдущая
                        </Button>
                    )}
                />
            </React.Fragment>
        );
    }
}

export default injectIntl(withStyles(styles)(PhotoGallery));
