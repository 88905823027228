import {MESSAGES_FETCHED} from '../types';


export default function messages(state = {messages: {en:{}, ru:{}}}, action = {}) {
    switch (action.type) {
        case MESSAGES_FETCHED:
            return { ...state, ...action};
        default:
            return state;
    }
}