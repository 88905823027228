import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {withTheme} from '@material-ui/styles';
// import Key from "@material-ui/icons/VpnKeyTwoTone";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import '../../../assets/css/style.css';
import {closeCar, sendQuizData} from '../../../../actions/Technician/cars';
import quizStyles from './style';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormHelperText from "@material-ui/core/FormHelperText";
import api from "../../../../api";
import PhotoInput from "../../../../components/Technician/photoInput";
import {showGalleryPhotos} from "../../../../actions/Technician/components";
import ModalGallery from "../../../../components/Technician/ModalGalleryContainer";

// import GearLoading from '../../../components/Technician/gearLoading';


class QuizFinish extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // если будет необходимо загружать фото сразу же при добавлении,
            // то заменить у полей techPhotos, photosUploaded this.state на this.props в конструкторе
            techPhotos: {
                intFront: this.props.techPhotos && this.props.techPhotos.intFront,
                intBack: this.props.techPhotos && this.props.techPhotos.intBack,
                extFront: this.props.techPhotos && this.props.techPhotos.extFront,
                extBack: this.props.techPhotos && this.props.techPhotos.extBack,
                extLeft: this.props.techPhotos && this.props.techPhotos.extLeft,
                extRight: this.props.techPhotos && this.props.techPhotos.extRight,
            },
            photosUploaded: false,
            insurance: !!props.service.insurance,
            sts: !!props.service.sts,
            key: !!props.service.key,
            serviceFetchingLocal: this.props.serviceFetching,
            authkey: JSON.parse(localStorage.dpJWT),
            saveClicked: false,
        };
        this.fileInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
        this.setState((state, props) => {
            if (state.serviceFetchingLocal !== !!props.serviceFetching) {
                console.warn({componentDidUpdateFetching: !!props.serviceFetching});
                return {
                    serviceFetchingLocal: !!props.serviceFetching
                }
            }
        });
    }

    handleChange = name => (event) => {
        this.setState({...this.state, [name]: event.target.checked});
    };

    sendQuizDataAndCloseCar = (service, quiz) => {
        this.setState({saveClicked: true});
        if (this.props.isPreCheck && !this.state.photosUploaded) {
            return
        }

        const serviceNewData = {
            ...service,
            techPhotos: this.state.techPhotos,
            insurance: this.state.insurance,
            sts: this.state.sts,
            key: this.state.key,
        };
        if (this.props.isPreCheck) {
            this.setState({serviceFetchingLocal: true});
            this.props.sendQuizData({
                    ...serviceNewData,
                    lastStep: true
                },
                quiz
            );

            let delay = 50;
            let self = this;
            let timerId = setTimeout(function tick() {
                if (self.state.serviceFetchingLocal) {
                    delay *= 2;
                    timerId = setTimeout(tick, delay);
                } else {
                    self.props.closeCar(serviceNewData, quiz);
                }
            }, delay);
        } else {
            this.props.closeCar(serviceNewData, quiz);
        }
    };

    addPhotoHandler = (event, key) => {
        const reader = new FileReader();
        const self = this;
        reader.onloadend = function () {
            const formData = {
                image: reader.result, // event.target.files[0],
                _id: self.props.service._id,
                type: key,
            };
            api.photo.addToService(formData)
                .then((res) => {
                    console.warn({result: res.result});
                    self.setState(prevState => ({
                        techPhotos: {
                            ...prevState.techPhotos,
                            [key]: res.result
                        }
                    }));

                    if (
                        !!self.state.techPhotos
                        && typeof self.state.techPhotos === 'object'
                        && !Object.values(self.state.techPhotos).includes(undefined)
                    ) {
                        self.setState({photosUploaded: true})
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    removePhotoHandler = (event, photoKey) => {
        this.setState(prevState => ({
            techPhotos: {
                ...prevState.techPhotos,
                [photoKey]: undefined
            },
            ...(prevState.photosUploaded && {photosUploaded: false})
        }));
        event.stopPropagation();
    };

    handleFullScreenPhoto = (photoIdx) => {
        if (photoIdx === -1) {
            return
        }
        this.props.showGalleryPhotos(Object.values(this.state.techPhotos)
            .filter(photoPath => !!photoPath), photoIdx);
    };

    render() {
        const {classes, closeCarsFetching, service, quiz, isPreCheck} = this.props;
        const {serviceFetchingLocal, techPhotos, saveClicked, key, insurance, sts, authkey, photosUploaded} = this.state;
        const techPhotosKeys = Object.keys(techPhotos);
        const techPhotosValues = Object.values(techPhotos);

        return (
            <React.Fragment>
                <div className={classes.body}>
                    {isPreCheck
                        ? <>
                            <div className={classes.title}>
                                Пожалуйста, убедитесь, что ключ зажигания, страховка и СТС на месте:
                            </div>
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={key}
                                        onChange={this.handleChange('key')}
                                        icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon}/>}
                                        checkedIcon={<CheckBoxIcon className={classes.sizeIcon}/>}
                                    />
                                )}
                                label="Ключ зажигания есть"
                                classes={{label: classes.checkboxes}}
                            />
                            <FormControlLabel className={classes.checkboxes}
                                              control={(
                                                  <Checkbox
                                                      color="primary"
                                                      checked={insurance}
                                                      onChange={this.handleChange('insurance')}
                                                      icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon}/>}
                                                      checkedIcon={<CheckBoxIcon className={classes.sizeIcon}/>}
                                                  />
                                              )}
                                              label="Страховка есть"
                                              classes={{label: classes.checkboxes}}
                                              style={{display: 'flex'}}
                            />
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        color="primary"
                                        checked={sts}
                                        onChange={this.handleChange('sts')}
                                        className={classes.size}
                                        icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon}/>}
                                        checkedIcon={<CheckBoxIcon className={classes.sizeIcon}/>}
                                    />
                                )}
                                label="СТС есть"
                                classes={{label: classes.checkboxes}}
                                style={{display: 'flex'}}
                            />
                            <div className={classes.title}>
                                Пожалуйста, сделайте следующие фотографии и приложите их:
                            </div>
                            <PhotoInput
                                photoKey={techPhotosKeys[0]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото салона спереди'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.intFront}
                                photoPath={techPhotos.intFront}
                                photoIdx={techPhotosValues.indexOf(techPhotos.intFront)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />
                            <PhotoInput
                                photoKey={techPhotosKeys[1]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото салона сзади'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.intBack}
                                photoPath={techPhotos.intBack}
                                photoIdx={techPhotosValues.indexOf(techPhotos.intBack)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />

                            <div className={classes.title}>
                                Отгоните автомобиль на его парковочное место и закройте двери.
                            </div>
                            <div className={classes.title} style={{padding: '0 0 10px 0'}}>
                                После того, как автомобиль поставлен на парковочное место и двери закрыты, сделайте фото
                                автомобиля:
                            </div>

                            <PhotoInput
                                photoKey={techPhotosKeys[2]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото авто (вид спереди)'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.extFront}
                                photoPath={techPhotos.extFront}
                                photoIdx={techPhotosValues.indexOf(techPhotos.extFront)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />
                            <PhotoInput
                                photoKey={techPhotosKeys[3]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото авто (вид сзади)'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.extBack}
                                photoPath={techPhotos.extBack}
                                photoIdx={techPhotosValues.indexOf(techPhotos.extBack)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />
                            <PhotoInput
                                photoKey={techPhotosKeys[4]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото авто (вид слева)'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.extLeft}
                                photoPath={techPhotos.extLeft}
                                photoIdx={techPhotosValues.indexOf(techPhotos.extLeft)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />
                            <PhotoInput
                                photoKey={techPhotosKeys[5]}
                                fileInput={this.fileInput}
                                photoDescr={'Фото авто (вид справа)'}
                                saveClicked={saveClicked}
                                errorCond={saveClicked && !techPhotos.extRight}
                                photoPath={techPhotos.extRight}
                                photoIdx={techPhotosValues.indexOf(techPhotos.extRight)}
                                authkey={authkey}
                                addPhotoHandler={this.addPhotoHandler}
                                removePhotoHandler={this.removePhotoHandler}
                                handleFullScreenPhoto={this.handleFullScreenPhoto}
                            />
                            {saveClicked && !photosUploaded &&
                            <FormHelperText error={true}>{'*Приложите обозначенные фотографии'}</FormHelperText>}

                        </>
                        : <>
                            <div className={classes.title}>
                                Отгоните автомобиль на его парковочное место и закройте двери.
                            </div>
                            <div className={classes.text}>
                                После нажатия кнопки “Завершить осмотр” автомобиль закроется автоматически, проверьте
                                факт
                                закрытия.
                            </div>
                        </>}
                </div>
                <div>
                    {/*<div className={clsx(classes.buttonContainer, classes.secondaryButton)}>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={closeCarsFetching}
                        classes={{
                            root: classes.rootButton,
                            label: classes.labelButton,
                            focusVisible: classes.focusButton,
                        }}
                        // onClick={() => props.openCar({ user, carVin })}
                        onClick={() => console.log('сервисный режим')}
                    >
                        Сервисный режим
                    </Button>
                </div>*/}
                    <div className={classes.buttonContainer}>
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={isPreCheck && saveClicked && !photosUploaded || serviceFetchingLocal || closeCarsFetching}
                            classes={{
                                root: classes.rootButton,
                                label: classes.labelButton,
                                focusVisible: classes.focusButton,
                            }}
                            // onClick={() => props.openCar({ user, carVin })}
                            onClick={() => this.sendQuizDataAndCloseCar(service, quiz)}
                        >
                            Завершить осмотр
                        </Button>
                        {serviceFetchingLocal && closeCarsFetching && <CircularProgress size={30} color="primary"/>}
                    </div>

                    <ModalGallery
                        title="Фото осмотра"
                    />
                </div>
            </React.Fragment>
        );
    };
}

function mapStateToProps(state) {
    return {
        // cars: state.techCars.cars,
        // carsFetching: state.techCars.carsFetching,
        // carsError: state.techCars.carsError,
        closeCarsFetching: state.techCars.closeCarsFetching,
        quiz: state.techCars.quiz,
        service: state.techCars.service,
        // user: state.auth.auth.email,
        isPreCheck: state.techCars.isPreCheck,
        serviceFetching: state.techCars.serviceFetching,
        techPhotos: state.techCars.techPhotos,
    };
}

export default withTheme(withStyles(quizStyles)(connect(mapStateToProps, {
    sendQuizData,
    closeCar,
    showGalleryPhotos
})(QuizFinish)));
