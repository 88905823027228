import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PhotoGallery from './PhotoGallery';
import { closeGalleryPhotos } from '../../actions/Technician/components';

const styles = theme => ({
  dialog: {
    height: 'calc(100% - 96px)',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#444',
  },
  closeButton: {
    right: '20px',
    top: '20px',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  dialogTitle: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    minHeight: '60px',
    paddingRight: '80px',
  },
  title: {
    color: '#00aad2',
    fontSize: '20px',
    fontFamily: 'HyundaiSansHead',
    fontWeight: 600,
  },
  description: {
    color: '#fff',
    fontSize: '18px',
    paddingTop: '0.5em',
  },
});

class ModalGalleryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // open: false,
    };
  }

  render() {
    const { classes, photos, activePhoto, title, description } = this.props;
    // const { damage_size, damaged_part, description, photo, type } = damage;
    console.log('ModalGalleryContainer', this.props, this.state);

    return (
      <React.Fragment>
        {!!photos && !!photos.length && (
          <Dialog
            fullWidth
            // fullScreen
            maxWidth="md"
            open={true}
            onClose={this.props.closeGalleryPhotos}
            classes={{
              paper: classes.dialog,
            }}
          >
            {!!title && !!description && (
              <DialogTitle disableTypography className={classes.dialogTitle}>
                <div className={classes.title}>
                  {title}
                </div>
                <div className={classes.description}>
                  {description}
                </div>
              </DialogTitle>
            )}
            <IconButton color="secondary" aria-label="Close" className={classes.closeButton} onClick={this.props.closeGalleryPhotos}>
              <CloseIcon fontSize="large" />
            </IconButton>
            <PhotoGallery
              photos={photos}
              activePhoto={activePhoto}
            />
          </Dialog>
        )}
      </React.Fragment>
    );
  }
}

// ViewEditDamage.defaultProps = {
//   damage: PropTypes.shape({
//     class: PropTypes.string.isRequired,
//     damage_size: PropTypes.string,
//     damaged_part: PropTypes.string,
//     description: PropTypes.string,
//     photo: PropTypes.arrayOf(PropTypes.string),
//     type: PropTypes.string.isRequired,
//   }),
// };

// ViewEditDamage.propTypes = {
//   title: PropTypes.string.isRequired,
//   damage: PropTypes.shape({
//     class: PropTypes.string.isRequired,
//     damage_size: PropTypes.string,
//     damaged_part: PropTypes.string,
//     description: PropTypes.string,
//     photo: PropTypes.arrayOf(PropTypes.string),
//     type: PropTypes.string,
//   }),
// };
function mapStateToProps(state) {
  return {
    photos: state.techComponents.photosToShow,
    activePhoto: state.techComponents.activePhoto,
    title: state.techComponents.photoTitle,
    description: state.techComponents.photoDescription,
    // user: state.auth.auth.email,
  };
}

export default injectIntl(withStyles(styles)(connect(mapStateToProps, { closeGalleryPhotos })(ModalGalleryContainer)));
