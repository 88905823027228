import api from '../api';
import { actions } from '../reducers/badges';

export const getSideBarBadges = () => (dispatch) => {
  dispatch({ type: actions.GET_BADGES });
  return api.badges.fetchAll()
    .then((response) => {
      dispatch({ type: actions.GET_BADGES_SUCCESS, payload: response.data });
      return response.data;
    })
    .catch((error) => {
      dispatch({ type: actions.GET_BADGES_FAIL, payload: error });
    });
};
