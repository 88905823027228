import axios from 'axios';


function subscribeToVehicles(cb) {
}

function subscribeToVehicle(cb) {
  // socket.on(`vehicle_${}`);
}

export { subscribeToVehicles, subscribeToVehicle };


// const development = 'https://ttri.noixlab.com';
// const production = 'https://api.mobility.hyundai.ru';

// let url = production;
const url = process.env.REACT_APP_API_SERVER || 'https://api.mobility.hyundai.ru';
export const apiPrefix = process.env.REACT_APP_API_PREFIX || '/api/tech';
// const apiPrefix = '/api/tech';
// if (JSON.parse(localStorage.getItem('environment')) === 'development') {
//   url = development;
// }

export default {
  url,
  user: {
    login: post => axios.post(`${url + apiPrefix}/security/login`, { params: post }),
    // login: post => axios.post(url+'/api/wi/v1/user/auth', { post }),
  },
  vehicles: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/vehicles/getlist`).then(res => res.data),
    fetchOne: last_id => axios.post(`${url}/api/wi/v1/vehicles/get`, { post: { last_id } }).then(res => res.data),
    fetchCarData: () => axios.post(`${url}/api/wi/v1/vehicles/getenumList`).then(res => res.data),
    save: data => axios.post(`${url}/api/wi/v1/vehicles/save/${data.vehicle_id}`, { post: data }).then(res => res.data),
    add: vehicle => axios.post(`${url}/api/wi/v1/vehicles/add`, { post: { vehicle } }).then(res => res.data),
    openCommand: last_id => axios.post(`${url}/api/wi/v1/vehicles/cmd`, { post: { vehicle_id: `${last_id}`, cmd: 'OUTOFF GRDOFF' } }).then(res => res),
    closeCommand: last_id => axios.post(`${url}/api/wi/v1/vehicles/cmd`, { post: { vehicle_id: `${last_id}`, cmd: 'GRDON' } }).then(res => res),
    blinkCommand: last_id => axios.post(`${url}/api/wi/v1/vehicles/cmd`, { post: { vehicle_id: `${last_id}`, cmd: 'AVAR' } }).then(res => res),
    customCommand: data => axios.post(`${url}/api/wi/v1/vehicles/cmd`, { post: { vehicle_id: `${data.vehicle_id}`, cmd: `${data.cmd}` } }).then(res => res),
    vehicleParameters: () => axios.post(`${url}/api/wi/v1/vehicles/getenumlist`).then(res => res.data),
    addParameter: data => axios.post(`${url}/api/wi/v1/vehicles/addenumlist`, { post: data }).then(res => res.data),
    editParameter: data => axios.post(`${url}/api/wi/v1/vehicles/editenumlist`, { post: { enum: `${data.enumName}`, collection: `${data.collection}`, old_enum: `${data.old_enum}` } }).then(res => res.data),
    deleteParameter: data => axios.post(`${url}/api/wi/v1/vehicles/deleteenumlist`, { post: { enum: `${data.enumName}`, collection: `${data.collection}`, old_enum: `${data.old_enum}` } }).then(res => res.data),
  },
  users: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/user/getall`).then(res => res.data),
    fetchOne: last_id => axios.post(`${url}/api/wi/v1/profile/get/${last_id}`).then(res => res.data),
    addUser: data => axios.post(`${url}/api/wi/v1/profile/add`, { post: { data } }).then(res => res.data),
    saveUser: data => axios.post(`${url}/api/wi/v1/profile/save`, { post: { data } }).then(res => res.data),
    deleteUser: phone => axios.post(`${url}/api/wi/v1/profile/delete`, { post: { phone } }).then(res => res),
  },
  documents: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/documents/getall`).then(res => res.data), // documents/getall
    fetchOne: last_id => axios.post(`${url}/api/wi/v1/documents/get/${last_id}`).then(res => res.data),
    // fetchDocumentData: () => axios.post('https://ttri.noixlab.com/api/wi/v1/documents/allnationality',).then(res => res.data),
    disallowAccess: data => axios.post(`${url}/api/wi/v1/documents/save`, { post: { data, status: 0 } }).then(res => res.data),
    allowAccess: data => axios.post(`${url}/api/wi/v1/documents/save`, { post: { data, status: 4 } }).then(res => res.data),
    saveAccess: data => axios.post(`${url}/api/wi/v1/documents/save`, { post: { data, status: 2 } }).then(res => res.data),
  },
  booking: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/reservation/list`).then(res => res.data),
    fetchOne: order_id => axios.post(`${url}/api/wi/v1/reservation/get/${order_id}`).then(res => res.data),
    updateStatus: post => axios.post(`${url}/api/wi/v1/reservation/update_status/${post.order_id}`, { post: { status_session: `${post.status_session}` } }).then(res => res.data),
  },
  badges: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/info/getsidebar`).then(res => res.data),
  },
  devices: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/device/getlist`).then(res => res.data),
    fetchOne: device_id => axios.post(`${url}/api/wi/v1/device/get/${device_id}`).then(res => res.data),
    saveDevice: data => axios.post(`${url}/api/wi/v1/device/save`, { post: { device_id: data.device_id, device_status: data.device_status, phone: data.phone } }).then(res => res.data),
    deleteDevice: device_id => axios.post(`${url}/api/wi/v1/device/delete`, { post: { device_id } }).then(res => res),
  },
  localization: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/language/list`).then(res => res.data),
    fetchOne: data => axios.post(`${url}/api/wi/v1/language/get/${data.lang}/${data.name}`).then(res => res.data),
    add: data => axios.post(`${url}/api/wi/v1/language/add`, { post: data }).then(res => res.data),
    save: data => axios.post(`${url}/api/wi/v1/language/save`, { post: data }).then(res => res.data),
    delete: data => axios.post(`${url}/api/wi/v1/language/delete`, { post: data }).then(res => res),
  },
  messages: {
    fetchAll: () => axios.post(`${url + apiPrefix}/security/localization`, { params: {} }).then(res => res.data),
  },
  feedback: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/feedback/list`).then(res => res.data),
    fetchOne: last_id => axios.post(`${url}/api/wi/v1/feedback/get/${last_id}`).then(res => res.data),
    changeStatus: last_id => axios.post(`${url}/api/wi/v1/feedback/Readed/${last_id}`).then(res => res),
  },
  order: {
    fetchAll: () => axios.post(`${url}/api/wi/v1/order/getall`).then(res => res.data),
    fetchOne: last_id => axios.post(`${url}/api/wi/v1/order/get/${last_id}`).then(res => res.data),
    repay: last_id => axios.post(`${url}/api/wi/v1/order/fastpay/${last_id}`).then(res => res),
    charge: last_id => axios.post(`${url}/api/wi/v1/order/autopay/${last_id}`).then(res => res),
    save: data => axios.post(`${url}/api/wi/v1/order/save/${data.invoice_id}`, { post: data }).then(res => res.data),
    add: invoice => axios.post(`${url}/api/wi/v1/order/add`, { post: { invoice } }).then(res => res.data),
  },
  tech: {
    getVehicles: data => axios.post(`${url + apiPrefix}/service/getVehiclesForService`, data).then(res => res.data),
    getVehicles2: data => axios.post(`${url}/api/tech/service/getVehiclesForService2`, data).then(res => res.data),
    getOrNewService: data => axios.post(`${url + apiPrefix}/service/getService`, data).then(res => res.data),
    editService: data => axios.post(`${url + apiPrefix}/service/setService`, data).then(res => res.data),
    completeService: data => axios.post(`${url + apiPrefix}/service/completeService`, data).then(res => res.data),
    setDamage: data => axios.post(`${url + apiPrefix}/damage/setDamage`, data).then(res => res.data),
    removeDamage: data => axios.post(`${url + apiPrefix}/damage/removeDamage`, data).then(res => res.data),
  },
  techAdmin: {
    getTechnicians: data => axios.post(`${url}/api/tech/security/listTechnicanUsers`, data).then(res => res.data),
    createTechnician: data => axios.post(`${url}/api/tech/security/createTechnicanUser`, data).then(res => res.data),
    editTechnician: data => axios.post(`${url}/api/tech/security/editTechnicanUser`, data).then(res => res.data),
    removeTechnician: data => axios.post(`${url}/api/tech/security/deleteTechnicanUser`, data).then(res => res.data),
  },
  photo: {
    add: photo => axios.post(`${url + apiPrefix}/damage/loadImage`, { params: photo }).then(res => res.data),
    addToService: photo => axios.post(`${url + apiPrefix}/service/loadImage`, { params: photo }).then(res => res.data),
    // add: photo => axios.post(url + '/api/wi/v1/upload/image', photo).then(res => res.data),
  },
  refueling: {
    getOrdersRefueling: () => axios.get(`${url}/crm/api/v2/refueling-request-service`).then(res => res.data),

    openDoorVehicleById: data => axios.get(`${url}/crm/api/v2/vehicle-technic/door/open/${data}`).then(res => res.data),
    closeDoorVehicleById: data => axios.get(`${url}/crm/api/v2/vehicle-technic/door/close/${data}`).then(res => res.data),
    turnOnEmergencyVehicleById: data => axios.get(`${url}/crm/api/v2/vehicle-technic/emergency/${data}`).then(res => res.data),

    getVehicleLastPacket: data => axios.get(`${url}/crm/api/v2/vehicle-technic/mta-packet/${data}`).then(res => res.data),
    startRefuelingVehicleById: data => axios.post(`${url}/crm/api/v2/refueling-request-service/start`, data).then(res => res.data),
    finishRefuelingVehicleById: data => axios.post(`${url}/crm/api/v2/refueling-request-service/complete`, data).then(res => res.data),
  }
};
