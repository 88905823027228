import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';

import editIcon from '../../../../assets/images/icMenuCheck.svg';
import Button from '@material-ui/core/Button';
import {sendQuizData} from '../../../../actions/Technician/cars';
import QuizButton from '../../../../components/Technician/QuizButton';
import quizStyles from './style';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";


class QuizMissedRefueling extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fuelCardSelected: null,
            fuelToFullTank: null,
            tank_opacity: null,
            deviceLevel_fuel1: null,

            last_completed_service_fuel: null,
            missed_refuel_surplus: null,
            missed_refuel_actual: '',
            formUntouched: true,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const {car} = this.props;
        this.setState({
            fuelToFullTank: car.fuelToFullTank,
            tank_opacity: car.tank_opacity,
            deviceLevel_fuel1: car.deviceLevel_fuel1,

            last_completed_service_fuel: car.last_completed_service_fuel,
            missed_refuel_surplus: car.deviceLevel_fuel1 - car.last_completed_service_fuel,
        })
    }

    handleChange(event) {
        console.warn('handleChange')
        this.setState({
            missed_refuel_actual: +event.target.value,
            formUntouched: false,
        });
    }

    render() {
        const {classes, service} = this.props;
        console.warn({
            last_completed_service_fuel: this.state.last_completed_service_fuel,
            missed_refuel_surplus: this.state.missed_refuel_surplus,
            missed_refuel_actual: this.state.missed_refuel_actual,

        })

        return (
            <React.Fragment>
                <div className={classes.body}>
                    <div className={classes.title}>
                        <p>Внимание! На текущий момент топлива в баке больше на {this.state.missed_refuel_surplus} л,
                            чем в предыдущий осмотр.</p>
                    </div>
                    <div className={classes.text}>
                        Пожалуйста, введите кол-во топлива, которое было залито в бак вне системы.
                    </div>

                    <FormControl className={classes.text}>
                        <InputLabel htmlFor="fuel-surplus">залито вне системы</InputLabel>
                        <Input
                            id="fuel-surplus"
                            value={this.state.missed_refuel_actual}
                            type='number'
                            onChange={this.handleChange}
                            aria-describedby="component-helper-text"
                            endAdornment={<InputAdornment position="end">л.</InputAdornment>}
                        />
                    </FormControl>

                </div>


                <QuizButton
                    disabled={this.state.formUntouched}
                    service={{...service, ...this.state}}
                    label="Продолжить"
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        lastUsedFuelCard: state.techCars.lastUsedFuelCard,
        fuelCards: state.techCars.fuelCards,
        serviceFetching: state.techCars.serviceFetching,
        quiz: state.techCars.quiz,
        service: state.techCars.service,
        user: state.auth.auth.email,
    };
}

export default withStyles(quizStyles)(connect(mapStateToProps, {sendQuizData})(QuizMissedRefueling));
