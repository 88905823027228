import React from 'react';
import clsx from 'clsx';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import CloseIcon from '@material-ui/icons/Close';
// import '../../../assets/css/style.css';
import {sendQuizData, addDamage} from '../../actions/Technician/cars';
// import ViewEditDamage from '../../../../components/Technician/ViewEditDamage';
// import ModalGallery from '../../../../components/Technician/ModalGalleryContainer';
import quizStyles from '../../pages/Technician/Quiz/Prepare/style';

// import GearLoading from '../../../components/Technician/gearLoading';

class QuizButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {classes, service, label, quiz, serviceFetching, disabled, showErrors, noErrors} = this.props;
        console.log('QuizButton', this.props);
        // const classes = useStyles();

        return (
            <div className={classes.buttonContainer}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={disabled || serviceFetching}
                    classes={{
                        root: clsx(classes.rootButton, classes.primaryButton),
                        label: classes.labelButton,
                        focusVisible: classes.focusButton,
                    }}
                    onClick={() => this.props.sendQuizData(service, quiz)}
                >
                    {label}
                </Button>
                {serviceFetching && <CircularProgress size={30} color="primary"/>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        // damageNew: state.techCars.service.damage_new,
        // showErrors: state.techCars.damagesFieldsReady,
        // noErrors: state.techCars.noErrors,
        // user: state.auth.auth.email,
        serviceFetching: state.techCars.serviceFetching,
        quiz: state.techCars.quiz,
    };
}

export default withStyles(quizStyles)(connect(mapStateToProps, {sendQuizData, addDamage})(QuizButton));
