import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import '../../../assets/css/style.css';
import { sendQuizData } from '../../../../actions/Technician/cars';
import QuizButton from '../../../../components/Technician/QuizButton';
import quizStyles from './style';
// import GearLoading from '../../../components/Technician/gearLoading';

class QuizDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insurance: !!props.service.insurance,
      sts: !!props.service.sts,
      key: !!props.service.key,
    };
    // this.car = this.props.match.params.car;
  }

  handleChange = name => (event) => {
    console.log('name', name, 'event', event.target.checked);
    this.setState({ ...this.state, [name]: event.target.checked });
  };

  render() {
    const { classes, user, quiz, carVin, carsFetching, service } = this.props;
    // console.log('QuizDocuments', this);

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title}>
            Ключи на месте?
          </div>
          <div className={classes.text}>
            Проверьте наличие ключей в бардачке или в передней части салона.
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={this.state.key}
                onChange={this.handleChange('key')}
                icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
              />
            )}
            label="Ключ зажигания есть"
            classes={{ label: classes.checkboxes }}
          />
          <div className={classes.title}>
            Документы на месте?
          </div>
          <div className={classes.text}>
            Проверьте наличие СТС, страховки.
          </div>
          <FormControlLabel className={classes.checkboxes}
            control={(
              <Checkbox
                color="primary"
                checked={this.state.insurance}
                onChange={this.handleChange('insurance')}
                icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
              />
            )}
            label="Страховка есть"
            classes={{ label: classes.checkboxes }}
            style={{ display: 'flex' }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={this.state.sts}
                onChange={this.handleChange('sts')}
                className={classes.size}
                icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon} />}
                checkedIcon={<CheckBoxIcon className={classes.sizeIcon} />}
              />
            )}
            label="СТС есть"
            classes={{ label: classes.checkboxes }}
            style={{ display: 'flex' }}
          />
        </div>

        <QuizButton
          service={{ ...service, ...this.state }}
          label="Подтвердить"
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    // cars: state.techCars.cars,
    // carsFetching: state.techCars.carsFetching,
    // carsError: state.techCars.carsError,
    serviceFetching: state.techCars.serviceFetching,
    quiz: state.techCars.quiz,
    service: state.techCars.service,
    user: state.auth.auth.email,
  };
}

export default withStyles(quizStyles)(connect(mapStateToProps, { sendQuizData })(QuizDocuments));
