import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Wrapper, Header } from './styledComponents';
import OrderRefuelingButtons from '../OrderRefuelingButtons/index';
import OrderRefuelingContent from '../OrderRefuelingContent/OrderRefuelingContent';
import NotificationRefueling from '../Notification/Notification';

const OrderRefueling = props => {
    const {
        notification,
        ordersRefueling,
        setNotification,
    } = props;

    const orderId = props.match?.params?.orderId;

    const order = useMemo(() => {
        return ordersRefueling.find(order => order._id === orderId) || {};
    }, [orderId, ordersRefueling]);

    return (
        <Wrapper>
            <Wrapper.Header>
                <Header.vehicle>
                    {order.vehicle}
                </Header.vehicle>
                <Header.grz>
                    {order.grz}
                </Header.grz>
            </Wrapper.Header>
            <Wrapper.Container>
                <OrderRefuelingContent
                    order={order}
                />
            </Wrapper.Container>
            <Wrapper.buttons>
                <OrderRefuelingButtons
                    status={order.status}
                    vehicleId={order.vehicleId}
                    refuelingId={order._id}
                    orderFinishTime={order?.dateEnd}
                />
            </Wrapper.buttons>
            {notification &&
                <NotificationRefueling
                    notification={notification}
                    setNotification={setNotification}
                />
            }
        </Wrapper>
    );
};

OrderRefueling.propTypes = {
    ordersRefueling: PropTypes.array.isRequired,
    setNotification: PropTypes.func.isRequired,
};

export default OrderRefueling;
