import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #f6f3f2;
  padding: 1em;
  box-sizing: border-box;
  font-size: 18px;
`;
Header.vehicle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.39px;
  color: #39393f;
  font-family: HyundaiSansHead;
`;
Header.grz = styled.div`
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
  font-family: HyundaiSansHead;
  margin: 0 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 92px;
`;
Wrapper.Header = Header;
Wrapper.Container = styled.div`
  padding: 1rem;
  box-sizing: border-box;
`;
Wrapper.buttons = styled.div`
  width: 100%;
  flex: 1;
`;
