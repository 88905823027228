import React from 'react';
import PropTypes from 'prop-types';
import { getLocalFullDate } from '../../../../utils/dateTimeFormat';
import { getStatusColor, getStatusString } from '../../logic';
import { Adress, Date, Notes, Order, Products, Status, Wrapper, Capacity, Number } from './styledComponents';

const OrderRefuelingContent = props => {
    const {
        order,
    } = props;

    const colorStatus = getStatusColor(order.status);

    return (
        <Wrapper>
            <Wrapper.Status>
                <Status.indicator color={colorStatus}/>
                <Status.value>
                    {getStatusString(order.status)}
                </Status.value>
            </Wrapper.Status>
            <Wrapper.Number>
                <Number.text>
                    Номер заказа:
                </Number.text>
                <Number.value>
                    {order.orderId}
                </Number.value>
            </Wrapper.Number>
            {order.orderTime &&
                <Wrapper.Date>
                    <Date.text>
                        Время заказа:
                    </Date.text>
                    <Date.value>
                        {getLocalFullDate(order.orderTime)}
                    </Date.value>
                </Wrapper.Date>
            }
            {order.dateStart &&
                <Wrapper.Date>
                    <Date.text>
                        Начало выполнения заказа:
                    </Date.text>
                    <Date.value>
                        {getLocalFullDate(order.dateStart)}
                    </Date.value>
                </Wrapper.Date>
            }
            {order.dateEnd &&
                <Wrapper.Date>
                    <Date.text>
                        Окончание выполнения заказа:
                    </Date.text>
                    <Date.value>
                        {getLocalFullDate(order.dateEnd)}
                    </Date.value>
                </Wrapper.Date>
            }
            {order.address &&
                <Wrapper.Adress>
                    <Adress.text>
                        Фактический адрес:
                    </Adress.text>
                    <Adress.value>
                        {order.address}
                    </Adress.value>
                </Wrapper.Adress>
            }
            <Wrapper.Order>
                {order.capacity &&
                    <Order.Capacity>
                        <Capacity.text>
                            Объем:
                        </Capacity.text>
                        <Capacity.value>
                            {order.capacity}
                        </Capacity.value>
                    </Order.Capacity>
                }
                {Array.isArray(order.products) &&
                    <Order.Products>
                        {order.products.map(product => (
                            <Products.item key={product}>{product}</Products.item>
                        ))}
                    </Order.Products>
                }
            </Wrapper.Order>
            {order.refueling_info &&
                <Wrapper.Notes>
                    <Notes.text>
                        Как заправлять:
                    </Notes.text>
                    <Notes.value>
                        {order.refueling_info}
                    </Notes.value>
                </Wrapper.Notes>
            }
            {order.notes &&
                <Wrapper.Notes>
                    <Notes.text>
                        Комментарий:
                    </Notes.text>
                    <Notes.value>
                        {order.notes}
                    </Notes.value>
                </Wrapper.Notes>
            }
        </Wrapper>
    )
};

OrderRefuelingContent.propTypes = {
    order: PropTypes.object.isRequired,
};

export default OrderRefuelingContent;
