import React from 'react';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

const questionInputStyle = () => ({
  input: {
    paddingTop: '1rem',
  },
  question: {
    margin: '0.5rem 0 1.5rem 0',
  },
  questionLow: {
    margin: '0.5rem 0',
  },
  formControl1: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 24px) scale(1)',
  },
  formControl2: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 8px) scale(1)',
  },
  shrink1: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  shrink2: {
    transform: 'translate(0, -10px) scale(0.75)',
    transformOrigin: 'top left',
  },
  hidden: {
    // display: "none",
    visibility: 'hidden',
    height: 0,
    margin: 0,
  },
});


class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
      name: Array.isArray(props.name) ? props.name[0] : props.name,
    };
  }

  debouncePush = debounce((str) => {
    let value = str;
    if (this.props.number) {
      value = value.replace(/,/g, '.').replace(/[^\d.-]/g, '');
    }
    this.setState({ value: value });
    if (Array.isArray(this.props.name)) {
      // this.props.name.map(val => this.props.handleInputChange({ name: val, value: value }));
      const arr = this.props.name.map(val => ({ name: val, value: value }));
      this.props.handleInputChange(arr);
    } else {
      this.props.handleInputChange({ name: this.props.name, value: value });
    }
    // this.props.updateInputValue({name: this.props.name, section: this.props.section, value});
  }, 100);

  handleChange = (value) => {
    this.setState({ value: value });
    this.debouncePush(value);
  };

  render() {
    // console.log('InputField', this.props, this.state);
    return (
      <TextField
        id={this.state.name}
        className={clsx(this.props.className)}
        name={this.state.name}
        value={this.state.value}
        onChange={e => this.handleChange(e.target.value)}
        InputProps={this.props.adornment ? { startAdornment: <InputAdornment position="start">{this.props.adornment}</InputAdornment> } : undefined}
        label={this.props.label}
        placeholder={this.props.placeholder}
        type={this.props.type || 'text'}
        disabled={this.props.disabled}
        fullWidth
        autoFocus={this.props.autoFocus}
        error={this.props.error}
        helperText={this.props.helperText || ''}
      />
    )
  }
}

export default withStyles(questionInputStyle)(InputField);
