export const getStylesRefuelingNav = () => ({
    appBar: {
        width: '100vw',
        backgroundColor: 'white',
        position: 'fixed',
        textAlign: 'center',
        boxShadow: 'none',
    },
    topBar: {
        height: '92px',
        display: 'flex',
    },
    title: {
        margin: 'auto',
        fontFamily: 'HyundaiSansHead',
        fontSize: '24px',
        fontWeight: '600',
        color: '#333355',
    },
    drawer: {
        maxWidth: '60%',
        width: '300px',
    },
    stub: {
        flex: 1,
    },
    menuTitle: {
        fontFamily: 'HyundaiSansHead',
        fontSize: '24px',
        fontWeight: '600',
        color: '#333355',
        margin: '0 auto 10px',
    },
    menuBar: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menuButtonRoot: {
        color: '#00aad2',
        padding: '4px',
    },
    backButtonRoot: {
        color: '#00aad2',
    },
    backButtonIcon: {
        width: '40px',
        height: '40px',
    },
    img: {
        width: '86px',
        height: '86px',
        alignSelf: 'center',
    },
    buttonIcon: {
        paddingLeft: 5,
    },
    drawerButtons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});
