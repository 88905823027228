import {createSelector} from "reselect";
import moment from "moment";

export const getNotification = state => state.refueling.notification;
export const getOrdersFetching = state => state.refueling.ordersFetching;
export const getOrdersRefueling = state => state.refueling.ordersRefueling;
export const getStatusesRefueling = state => state.refueling.statusesRefueling;
export const getControlButtonsFetching = state => state.refueling.controlButtonsFetching;
export const getSelectedStatusRefueling = state => state.refueling.selectedStatusRefueling;
export const getPaginationPageRefueling = state => state.refueling.paginationPageRefueling;
export const getPaginationSizeRefueling = state => state.refueling.paginationSizeRefueling;
export const getSearchTermRefueling = state => state.refueling.searchTermRefueling;
export const getSortRefueling = state => state.refueling.sortRefueling;

const setOrdersRefuelingByStatus = (ordersRefueling = [], selectedStatusRefueling) => {
    switch (selectedStatusRefueling) {
        case 'ALL':
            break;
        default:
            ordersRefueling = ordersRefueling.filter((order) => order.status === selectedStatusRefueling);
            break;
    }
    return ordersRefueling;
}

const searchMatch = (str = '', term = '') => {
    if (!str || !term) {
        return false;
    }
    term = term.trim().toLowerCase();
    str = str.trim().toLowerCase();
    return str.match(term)
}

const setOrdersRefuelingBySearchTerm = (ordersRefueling = [], searchTermRefueling = '') => {
    if (!searchTermRefueling) {
        return ordersRefueling;
    }
    return ordersRefueling.filter(({grz, orderId, address}) => {
        return (
            searchMatch(grz, searchTermRefueling) ||
            searchMatch(orderId, searchTermRefueling) ||
            searchMatch(address, searchTermRefueling)
        );
    });
}

const setOrdersRefuelingPagination = (
    ordersRefueling = [],
    paginationSizeRefueling,
    paginationPageRefueling = 1
) => {
    const start = (paginationPageRefueling - 1) * paginationSizeRefueling;
    return ordersRefueling.slice(start, start + paginationSizeRefueling);
}

const setOrdersRefuelingByFilters = (ordersRefueling, statusesRefueling, searchTermRefueling) => {
    const ordersRefuelingByStatus = setOrdersRefuelingByStatus(ordersRefueling, statusesRefueling);
    return setOrdersRefuelingBySearchTerm(ordersRefuelingByStatus, searchTermRefueling);
};

const setOrdersRefuelingSort = (ordersRefueling = [], sort) => {
    const items = [...ordersRefueling];
    sort.map(({name, order, type}) => {
        let compareFn = () => 0
        switch (type) {
            case 'date':
                compareFn = order ?
                    (a, b) => moment(a[name]).unix() - moment(b[name]).unix() :
                    (a, b) => moment(b[name]).unix() - moment(a[name]).unix()
                break;
            default:
                compareFn = order ?
                    (a, b) => a[name] - b[name] :
                    (a, b) => b[name] - a[name]
                break;
        }
        items.sort(compareFn);
    });
    return items;
}

const selectOrdersRefuelingByFilters = createSelector([
    getOrdersRefueling,
    getSelectedStatusRefueling,
    getSearchTermRefueling
], (
    ordersRefueling, statusesRefueling, searchTermRefueling
) => setOrdersRefuelingByFilters(ordersRefueling, statusesRefueling, searchTermRefueling));

const selectOrdersRefueling = createSelector([
    selectOrdersRefuelingByFilters,
    getPaginationSizeRefueling,
    getPaginationPageRefueling,
    getSortRefueling
], (
    ordersRefueling,
    paginationSizeRefueling,
    paginationPageRefueling,
    sortRefueling
) => {
    const ordersRefuelingSort = setOrdersRefuelingSort(ordersRefueling, sortRefueling);
    return setOrdersRefuelingPagination(ordersRefuelingSort, paginationSizeRefueling, paginationPageRefueling);
})

const selectPaginationCountRefueling = createSelector([selectOrdersRefuelingByFilters, getPaginationSizeRefueling], (
    ordersRefueling,
    paginationSizeRefueling
) => {
    return Math.ceil(ordersRefueling.length / paginationSizeRefueling);
})

export const getOrdersRefuelingByFilters = (state) => selectOrdersRefueling(state)
export const getPaginationCountRefueling = (state) => selectPaginationCountRefueling(state);

const selectControlButtonsFetching = createSelector([getControlButtonsFetching], (
    controlButtons
) => {
    return Object.values(controlButtons).some((v) => v);
})
export const getControlButtonsDisabling = (state) => selectControlButtonsFetching(state);

