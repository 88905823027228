export const LOCALE_SET = "LOCALE_SET";
export const MESSAGES_FETCHED = "MESSAGES_FETCHED";
export const TABLE_PAGINATION = 'TABLE_PAGINATION';
export const ROWS_PER_PAGE = 'ROWS_PER_PAGE';

export const GET_CARS = 'GET_CARS';
export const GET_CARS_SUCCESS = 'GET_CARS_SUCCESS';
export const GET_CARS_FAIL = 'GET_CARS_FAIL';
export const GET_ONE_CAR = 'GET_ONE_CAR';
export const GET_ONE_CAR_SUCCESS = 'GET_ONE_CAR_SUCCESS';
export const GET_ONE_CAR_FAIL = 'GET_ONE_CAR_FAIL';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
