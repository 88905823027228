import styled from "styled-components";
export const Status = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
Status.indicator = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin-right: 10px;
`;
Status.value = styled.div``;

export const Date = styled.div`
  margin-bottom: 10px;
`;
Date.text = styled.div`
  margin-bottom: 5px;
  color: #99999c;
  font-weight: 400;
`;
Date.value = styled.div``;

export const Adress = styled.div`
  margin-bottom: 1rem;
`;
Adress.text = styled.div`
  margin-bottom: 5px;
  color: #99999c;
  font-weight: 400;
`;
Adress.value = styled.div``;

export const Products = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
Products.item = styled.div`
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
  padding: 5px 10px;
  flex: 0 1 auto;
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap;
  margin: 5px 5px 5px 0;
`;

export const Capacity = styled.div`
  display: flex;
`;
Capacity.text = styled.div`
  color: #99999c;
  font-weight: 400;
  margin-right: 0.5rem;
`;
Capacity.value = styled.div``;

export const Order = styled.div``;
Order.Capacity = Capacity;
Order.Products = Products;

export const Notes = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;
Notes.text = styled.div`
  color: #99999c;
  font-weight: 400;
  margin-right: 0.5rem;
`;
Notes.value = styled.div``;

export const Number = styled.div`
  margin-bottom: 10px;
`;
Number.text = styled.div`
  margin-bottom: 5px;
  color: #99999c;
  font-weight: 400;
`;
Number.value = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
`;
Wrapper.Status = Status;
Wrapper.Adress = Adress;
Wrapper.Order = Order;
Wrapper.Notes = Notes;
Wrapper.Date = Date;
Wrapper.Number = Number;
