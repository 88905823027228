import RefuelingNav from './RefuelingNav';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { logout, updateApp } from '../../actions/auth';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { getStylesRefuelingNav } from './logic';
import * as selectors from '../../selectors/auth';

export const mapStateToProps = state => ({
    roles: selectors.getRoles(state),
});

export default injectIntl(withRouter(withStyles(getStylesRefuelingNav)(connect(
    mapStateToProps,
    { logout, updateApp }
)(RefuelingNav))));
