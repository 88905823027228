import Dashboard from './Dashboard';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getDashboardStyles } from './logic';
import { getCars, setSelectedStatus } from '../../../actions/Technician/cars';
import * as selectors from '../../../selectors/cars';

export const mapStateToProps = state => ({
    cars: selectors.getCars(state),
    statuses: selectors.getStatuses(state),
    carsError: selectors.getCarsError(state),
    carsFetching: selectors.getCarsFetching(state),
    selectedStatus: selectors.getSelectedStatus(state),
});

export default withStyles(getDashboardStyles)(connect(
    mapStateToProps,
    { getCars, setSelectedStatus }
)(Dashboard));
