import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f6f3f2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-zize: 14px;
`;
Wrapper.indicator = styled.div`
  width: 13px;
  height: 13px;
  background-color: ${props => props.color};
  border-radius: 50%;
  margin: 0.5em 0 0.5em 0.5em;
`;
Wrapper.value = styled.div`
  margin: 0.9em 0.6em 0.6em;
`;
Wrapper.counter = styled.div`
  margin: 0.75em 0.8em 0.5em 0;
`;
