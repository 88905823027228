import PropTypes from "prop-types";
import React, { useMemo, useState, useCallback } from 'react';
import { technicianTheme } from '../../appTheme';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { ThemeProvider } from '@material-ui/styles';
import IconButton from "@material-ui/core/IconButton";
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import Typography from "@material-ui/core/Typography";
import menuIcon from "../../assets/images/icon_mainmenu.svg";
import Drawer from "@material-ui/core/Drawer/Drawer";
import logo from "../../assets/images/logo.svg";
import StatusesRefueling from "../../pages/Refueling/components/Statuses";
import RefreshIcon from '@material-ui/icons/Refresh';
import Exit from '@material-ui/icons/ExitToApp';
import Button from "@material-ui/core/Button";

const RefuelingNav = props => {
    const {
        logout,
        classes,
        history,
        location,
        updateApp,
    } = props;
    const [ sidebarOpen, setSidebarOpen ] = useState(false);

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const headerData = useMemo(() => {
        const { pathname } = location;

        if (typeof pathname === 'string' && pathname.indexOf('refueling') !== -1) {
            return {
                title: 'Заказ',
                backButtonShow: true,
            };
        }

        return {
            title: 'Очередь',
            backButtonShow: false,
        };

    }, [location]);

    const openDrawer = useCallback(() => {
        setSidebarOpen(true);
    }, [setSidebarOpen]);

    const closeDrawer = useCallback(() => {
        setSidebarOpen(false);
    }, [setSidebarOpen]);

    return (
        <ThemeProvider theme={technicianTheme}>
            <AppBar
                position="static"
                color="primary"
                classes={{
                    root: classes.appBar,
                }}
            >
                <Toolbar
                    className={classes.topBar}
                >
                    {headerData.backButtonShow
                        ? (
                            <IconButton
                                classes={{
                                    root: classes.backButtonRoot,
                                }}
                                aria-label="Back"
                                onClick={goBack}
                            >
                                <ArrowBack className={classes.backButtonIcon}/>
                            </IconButton>
                        ) : (
                            <div className={classes.backButtonIcon} />
                        )
                    }
                    <Typography variant="h6" color="inherit" className={classes.title}>
                        {headerData.title}
                    </Typography>
                    <IconButton
                        classes={{
                            root: classes.menuButtonRoot,
                        }}
                        aria-label="Menu"
                        onClick={openDrawer}
                    >
                        <img src={menuIcon} height="46" width="46" alt="" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                open={sidebarOpen}
                onClose={closeDrawer}
                anchor="right"
                classes={{
                    paper: classes.drawer,
                }}
            >
                <img src={logo} alt="" className={classes.img} />
                <div className={classes.menuBar}>
                    <Typography variant="h6" color="inherit" className={classes.menuTitle}>
                        Очередь
                    </Typography>
                    <StatusesRefueling/>
                </div>
                <div className={classes.stub}></div>
                <div className={classes.drawerButtons}>
                    <Button color="primary" onClick={() => updateApp()}>
                        Обновить приложение
                        <RefreshIcon className={classes.buttonIcon} />
                    </Button>
                    <Button color="primary" onClick={() => logout()}>
                        Выход
                        <Exit className={classes.buttonIcon} />
                    </Button>
                </div>
            </Drawer>
        </ThemeProvider>
    );
};

RefuelingNav.propsTypes = {
    roles: PropTypes.array.isRequired,
    logout: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    updateApp: PropTypes.func.isRequired,
};

export default RefuelingNav;
