import {actions} from '../../reducers/Refueling/refueling';
import api from '../../api';
import {createNotification} from "../../utils/createNotification";
import {closeDoorVehicle, getCloseDoorErrors} from "./helpers/close-door-vehicle";

const notificationNetworkError = createNotification(
    'Действие выполнить невозможно. Проблема со связью с автомобилем!', 'error'
);

const notificationOpenDoorError = createNotification(
    'Действие выполнить невозможно. Проверьте двери!', 'error'
);

export const setSelectedStatusRefueling = payload => (dispatch) => {
    dispatch({type: actions.SET_ORDERS_REFUELING, payload: []});
    dispatch({type: actions.SET_PAGINATION_PAGE_REFUELING, payload: 1});
    dispatch({type: actions.SET_SEARCH_TERM_REFUELING, payload: ''});
    dispatch({type: actions.SET_SELECTED_STATUS_REFUELING, payload});
};

export const setSearchTermRefueling = payload => (dispatch) => {
    dispatch({type: actions.SET_PAGINATION_PAGE_REFUELING, payload: 1});
    dispatch({type: actions.SET_SEARCH_TERM_REFUELING, payload});
};

export const setPaginationPageRefueling = payload => (dispatch) => {
    dispatch({type: actions.SET_PAGINATION_PAGE_REFUELING, payload});
};

export const setSortRefueling = payload => (dispatch) => {
    dispatch({type: actions.SET_SORT_REFUELING, payload});
};

export const setNotification = payload => (dispatch) => {
    dispatch({type: actions.NOTIFICATION, payload});
};

export const getOrdersRefueling = () => (dispatch) => {
    dispatch({type: actions.SET_ORDERS_FETCHING, payload: true});

    api.refueling.getOrdersRefueling()
        .then((response) => {
            dispatch({type: actions.SET_ORDERS_REFUELING, payload: response});
        })
        .catch((error) => {
            console.warn('error', error);
            dispatch({type: actions.SET_ORDERS_FETCHING, payload: false});
        })
        .finally(() => {
            dispatch({type: actions.NOTIFICATION, payload: setNotification()});
        });
};

export const openDoorVehicleById = payload => (dispatch) => {
    dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {openDoor: true}});

    api.refueling.openDoorVehicleById(payload)
        .then((response) => {
            let notification = createNotification(
                'Машина открыта и будет закрыта автоматически через 1 минуту!',
                'warning'
            );
            if (!response) {
                notification = notificationNetworkError;
            }
            dispatch({type: actions.NOTIFICATION, payload: notification});
            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {openDoor: false}});
        })
        .catch((error) => {
            console.warn('error', error);
            dispatch({type: actions.NOTIFICATION, payload: notificationNetworkError});
            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {openDoor: false}});
        });
};

export const closeDoorVehicleById = payload => async (dispatch) => {
    dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {closeDoor: true}});

    try {
        const state = await closeDoorVehicle(payload, api.refueling);

        let notification = createNotification('Машина закрыта!');
        const errorMsg = getCloseDoorErrors(state);
        if (errorMsg) {
            notification = createNotification(errorMsg, 'error');
        }

        dispatch({ type: actions.NOTIFICATION, payload: notification });
        dispatch({ type: actions.SET_CONTROL_BUTTONS, payload: { closeDoor: false } });
    } catch (e) {
        console.warn('error', e);
        dispatch({ type: actions.NOTIFICATION, payload: notificationNetworkError });
        dispatch({ type: actions.SET_CONTROL_BUTTONS, payload: { closeDoor: false } });
    }
};

export const startRefuelingVehicleById = payload => (dispatch) => {
    const params = {refuelingId: payload};
    dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {startRefueling: true}});

    api.refueling.startRefuelingVehicleById(params)
        .then((response) => {
            if (response) {
                dispatch({
                    type: actions.CHANGE_STATUS_REFUELING_BY_ID,
                    payload: {
                        refuelingId: payload,
                        status: 'STARTED',
                    }
                });
            } else {
                dispatch({type: actions.NOTIFICATION, payload: notificationNetworkError});
            }

            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {startRefueling: false}});
        })
        .catch((error) => {
            console.warn('error', error);
            dispatch({type: actions.NOTIFICATION, payload: notificationNetworkError});
            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {startRefueling: false}});
        });
};

export const finishRefuelingVehicleById = ({refuelingId, vehicleId}) => async (dispatch) => {
    dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {finishRefueling: true}});
    try {
        const state = await closeDoorVehicle(vehicleId, api.refueling);

        let notification = createNotification('Заправка завершена. Убедитесь, что машина закрыта!');
        const errorMsg = getCloseDoorErrors(state);
        if (errorMsg) {
            notification = createNotification(errorMsg, 'error');
        } else {
            const response = await api.refueling.finishRefuelingVehicleById({refuelingId});
            if (response) {
                dispatch({
                    type: actions.CHANGE_STATUS_REFUELING_BY_ID,
                    payload: {
                        refuelingId,
                        status: 'COMPLETED',
                    }
                });
            } else {
                notification = notificationNetworkError;
            }
        }

        dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {finishRefueling: false}});
        dispatch({type: actions.NOTIFICATION, payload: notification});
    } catch (e) {
        console.warn('error', e);
        if (e === 'ConditionFalse') {
            dispatch({ type: actions.NOTIFICATION, payload: notificationOpenDoorError });
        } else {
            dispatch({ type: actions.NOTIFICATION, payload: notificationNetworkError });
        }

        dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {finishRefueling: false}});
    }
};

export const turnOnEmergencyVehicleById = payload => (dispatch) => {
    dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {emergency: true}});

    api.refueling.turnOnEmergencyVehicleById(payload)
        .then((response = []) => {
            let notification = createNotification('Моргание аварийкой выполнено');
            // Проверяем, что один из сигналов был подан
            if (!response.some((res) => res)) {
                notification = notificationNetworkError;
            }

            dispatch({type: actions.NOTIFICATION, payload: notification});
            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {emergency: false}});
        })
        .catch((error) => {
            console.warn('error', error);
            dispatch({type: actions.NOTIFICATION, payload: notificationNetworkError});
            dispatch({type: actions.SET_CONTROL_BUTTONS, payload: {emergency: false}});
        });
};
