import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from './rootReducer';
import { localeSet } from './actions/locale';

addLocaleData(en);
addLocaleData(ru);

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

if (localStorage.dpLang) {
  store.dispatch(localeSet(localStorage.dpLang));
}

ReactDOM.render((
  <BrowserRouter>
    <Provider store={store}>
      {/* <Route path="/" component={App} /> */}
      <Route path="/" render={props => <App {...props} />} />
    </Provider>
  </BrowserRouter>
),
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
