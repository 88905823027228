export const actions = {
  GET_TECHS: 'GET_TECHS',
  GET_TECHS_SUCCESS: 'GET_TECHS_SUCCESS',
  GET_TECHS_FAIL: 'GET_TECHS_FAIL',
  EDIT_TECH: 'EDIT_TECH',
  EDIT_TECH_SUCCESS: 'EDIT_TECH_SUCCESS',
  EDIT_TECH_FAIL: 'EDIT_TECH_FAIL',
  // SET_DAMAGE: 'SET_DAMAGE',
  // REMOVE_DAMAGE: 'REMOVE_DAMAGE',
  // SET_DAMAGES: 'SET_DAMAGES',
};

// const test = [
//   {
//     last_id: 1,
//     fio: 'Иванов Иван Иванович',
//     email: 'sdgsd@asdasf.ru',
//     number: 79993334442211,
//   },
//   {
//     last_id: 2,
//     fio: 'Иванов Иван Иванович',
//     email: 'sdgsd@asdasf.ru',
//     number: 79993334442211,
//   },
//   {
//     last_id: 3,
//     fio: 'Иванов Иван Иванович',
//     email: 'sdgsd@asdasf.ru',
//     number: 79993334442211,
//   },
// ];

const initialState = {
  techs: [],
  listFetching: false,
  listError: null,
  techFetching: false,
  techError: null,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case actions.GET_TECHS:
      return {
        ...state,
        listFetching: true,
        techs: [],
      };
    case actions.GET_TECHS_SUCCESS:
      return {
        ...state,
        listFetching: false,
        techs: action.payload,
        listError: null,
      };
    case actions.GET_TECHS_FAIL:
      return {
        ...state,
        listFetching: false,
        techs: [],
        listError: action.payload,
      };
    case actions.EDIT_TECH:
      return {
        ...state,
        techFetching: true,
      };
    case actions.EDIT_TECH_SUCCESS:
      return {
        ...state,
        techFetching: false,
        techError: null,
      };
    case actions.EDIT_TECH_FAIL:
      return {
        ...state,
        techFetching: false,
        techError: action.payload,
        // service: { ...state.service, damages: action.payload },
      };
    default:
      return state;
  }
}
