import { combineReducers } from 'redux';
import auth from './reducers/auth';
// import locale from './reducers/locale';
import messages from './reducers/messages';
// import cars from './reducers/cars';
// import users from './reducers/users';
// import documents from './reducers/documents';
// import booking from './reducers/booking';
// import devices from './reducers/devices';
// import localization from './reducers/localization';
// import feedback from './reducers/feedback';
// import pagination from './reducers/pagination';
// import order from './reducers/order';
// import badges from './reducers/badges';
// import monitoring from './reducers/monitoring';
import profile from './reducers/profile';
// import techStatuses from './reducers/Technician/statuses';
import techCars from './reducers/Technician/cars';
import refueling from './reducers/Refueling/refueling';
import technicians from './reducers/Technician/technicians';
import techComponents from './reducers/Technician/components';

export default combineReducers({
  auth,
  // locale,
  messages,
  // cars,
  // users,
  // documents,
  // booking,
  // devices,
  // localization,
  // feedback,
  // pagination,
  // order,
  // badges,
  // monitoring,
  profile,
  // techStatuses,
  refueling,
  technicians,
  techCars,
  techComponents,
});
