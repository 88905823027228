import React from 'react';
import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import QuizButton from '../../../../components/Technician/QuizButton';
import {sendQuizData} from '../../../../actions/Technician/cars';
import quizStyles from './style';

const QuizCheckbox = injectIntl(withStyles(quizStyles)((props) => {
    const {classes, label, access, checked, handleChange, top} = props;
    console.log('QuizCheckbox', props);
    return (
        <div>
            <FormControlLabel className={classes.checkboxes}
                              control={(
                                  <Checkbox
                                      color="primary"
                                      checked={checked}
                                      // checked={this.state.usb_charge}
                                      onChange={handleChange(access)}
                                      icon={<CheckBoxOutlineBlankIcon className={classes.sizeIcon}/>}
                                      checkedIcon={<CheckBoxIcon className={classes.sizeIcon}/>}
                                  />
                              )}
                              label={props.intl.formatMessage({id: access.key})}
                              classes={{label: classes.checkboxes}}
            />
        </div>
    );
}));

class QuizAccessories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accessories: Array.isArray(props.accessories) && props.accessories.length > 0 && props.accessories.reduce((accumulator, { key, val }) => {
                return { ...accumulator, [key]: val };
            }, {}),
            additional_accessories: Array.isArray(props.additional_accessories) && props.additional_accessories.length > 0 && props.additional_accessories.reduce((accumulator, currentValue) => {
                accumulator[currentValue.key] = currentValue.val;
                return accumulator;
            }, {})
        };
    }

    handleChangeAccessories = access => (event) => {
        this.setState({...this.state, accessories: {...this.state.accessories, [access.key]: event.target.checked}});
    };

    handleChangeAdditionalAccessories = access => (event) => {
        this.setState({
            ...this.state,
            additional_accessories: {...this.state.additional_accessories, [access.key]: event.target.checked}
        });
    };

    render() {
        const {classes, service, accessories, additional_accessories} = this.props;

        return (
            <React.Fragment>
                <div className={classes.body}>
                    <div className={classes.title}>
                        Все обязательные аксессуары исправны и в наличии?
                    </div>
                    <div className={classes.title}>
                        Отметьте все присутствующие аксессуары
                    </div>
                    {Array.isArray(accessories) && accessories.length > 0 && accessories.map(access => (
                        <QuizCheckbox
                            key={access.key}
                            access={access}
                            checked={this.state.accessories[access.key]}
                            handleChange={this.handleChangeAccessories}
                        />
                    ))}
                    {Array.isArray(additional_accessories) && additional_accessories.length > 0 && additional_accessories.map(access => (
                        <QuizCheckbox
                            key={access.key}
                            access={access}
                            checked={this.state.additional_accessories[access.key]}
                            handleChange={this.handleChangeAdditionalAccessories}
                        />
                    ))}
                </div>

                <QuizButton
                    service={{
                        ...service,
                        accessories: Object.entries(this.state.accessories).map((val) => {
                            return {"key": val[0], "val": val[1]}
                        }),
                        additional_accessories: Object.entries(this.state.additional_accessories).map((val) => {
                            return {"key": val[0], "val": val[1]}
                        }),
                    }}
                    label="Подтвердить"
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        additional_accessories: state.techCars.service.additional_accessories,
        accessories: state.techCars.service.accessories,
        service: state.techCars.service,
        user: state.auth.auth.email,
    };
}

export default injectIntl(withStyles(quizStyles)(connect(mapStateToProps, {sendQuizData})(QuizAccessories)));
