// import blue from '@material-ui/core/colors/blue';
// import { green } from '@material-ui/core/colors';
import authBanner from './../../assets/images/background.jpg';

const authStyles = () => ({
  authMain: {
    display: 'flex',
  },
  authForm: {
    width: '525px',
    height: '100vh',
    backgroundColor: '#00aad2',
    backgroundImage: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      width: '100vw',
      // backgroundPosition: 'right',
    },
  },
  authBanner: {
    backgroundImage: 'url('+authBanner+')',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    height: '100vh',
    '@media (max-width: 1023px) and (orientation: portrait)': {
      backgroundSize: 'cover',
      backgroundPosition: 'right',
    },
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  authFormContainer: {
    // margin: '20px',
    display: 'flex',
    maxWidth: '525px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '420px',
    padding: '8px 16px 64px',
    '@media (max-height: 415px)': {
      height: 'calc(100vh - 20px)',
      padding: 0,
    },
    '@media (max-width: 768px)': {
      // width: 'calc(100vw - 20px)',
      // backgroundPosition: 'right',
    },
  },
  formCol: {
    // margin: '0 0 20px 0',
    width: '100%',
  },
  logo: {
    width: '100%',
  },
  title: {
    width: '100%',
    color: '#fff',
    fontFamily: 'HyundaiSansHead',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.33',
    letterSpacing: '-0.39px',
    '@media (max-width: 375px) and (orientation: portrait)': {
      fontSize: '16px',
    },
    '@media (max-width: 736px) and (orientation: landscape)': {
      textAlign: 'right',
    },
  },
  email: {

  },
  inputLabel: {
    fontFamily: 'HyundaiSansHead',
    fontSize: '17px',
    fontWeight: '600',
    letterSpacing: '1.2px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '30px',
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  error: {
    color: '#fff',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  errorIcon: {
    marginRight: '7px',
    backgroundColor: 'red',
    borderRadius: '15px',
  },
  password: {

  },
  forgot: {
    textAlign: 'right',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '40px',
    cursor: 'pointer',
  },
  forgotPaper: {
    padding: 16,
    color: '#00aad2',
    maxWidth: 280,
    lineHeight: '1.4em',
    textAlign: 'center',
  },
  colButton: {
    alignSelf: 'center',
    position: 'relative',
    '@media (max-width: 736px) and (orientation: landscape)': {
      alignSelf: 'flex-start',
      width: '50%',
      bottom: '20px',
    },
  },
  rootButton: {
    border: '1px solid #fff',
    boxShadow: 'none',
    borderRadius: '30px',
    backgroundColor: 'rgba(255,255,255, 0)',
  },
  labelButton: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '36px',
    letterSpacing: '-0.51px',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  focusButton: {
    backgroundColor: 'rgba(255,255,255, 0.2)',
  },
});

export default authStyles;
