import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import {createMuiTheme, withStyles} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import Slide from "@material-ui/core/Slide/Slide";
import PropTypes from "prop-types";


const theme = createMuiTheme({
    palette: {
        primary: blue,
    },
});


const styles = theme => ({
    root: {
        background: 'white',
        color: 'black',
        fontSize: '16px',
        // fontFamily: 'Cera Pro Bold',
        cursor: 'pointer',
    },
});

function TransitionLeft(props) {
    return <Slide {...props} direction="left"/>;
}


class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {open: true};
    }

    componentWillUpdate() {
        if (this.props.response !== this.state.open) {
            this.setState({open: this.props.response});
        }
    };

    handleClose = () => {
        this.setState({open: false});
    };

    render() {
        const {message, classes} = this.props;
        return (
            <Snackbar
                // onClick={this.goToDetails}
                // open={true}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={this.state.open}
                onClose={this.handleClose}
                TransitionComponent={TransitionLeft}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes.root
                    },
                }}
                message={<span id="message-id">{message}</span>}
            />
        )
    }
}


Notification.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Notification);