import { createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily: 'HyundaiSansText',
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00aad2',
      // dark: 'rgba(255, 255, 255, 0.3)',
      contrastText: '#ffffff',
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: '#f50057',
      // dark: 'rgba(255, 255, 255, 0.3)',
      contrastText: '#ffffff',
    },
  },
});


export default theme;