import PropTypes from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { Wrapper } from './styledComponents';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getStatusColor } from '../../logic';

const Statuses = props => {
    const {
        classes,
        ordersRefueling,
        statusesRefueling,
        getOrdersRefueling,
        selectedStatusRefueling,
        setSelectedStatusRefueling,
    } = props;

    const counters = useMemo(() => {
        return statusesRefueling.reduce((acc, status) => {
            if (status.value === 'ALL') {
                return acc;
            }

            return {
                ...acc,
                [status.value]: ordersRefueling.filter(order => order.status === status.value).length,
            }
        }, { ALL: ordersRefueling.length });
    }, [statusesRefueling, ordersRefueling]);

    const changeStatusRefueling = useCallback((value) => {
        setSelectedStatusRefueling(value);
        getOrdersRefueling();
    }, [getOrdersRefueling, setSelectedStatusRefueling]);

    return (
        <Wrapper>
            <Grid container spacing={2}>
                {statusesRefueling.map(status => {
                    const colorStatus = getStatusColor(status.value);
                    const backgroundColor = status.value === selectedStatusRefueling ? 'rgb(228, 220, 211)' : 'white';

                    return (
                        <Grid item
                              key={status.name}
                              onClick={() => changeStatusRefueling(status.value)}
                        >
                            <Button size="small"
                                    style={{ backgroundColor }}
                                    className={classes.status}
                            >
                                <Wrapper.indicator color={colorStatus}/>
                                <Wrapper.value>{status.name}</Wrapper.value>
                                <Wrapper.counter>{counters[status.value]}</Wrapper.counter>
                            </Button>
                        </Grid>
                    )
                })}
            </Grid>
        </Wrapper>
    );
};

Statuses.propTypes = {
    classes: PropTypes.object.isRequired,
    ordersRefueling: PropTypes.array.isRequired,
    statusesRefueling: PropTypes.array.isRequired,
    getOrdersRefueling: PropTypes.func.isRequired,
    selectedStatusRefueling: PropTypes.string.isRequired,
    setSelectedStatusRefueling: PropTypes.func.isRequired,
};

export default Statuses;
