import styled from 'styled-components';

export const Model = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
`;
Model.vehicle = styled.div`
  flex: 1 1 auto;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.39px;
  color: #39393f;
  font-family: HyundaiSansHead;
`;
Model.grz = styled.div`
  padding: 10px;
  flex: 0 1 auto;
  border-radius: 5px;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.2);
  font-family: HyundaiSansHead;
`;

export const Application = styled.div``;
Application.Model = Model;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
Wrapper.Application = Application;
