import React from 'react';
import { NavLink } from 'react-router-dom';
// import '../assets/css/auth.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import numeralize from 'numeralize-ru';
import { intlShape, injectIntl } from 'react-intl';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import accountIcon from '../assets/images/navigation/ic_account.svg';
import { getBookingList } from '../actions/booking';
import { getDocuments } from '../actions/documents';
import { getDevices } from '../actions/devices';
import { getSideBarBadges } from '../actions/badges';
import * as actions from '../actions/auth';
import logo from '../assets/images/logo.svg';


const theme = createMuiTheme({

  palette: {
    primary: blue,
  },
});


const styles = theme => ({
  root: {
    background: 'white',
    color: 'black',
    fontSize: '16px',
    // fontFamily: 'Cera Pro Bold',
    cursor: 'pointer',
  },
  badge: {
    // fontFamily: 'Cera Pro Regular',
    fontSize: '10px',
    width: '16px',
    height: '16px',
    top: '-5px',
    right: '-5px',
  },
  badgeHidden: {
    display: 'none',
  },
});

function TransitionRight(props) {
  return <Slide style={{ marginLeft: '76px', marginBottom: '70px' }} {...props} direction="right" />;
}

const initialState = {
  open: !(JSON.parse(localStorage.getItem('environment')) === 'development'),
};


class Navigation extends React.Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props, context) {
      super(props, context);
      this.state = initialState;
    }

    handleClick = () => {
      this.setState({ open: true });
    };

    handleClose = () => {
      this.setState({ open: false });
    };

    goToDetails = () => {
      this.setState({ open: false });
      this.context.router.history.push('/users/expect');
    };

    componentDidMount() {
      // this.props.getDocuments();
      // this.props.getDevices();
      // this.props.getBookingList();
      this.props.getSideBarBadges();
    }

    render() {
      const { intl, classes, badges } = this.props;
      const devicesBadge = badges ? badges.devices : false;
      const usersBadge = badges ? badges.users : false;
      const bookingRecent = badges ? badges.reservation : false;
      const feedbacksBadge = badges ? badges.feedback : false;
      const vehiclesBadge = badges ? badges.vehicles : false;

      return (
        <nav>
          <div className="logo">
            <NavLink actvieClassName="active" to="/dashboard"><img src={logo} alt="logo"/></NavLink>
          </div>
          <MuiThemeProvider theme={theme}>
            <div className="nav_menu">
              <ul>
                <li className="home">
                  <Badge classes={{ badge: classes.badgeHidden }} badgeContent={0} color="primary">
                    <NavLink actvieClassName="active" to="/dashboard">
                      <label>
                        {intl.formatMessage({
                          id: 'Dashboard',
                          defaultMessage: 'Dashboard',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="monitoring">
                  <Badge classes={{ badge: classes.badgeHidden }} badgeContent={0} color="primary">
                    <NavLink actvieClassName="active" to="/monitoring">
                      <label>
                        {intl.formatMessage({
                          id: 'Monitoring',
                          defaultMessage: 'Monitoring',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="vehicle">
                  <Badge
                    classes={{
                      badge: vehiclesBadge ? classes.badge : classes.badgeHidden,
                    }}
                    badgeContent={vehiclesBadge}
                    color="primary"
                  >
                    <NavLink actvieClassName="active" to="/vehicles/all">
                      <label>
                        {intl.formatMessage({
                          id: 'Fleet_management',
                          defaultMessage: 'Fleet management',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="users">
                  <Badge
                    classes={{
                      badge: usersBadge ? classes.badge : classes.badgeHidden,
                    }}
                    badgeContent={usersBadge}
                    color="primary"
                  >
                    <NavLink actvieClassName="active" to="/users/all">
                      <label>
                        {intl.formatMessage({
                          id: 'Users',
                          defaultMessage: 'Users',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="equipment">
                  <Badge
                    classes={{
                      badge: devicesBadge ? classes.badge : classes.badgeHidden,
                    }}
                    badgeContent={devicesBadge}
                    color="primary"
                  >
                    <NavLink actvieClassName="active" to="/devices/all">
                      <label>
                        {intl.formatMessage({
                          id: 'Equipment',
                          defaultMessage: 'Equipment',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="booking">
                  <Badge
                    classes={{
                      badge: bookingRecent ? classes.badge : classes.badgeHidden,
                    }}
                    badgeContent={bookingRecent}
                    color="primary"
                  >
                    <NavLink actvieClassName="active" to="/booking/list/all">
                      <label>
                        {intl.formatMessage({
                          id: 'Bookings',
                          defaultMessage: 'Bookings',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="settings">
                  <Badge classes={{ badge: classes.badgeHidden }} badgeContent={0} color="primary">
                    <NavLink actvieClassName="active" to="/localization">
                      <label>
                        {intl.formatMessage({
                          id: 'Localization',
                          defaultMessage: 'Localization',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="feedback">
                  <Badge
                    classes={{
                      badge: feedbacksBadge ? classes.badge : classes.badgeHidden,
                    }}
                    badgeContent={feedbacksBadge}
                    color="primary"
                  >
                    <NavLink actvieClassName="active" to="/feedback">
                      <label>
                        {intl.formatMessage({
                          id: 'Feedback',
                          defaultMessage: 'Feedback',
                        })}
                      </label>
                    </NavLink>
                  </Badge>
                </li>
                <li className="billing">
                  <Badge classes={{ badge: classes.badgeHidden }} badgeContent={0} color="primary">
                    <NavLink actvieClassName="active" to="/invoices/list/all">
                      <label>
                                            Счета и штрафы
                      </label>
                    </NavLink>
                  </Badge>
                </li>
              </ul>
              <ul className="nav_profile">
                {
                  usersBadge > 0
                    ? (
                      <li className="notification hasnotifi" onClick={this.handleClick}>
                        <span>1</span>
                      </li>
                    )
                    : <li className="notification" />
                }
                <li
                  id="profile"
                  className="avatar"
                  // onClick="javascript:void(0);"
                  tabIndex="1"
                >
                  <img src={accountIcon} alt="account" />
                </li>
                <div className="profile_detail" id="profile_menu">
                  <ul>
                    {/* <li><a href="">Обратная связь</a></li> */}
                    <li>
                      <NavLink to="/profile">
                        {intl.formatMessage({
                          id: 'Profile',
                          defaultMessage: 'Profile',
                        })}
                      </NavLink>
                    </li>
                    <li>
                      <a onClick={() => this.props.logout()}>
                        {intl.formatMessage({
                          id: 'Exit',
                          defaultMessage: 'Exit',
                        })}
                      </a>
                    </li>
                  </ul>
                </div>
                {
                  usersBadge > 0
                  && (
                    <Snackbar
                      onClick={this.goToDetails}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      open={this.state.open}
                      onClose={this.handleClose}
                      autoHideDuration={6000}
                      TransitionComponent={TransitionRight}
                      ContentProps={{
                        'aria-describedby': 'message-id',
                        classes: {
                          root: classes.root,
                        },
                      }}
                      message={(
                        <span
                          id="message-id">{usersBadge} {numeralize.pluralize(usersBadge, 'пользователь', 'пользователя', 'пользователей')} ожидает проверки документов</span>
                      )}
                    />
                  )
              }
              </ul>
            </div>
          </MuiThemeProvider>
        </nav>
      );
    }
}

Navigation.propTypes = {
  logout: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    documents: state.documents.documents,
    devices: state.devices.devices,
    bookingList: state.booking.bookingList,
    badges: state.badges.badges,
  };
}

export default injectIntl(withStyles(styles)(connect(mapStateToProps, {
  getDocuments,
  getDevices,
  getBookingList,
  getSideBarBadges,
  logout: actions.logout,
})(Navigation)));
