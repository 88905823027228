import OrderRefuelingButtons from './OrderRefuelingButtons';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { getRefuelingStyles } from '../../logic';
import * as selectors from '../../../../selectors/refueling';
import {
    openDoorVehicleById,
    closeDoorVehicleById,
    startRefuelingVehicleById,
    turnOnEmergencyVehicleById,
    finishRefuelingVehicleById,
} from '../../../../actions/Refueling/refueling';

export const mapStateToProps = state => ({
    getControlButtonsDisabling: selectors.getControlButtonsDisabling(state),
});

export default withStyles(getRefuelingStyles)(connect(
    mapStateToProps,
    {
        openDoorVehicleById,
        closeDoorVehicleById,
        startRefuelingVehicleById,
        finishRefuelingVehicleById,
        turnOnEmergencyVehicleById,
    }
)(OrderRefuelingButtons));
