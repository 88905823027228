import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import React from 'react';

const styles = () => ({
  topBar: {
    backgroundColor: '#f6f3f2',
    // height: '85px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    top: '92px',
    // position: 'fixed',
    position: 'relative',
    width: 'calc(100vw - 16px)',
    padding: 8,
    // zIndex: '1000',
    fontSize: 16,
    '@media (min-width: 376px) and (max-width: 600px)': {
      fontSize: 14,
    },
  },
  menuBar: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // height: '100%',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    fontSize: '1.1em',
    fontFamily: 'HyundaiSansHead',
    alignItems: 'center',
    // height: '50px',
    backgroundColor: 'white',
    borderRadius: '3em',
    boxShadow: '0 1px 4px 0 #e4dcd3',
    // margin: '0 0.5em',
  },
  rootMenu: {
    margin: '0.2em 0.6em',
    width: 'calc(100% - 1.2em)',
  },
  indicator: {
    width: '0.8em',
    height: '0.8em',
    margin: '0.9em 0 0.9em 0.9em',
    borderRadius: '2em',
  },
  indicatorMenu: {
    margin: '0.9em 0 0.9em 0.9em',
    borderRadius: '2em',
  },
  indicatorTop: {
    margin: '0.5em 0 0.5em 0.5em',
    borderRadius: '2em',
  },
  name: {
    // fontSize: '1.1em',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontFamily: 'HyundaiSansHead',
    // margin: '0.9em 1.2em 0.6em 1.1em',
    flex: '1 1 auto',
  },
  nameMenu: {
    margin: '0.9em 0.6em 0.6em',
  },
  nameTop: {
    margin: '0.75em 0.5em 0.5em 0.5em',
  },
  number: {
    // fontSize: '1.1em',
    fontWeight: '700',
    margin: '0.9em 0.6em 0.6em',
  },
  numberMenu: {
    margin: '0.9em 1.1em 0.6em 0',
  },
  numberTop: {
    margin: '0.75em 0.8em 0.5em 0',
  },
  divider: {
    flex: '0 1 1em',
  },
});

const Status = withStyles(styles)((props) => {
  const {
    classes, children, number, indicatorColor, backgroundColor, top,
  } = props;
  return (
    <Button
      className={clsx(classes.root, top ? null : classes.rootMenu)}
      style={{ backgroundColor, opacity: number ? 1 : 0.6 }}
      size="small"
    >
      {indicatorColor
            && (
            <div
              className={clsx(classes.indicator, top ? classes.indicatorTop : classes.indicatorMenu)}
              style={{ backgroundColor: indicatorColor }}
            />
            )}
      <div className={clsx(classes.name, top ? classes.nameTop : classes.nameMenu)}>
        {children}
      </div>
      <div className={clsx(classes.number, top ? classes.numberTop : classes.numberMenu)}>
        {number}
      </div>
    </Button>
  );
});

const Statuses = withStyles(styles)((props) => {
  const {
    classes, statuses, top, statusClick, selectedStatus,
  } = props;
  // console.log('statuses', statuses);
  const checkupStatusesCounter = [
      statuses.AWAIT_FOR_CHECKUP,
      statuses.CHECKUP_ACTIVE,
      statuses.CHECKUP_PAUSED,
      statuses.CHECKUP_COMPLETED,
      statuses.CHECKUP_OVERDUE,
      statuses.CHECKUP_CANCELLED,
      statuses.WAIT_PREPARE,
      statuses.PAID_PRE_ORDER
  ]
    .reduce((acc, status) => {
      if (status) {
        acc += status;
      }
      return acc;
    }, 0);


  return (
    <div className={clsx(top ? classes.topBar : classes.menuBar)}>
      <Grid container spacing={1}>
        <Grid item onClick={() => statusClick('ALL')}>
          <Status
            number={statuses.ALL}
            backgroundColor={selectedStatus === 'ALL' ? '#e4dcd3' : null}
            indicatorColor="#CCB6A0"
            top={top}
          >
            ВСЕ
          </Status>
        </Grid>
        <Grid item onClick={() => statusClick('CHECKUP')}>
          <Status
            number={checkupStatusesCounter}
            backgroundColor={selectedStatus === 'CHECKUP' ? '#e4dcd3' : null}
            indicatorColor="#37a6e5"
            top={top}
          >
            ОСМОТР
          </Status>
        </Grid>
        <Grid item onClick={() => statusClick('PROBLEM')}>
          <Status
            number={statuses.PROBLEM}
            backgroundColor={selectedStatus === 'PROBLEM' ? '#e4dcd3' : null}
            indicatorColor="#FF0000"
            top={top}
          >
            Проблема
          </Status>
        </Grid>
        <Grid item onClick={() => statusClick('NEW')}>
          <Status
            number={statuses.NEW}
            backgroundColor={selectedStatus === 'NEW' ? '#e4dcd3' : null}
            indicatorColor="#94e664"
            top={top}
          >
            Новые
          </Status>
        </Grid>
        <Grid item onClick={() => statusClick('DELIVERY_NEW')}>
          <Status
            number={statuses.DELIVERY_NEW}
            backgroundColor={selectedStatus === 'DELIVERY_NEW' ? '#e4dcd3' : null}
            indicatorColor="#94e664"
            top={top}
          >
            Новый (Доставка)
          </Status>
        </Grid>
        <Grid item onClick={() => statusClick('REPAIR_PAUSED')}>
          <Status
            number={statuses.REPAIR_PAUSED}
            backgroundColor={selectedStatus === 'REPAIR_PAUSED' ? '#e4dcd3' : null}
            indicatorColor="#ff0000"
            top={top}
          >
            Ремонт
          </Status>
        </Grid>
        {/* <Grid item xs={top ? 6 : 12} sm={top ? 3 : 12}>
                    <Status
                        number={statuses.repair}
                        indicatorColor="#f5915c"
                        top={top}
                    >
                        РЕМОНТ
                    </Status>
                </Grid> */}
      </Grid>
    </div>
  );
});

export default (Statuses);
