export const actions = {
    GET_BADGES: 'GET_BADGES',
    GET_BADGES_SUCCESS: 'GET_BADGES_SUCCESS',
    GET_BADGES_FAIL: 'GET_BADGES_FAIL',
};

const initialState = {
    badges: null,
    badgesFetching: false,
    badgesError: null,
};

export default function badges(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_BADGES:
            return {
                ...state,
                badgesFetching: true
            };
        case actions.GET_BADGES_SUCCESS:
            return {
                ...state,
                badgesFetching: false,
                badges: action.payload,
                badgesError: null
            };
        case actions.GET_BADGES_FAIL:
            return {
                ...state,
                badgesFetching: false,
                badges: null,
                badgesError: action.payload
            };
        default:
            return state;
    }
}