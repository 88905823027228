import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { addDamage } from '../../../../actions/Technician/cars';
import ViewEditDamage from '../../../../components/Technician/ViewEditDamage';
import ModalGallery from '../../../../components/Technician/ModalGalleryContainer';
import quizStyles from './style';

const damageClass = 'inspection_report';

class QuizInspectionReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { classes, car } = this.props;

    const naming = {
      addButtonText: 'Добавить',
      damageTitleText: 'Новая диагностическая карта',
      damageInputLabelText: 'Комментарий',
      damageInputHelperText: ' ' // Пробел
    }

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title} style={{ textAlign: 'center' }}>
            Диагностическая карта
          </div>

          <div className={classes.title}>
            Загрузите диагностическую карту.
          </div>
          <ViewEditDamage
            carVin={car.vin}
            damageClass={damageClass}
            naming={naming}
            damageDetailOptional
            disableIfEmpty
          />
          <ModalGallery
            title="Диагностическая карта"
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    damageNew: state.techCars.damages,
    service: state.techCars.service,
    user: state.auth.auth.email,
  };
}

export default withStyles(quizStyles)(connect(mapStateToProps, { addDamage })(QuizInspectionReport));
