export const getDashboardStyles = () => ({
    dashboard: {
        // top: '180px',
        top: '92px',
        width: '100%',
        position: 'relative',
        margin: '0',
        padding: '0 8px',
    },
    secondBar: {
        backgroundColor: '#f6f3f2',
        height: '85px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        top: '92px',
        position: 'relative',
        zIndex: '1000',
    },
    paper: {
        width: '100%',
        padding: '15px',
        // border: '1px solid rgba(0,0,0,0.3)',
    },
    paperHead: {
        display: 'flex',
        alignItems: 'center',
    },
    paperStatus: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0 0px',
    },
    paperLine: {
        borderTop: '1px dashed #ccc',
    },
    carColor: {
        width: '32px',
        height: '32px',
        borderRadius: '7px',
        flex: '0 1 auto',
        boxShadow: '0px 0px 3px 0px rgba(0,0,0,0.2)',
    },
    carModel: {
        paddingLeft: '10px',
        flex: '1 1 auto',
        fontSize: '18px',
        fontWeight: '600',
        letterSpacing: '-0.39px',
        fontFamily: 'HyundaiSansHead',
        color: '#39393f',
    },
    carLicense: {
        padding: '10px',
        flex: '0 1 auto',
        borderRadius: '5px',
        fontFamily: 'HyundaiSansHead',
        fontWeight: '600',
        whiteSpace: 'nowrap',
        boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.2)',
    },
    paperStatusCol: {
        padding: '0px',
    },
    paperStatusTitle: {
        padding: '4px',
        fontSize: '12px',
        letterSpacing: '-0.26px',
        color: '#99999c',
        textAlign: 'center',
    },
    paperStatusState: {
        padding: '10px',
        width: '100%',
        minWidth: '100px',
    },
    paperStatusLed: {
        width: '13px',
        height: '13px',
        float: 'left',
        borderRadius: '13px',
        // marginRight: '10px',
    },
    paperStatusText: {
        paddingLeft: '20px',
    },
    paperStatusTime: {
        padding: '10px',
        fontSize: '20px',
        fontWeight: '700',
        letterSpacing: '-0.43px',
        color: '#696970',
        textAlign: 'center',
    },
    Button: {
        width: '100%',
        '&:hover': {
            boxShadow: '0px 0px 5px 3px rgba(0,177,210,0.5)',
            borderRadius: '8px',
        },
    },
    loading: {
        top: '177px',
        width: '100%',
        height: 'calc(100% - 177px)',
        position: 'absolute',
        zIndex: 1,
        backgroundColor: 'rgba(255,255,255,0.7)',
    },
    emptyQueue: {
        textAlign: 'center',
        fontFamily: 'HyundaiSansHead',
        fontSize: '32px',
        fontWeight: '700',
        color: '#ccc',
        marginTop: '2em',
    },
});

export const statusChoiceArr = {
    AWAIT_FOR_CHECKUP: {
        color: '#37a6e5',
        message: 'Ожидает осмотр',
    },
    WAIT_PREPARE: {
        color: '#37a6e5',
        message: 'Ожидает осмотр',
    },
    PAID_PRE_ORDER: {
        color: '#37a6e5',
        message: 'Предзаказ оплачен',
    },
    CHECKUP_ACTIVE: {
        color: '#37a6e5',
        message: 'Осмотр',
    },
    CHECKUP_PAUSED: {
        color: '#37a6e5',
        message: 'Осмотр',
    },
    CHECKUP_COMPLETED: {
        color: '#37a6e5',
        message: 'Осмотр',
    },
    CHECKUP_OVERDUE: {
        color: '#37a6e5',
        message: 'Осмотр',
    },
    CHECKUP_CANCELLED: {
        color: '#37a6e5',
        message: 'Осмотр',
    },
    DELIVERY_NEW: {
        color: '#94e664',
        message: 'Новый (Доставка)',
    },
    REPAIR_PAUSED: {
        color: '#ff0000',
        message: 'Ремонт',
    },
    DELIVERY_CHECK: {
        color: '#37a6e5',
        message: 'Проверка (Доставка)',
    },
    DELIVERY_PROBLEM: {
        color: 'FF0000',
        message: 'Проблема (Доставка)',
    },
    repair: {
        color: '#f5915c',
        message: 'Ремонт',
    },
    NEW: {
        color: '#94e664',
        message: 'Новый',
    },
    PROBLEM: {
        color: '#FF0000',
        message: 'Проблема',
    },
    unknownStatus: {
        color: '#cdcecc',
    },
};

export const checkCarByStatus = (carStatus, selectedStatus) => {
    switch (carStatus) {
        case 'CHECKUP_ACTIVE':
        case 'CHECKUP_PAUSED':
        case 'CHECKUP_OVERDUE':
        case 'AWAIT_FOR_CHECKUP':
        case 'CHECKUP_COMPLETED':
        case 'CHECKUP_CANCELLED':
        case 'PAID_PRE_ORDER':
        case 'WAIT_PREPARE':
            if (selectedStatus === 'CHECKUP') {
                return true;
            }
            break;
        case 'DELIVERY_NEW':
            if (selectedStatus === 'DELIVERY_NEW') {
                return true;
            }
            break;
        case 'REPAIR_PAUSED':
            if (selectedStatus === 'REPAIR_PAUSED') {
                return true;
            }
            break;
        case 'PROBLEM':
            if (selectedStatus === 'PROBLEM') {
                return true;
            }
            break;
        case 'DELIVERY_PROBLEM':
            if (selectedStatus === 'PROBLEM') {
                return true;
            }
            break;
        case 'NEW':
            if (selectedStatus === 'NEW') {
                return true;
            }
            break;
        default:
            break;
    }
};

export const voltageStatusColor = (voltage) => {
    if (Math.abs(voltage) < 120 || Math.abs(voltage) === 0) {
        return {
            color: '#FF0000',
            message: '- Низкий заряд!',
        };
    }

    return {
        color: '#000000',
        message: '',
    };
};
