import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 92px;
`;
Wrapper.status = styled.div`
  width: 100%;
`;
Wrapper.orders = styled.div`
  width: 100%;
  min-height: 200px;
  position: relative;
  margin: 0;
  padding: 0 8px;
  box-sizing: border-box;
`;
Wrapper.loading = styled.div`
  display: ${props => props.ordersFetching ? 'block' : 'none'};
  top: 82px;
  width: 100%;
  height: calc(100% - 82px);
  position: absolute;
  z-index: 1;
  background-color: rgba(255,255,255,0.7);
`;
