import React from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import '../../../assets/css/style.css';
import { addDamage } from '../../../../actions/Technician/cars';
import ViewEditDamage from '../../../../components/Technician/ViewEditDamage';
import ModalGallery from '../../../../components/Technician/ModalGalleryContainer';
import quizStyles from './style';
// import GearLoading from '../../../components/Technician/gearLoading';

const damageClass = 'ext_left';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class QuizExtLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // newDamageDialog: false,
    };
  }

  componentDidMount() {
    // this.props.addDamage(this.props.damageNew, damageClass, false);
  }
  // handleChange = name => (event) => {
  //   console.log('name', name, 'event', event.target.checked);
  //   this.setState({ ...this.state, [name]: event.target.checked });
  // };

  // newDamage = () => {
  //   this.setState({ newDamageDialog: true });
  // }

  // handleClose = () => {
  //   this.setState({ damageDialog: false, newDamageDialog: false });
  // }

  // handleDialog = (key) => {
  //   this.setState({ damageDialog: key });
  // }

  render() {
    const { classes, user, car, service } = this.props;
    console.log('QuizExtLeft', this.props);
    // const classes = useStyles();

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title} style={{ textAlign: 'center' }}>
            Повреждения левой стороны
          </div>
          {/* <Grid container spacing={2} style={{ margin: '8px -8px' }}>
            {car.damages.map((damage, key) => (
              <Grid item xs={6} key={key}>
                <ButtonBase
                  className={classes.paperButton}
                  onClick={() => this.handleDialog(key)}
                  focusRipple
                >
                  <Paper
                    elevation={2}
                    style={{ display: 'flex', backgroundColor: 'rgba(200, 200, 200 ,0.1)' }}
                  >
                    <Avatar src={damage.photo_url} style={{ border: '2px solid #ccc', width: '60px', height: '60px', borderRadius: '20%', margin: '8px' }} />
                    <div style={{ padding: '0 8px' }}>
                      <div style={{ color: 'rgb(0, 170, 210)', padding: '8px 0', fontSize: '18px', fontWeight: '700', lineHeight: '1.08' }}>{damage.damaged_part}</div>
                      <div style={{ fontSize: '14px', lineHeight: '1.08' }}>{damage.description}</div>
                    </div>
                  </Paper>
                </ButtonBase>
                <Dialog
                  fullWidth
                  maxWidth="md"
                  open={this.state.damageDialog===key}
                  onClose={this.handleClose}
                >
                  <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                    {damage.damaged_part}
                  </DialogTitle>
                    {damage.description} 
                  <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={this.handleClose} color="primary">
                      Subscribe
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            ))}
          </Grid> */}


          <div className={classes.title}>
            Есть ли новые повреждения левого борта кузова и дисков автомобиля?
          </div>
          <div className={classes.text}>
            Осмотрите левый борт кузова автомобиля и диски на наличие повреждений.
            <br />
            Если вы увидели новое повреждение, выберите поврежденную деталь, дайте описание и сделайте фотографию повреждения.
          </div>
          <ViewEditDamage
            carVin={car.vin}
            damageClass={damageClass}
          />
          <ModalGallery
            title="Повреждения"
          />
        </div>

        {/* <Button
          color="primary"
          variant="contained"
          classes={{
            root: clsx(classes.rootButton, classes.primaryButton),
            label: classes.labelButton,
            focusVisible: classes.focusButton,
          }}
          onClick={() => this.props.sendQuizData({ smell: false })}
        >
          Внешние повреждения
        </Button> */}
        {/* <QuizButton
          service={service}
          label="Далее"
        /> */}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    damageNew: state.techCars.damages,
    service: state.techCars.service,
    user: state.auth.auth.email,
  };
}

export default withStyles(quizStyles)(connect(mapStateToProps, { addDamage })(QuizExtLeft));
