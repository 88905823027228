import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import api from '../../../../api';
import { sendQuizData } from '../../../../actions/Technician/cars';
import quizStyles from './style';

class QuizSmell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDamageFetch: false,
    };
    this.fileInput = React.createRef();
    this.damages = [];
  }

  saveDamage = () => {
    const params = {
      params: {
        class: 'smell',
        vehicle_id: this.props.service.vehicle_id,
        service_id: this.props.service.last_id,
      }, id: 1
    };
    this.setState({ saveDamageFetch: true });
    console.log(params);
    api.tech.setDamage(params)
      .then((res) => {
        console.log(res);
        if (res.result.success) {
          this.props.sendQuizData(this.props.service, this.props.quiz)
        } else {
          throw res;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { classes, user, damageNew, serviceFetching, service, quiz } = this.props;
    console.log('QuizSmell', this.props);

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title}>
            Есть ли следы курения в салоне?
          </div>
          <div className={classes.text}>
            Проверте есть ли запах табака в салоне или сигаретный пепел на элементах салона.
          </div>
        </div>
        
        <div>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              classes={{
                root: classes.rootButton,
                label: classes.labelButton,
              }}
              onClick={() => this.props.sendQuizData(service, quiz)}
            >
              Следов курения нет
            </Button>
            {/* {saveDamageFetch && <CircularProgress size={30} color="primary" />} */}
          </div>
          <div className={clsx(classes.buttonContainer, classes.secondaryButton)}>
            <Button
              color="secondary"
              variant="contained"
              classes={{
                root: classes.rootButton,
                label: classes.labelButton,
              }}
              onClick={this.saveDamage}
            >
              Есть следы курения
            </Button>
            {this.state.saveDamageFetch && <CircularProgress className={classes.secondaryButton} size={30} color="secondary" />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    service: state.techCars.service,
    damageNew: state.techCars.damages,
    // serviceFetching: state.techCars.serviceFetching,
    quiz: state.techCars.quiz,
    user: state.auth.auth.email,
  };
}

export default withStyles(quizStyles)(connect(mapStateToProps, { sendQuizData })(QuizSmell));
