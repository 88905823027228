import PropTypes from 'prop-types';
import React, {useCallback} from 'react';
import {PaginationBlock, RightBlock, SearchBlock, SortBlock, Wrapper} from './styledComponents';
import {Pagination} from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import useSearch from "../../hooks/useSearch";
import Sort from "../Sort";
import {useDebouncedCallback} from "use-debounce";

const NavigationRefueling = props => {
    const {
        classes,
        setSearchTermRefueling,
        setPaginationPageRefueling,
        searchTermRefueling = '',
        sortRefueling,
        paginationPageRefueling,
        paginationCountRefueling,
        setSortRefueling
    } = props;

    const [searchValue, onSearch, onSearchClear] = useSearch(searchTermRefueling, setSearchTermRefueling);

    const setPagination = useCallback(
        (_, page) => setPaginationPageRefueling(page),
        [setPaginationPageRefueling]
    );

    const setSort = useDebouncedCallback((order, item) => {
        setSortRefueling({...item, order})
    }, 400);

    return (
        <>
            <Wrapper>
                <SearchBlock>
                    <form className={classes.widthFull} noValidate autoComplete="off">
                        <FormControl className={classes.widthFull}>
                            <TextField
                                onChange={onSearch}
                                value={searchValue}
                                variant="outlined"
                                placeholder={'Поиск по по рег. номеру, номеру заказа, адресу'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={onSearchClear} edge="end">
                                                <CloseIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </FormControl>
                    </form>
                </SearchBlock>
                <RightBlock>
                    <SortBlock>
                        Сортировка:
                        {sortRefueling.map((item) => (
                                <Sort key={item.name} sort={item.value} setSort={(sortValue) => setSort(sortValue, item)} />
                        ))}
                    </SortBlock>
                    <PaginationBlock>
                    {paginationCountRefueling > 1 && <Pagination page={paginationPageRefueling}
                                                                 onChange={setPagination}
                                                                 count={paginationCountRefueling}/>}
                    </PaginationBlock>
                </RightBlock>
            </Wrapper>
        </>
    );
};

NavigationRefueling.propTypes = {
    classes: PropTypes.object.isRequired,
    setSearchTermRefueling: PropTypes.func.isRequired,
    setPaginationPageRefueling: PropTypes.func.isRequired,
    searchTermRefueling: PropTypes.string.isRequired,
    sortRefueling: PropTypes.array.isRequired,
    paginationPageRefueling: PropTypes.number.isRequired,
    paginationCountRefueling: PropTypes.number.isRequired,
    setSortRefueling: PropTypes.func.isRequired,
};

export default NavigationRefueling;
