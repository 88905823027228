import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, formatMessage } from 'react-intl';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import '../../../assets/css/style.css';
import { addDamage } from '../../../../actions/Technician/cars';
import ViewEditDamage from '../../../../components/Technician/ViewEditDamage';
import ViewDamage from '../../../../components/Technician/ViewDamage';
import ModalGallery from '../../../../components/Technician/ModalGalleryContainer';
import quizStyles from './style';
// import GearLoading from '../../../components/Technician/gearLoading';

const damageClass = 'internal';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogTitle = withStyles(useStyles)((props) => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class QuizInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // newDamageDialog: false,
    };
  }

  componentDidMount() {
    // this.props.addDamage(this.props.damageNew, damageClass, false);
  }
  // handleChange = name => (event) => {
  //   console.log('name', name, 'event', event.target.checked);
  //   this.setState({ ...this.state, [name]: event.target.checked });
  // };

  // newDamage = () => {
  //   this.setState({ newDamageDialog: true });
  // }

  // handleClose = () => {
  //   this.setState({ damageDialog: false, newDamageDialog: false });
  // }

  // handleDialog = (key) => {
  //   this.setState({ damageDialog: key });
  // }

  render() {
    const { classes, damageOld, car } = this.props;
    console.log('QuizInternal', this);
    // const classes = useStyles();

    return (
      <React.Fragment>
        <div className={classes.body}>
          <div className={classes.title} style={{ textAlign: 'center' }}>
            Повреждения салона
          </div>
          <ViewDamage
            damageClass={damageClass}
          />

          <div className={classes.title}>
            Есть ли новые повреждения салона?
          </div>
          <div className={classes.text}>
            Осмотрите салон на наличие загрязнений и повреждений.
            Если вы увидели новое повреждение или загрязнение укажите в каком месте.
          </div>
          <ViewEditDamage
            carVin={car.vin}
            damageClass={damageClass}
          />
          <ModalGallery
            title="Повреждения"
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    // damageNew: state.techCars.damages,
    damageOld: state.techCars.damages_old,
    service: state.techCars.service,
    user: state.auth.auth.email,
  };
}

export default injectIntl(withStyles(quizStyles)(connect(mapStateToProps, { addDamage })(QuizInternal)));
