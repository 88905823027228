import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';
import React, { useMemo, useEffect } from 'react';
import Statuses from '../../../components/Technician/Statuses';
import GearLoading from '../../../components/Technician/gearLoading';
import { statusChoiceArr, checkCarByStatus, voltageStatusColor } from './logic';

const Dashboard = props => {
  const {
    cars,
    classes,
    getCars,
    statuses,
    carsFetching,
    selectedStatus,
    setSelectedStatus,
  } = props;

  useEffect(() => {
    getCars();
  }, []);

  const filteredCars = useMemo(() => {
    if (!Array.isArray(cars) || !cars.length) {
      return [];
    }

    return selectedStatus === 'ALL' ? cars : cars.filter((car) => checkCarByStatus(car.status, selectedStatus));
  }, [cars, selectedStatus]);

  const LinkRef = React.forwardRef((props, ref) => <div ref={ref}><Link {...props} /></div>);

  return (
    <React.Fragment>
      <Statuses
        top
        statuses={statuses}
        statusClick={setSelectedStatus}
        selectedStatus={selectedStatus}
      />
      <div className={classes.loading} style={{ display: carsFetching ? 'block' : 'none' }}>
        <GearLoading />
      </div>
      <Grid container spacing={2} className={classes.dashboard}>
        {filteredCars.length ? filteredCars.map(car => (
          <Grid item xs={12} sm={6} md={4} xl={3} key={car.last_id}>
            <ButtonBase
              className={classes.Button}
              component={LinkRef}
              to={`/quiz/prepare/${car.last_id}`}
              focusRipple
            >
              <Paper
                elevation={3}
                className={classes.paper}
              >
                <div className={classes.paperHead}>
                  <div className={classes.carColor} style={{ backgroundColor: car.color }} />
                  <div className={classes.carModel}>
                    {car.model}
                  </div>
                  <div className={classes.carLicense}>
                    {car.license}
                  </div>
                </div>
                <hr className={classes.paperLine} />
                <div className={classes.paperStatus}>
                  <div className={classes.paperStatusCol}>
                    <div className={classes.paperStatusTitle}>
                      СТАТУС
                    </div>
                    <div className={classes.paperStatusState}>
                      <div
                        className={classes.paperStatusLed}
                        style={{ backgroundColor: car.status && statusChoiceArr[car.status] && statusChoiceArr[car.status].color || statusChoiceArr.unknownStatus.color }}
                      />
                      <div className={classes.paperStatusText}>
                        {car.status && statusChoiceArr[car.status] && statusChoiceArr[car.status].message || car.status}
                      </div>
                    </div>
                  </div>

                  {car.device && car.device.voltage_onboart
                    && (
                      <div className={classes.paperStatusCol}>
                        <div className={classes.paperStatusTitle}>
                          АКБ
                        </div>
                        <div className={classes.paperStatusState}>
                          <div
                            className={classes.paperStatusText}
                            style={{ color: voltageStatusColor(car.device.voltage_onboart).color }}
                          >
                            {Number.isInteger(car.device.voltage_onboart) && (Math.abs(car.device.voltage_onboart) / 10)}
                            {' '}
                            В
                            {' '}
                            {voltageStatusColor(car.device.voltage_onboart).message}
                          </div>
                        </div>
                      </div>
                    ) || null}

                  {car.changeStatusEvent
                  && (
                    <div className={classes.paperStatus}>
                      <div className={classes.paperStatusCol}>
                        <div className={classes.paperStatusTitle}>
                          Комментарий
                        </div>
                        <div className={classes.paperStatusText}>
                          {car.changeStatusEvent}
                        </div>
                      </div>
                    </div>
                  ) || null}
                </div>

                {car.session && car.session.date_start
                  && (new Date(car.session.date_start) > new Date())
                  && (
                    <div className={classes.paperStatus}>
                      <div className={classes.paperStatusCol}>
                        <div className={classes.paperStatusTitle}>
                          ДАТА И ВРЕМЯ ВЫДАЧИ
                        </div>
                        <div className={classes.paperStatusTime}>
                          {moment(car.session.date_start).utcOffset(180).format('lll')}
                        </div>
                      </div>

                      {car.statuses
                        && (
                          <div className={classes.paperStatusCol}>
                            <div className={classes.paperStatusTitle}>
                              ДОСТАВКА
                            </div>
                            <div className={classes.paperStatusState}>
                              <div
                                className={classes.paperStatusText}
                                style={{ color: car.statuses.haveDelivery ? '#FF0000' : '#94e664' }}
                              >
                                {car.statuses.haveDelivery ? 'Да' : 'Нет'}
                              </div>
                            </div>
                          </div>
                        ) || null}
                    </div>
                  ) || null}

              </Paper>
            </ButtonBase>
          </Grid>
          ))
          : (
            <Grid item xs={12} className={classes.emptyQueue}>
              Автомобилей в очереди нет
            </Grid>
          )
        }
      </Grid>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  cars: PropTypes.array.isRequired,
  getCars: PropTypes.func.isRequired,
  statuses: PropTypes.object.isRequired,
  carsError: PropTypes.oneOfType([
    PropTypes.oneOf([null, PropTypes.string])
  ]),
  carsFetching: PropTypes.bool.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  setSelectedStatus: PropTypes.func.isRequired,
};

export default Dashboard;
