import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ButtonBase from '@material-ui/core/ButtonBase';
// import { ThemeProvider, withTheme } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
// import CloseIcon from '@material-ui/icons/Close';
import TechniciansCRUD from '../../components/Technician/TechniciansCRUD';
import editIcon from '../../assets/images/icon_edit.svg';
import removeIcon from '../../assets/images/icon_remove.svg';
// import useMediaQuery from '@material-ui/core/useMediaQuery';
// import { useTheme } from '@material-ui/core/styles';
import { getTechnicians } from '../../actions/Technician/technicians';
import GearLoading from '../../components/Technician/gearLoading';

const styles = theme => ({
  technicians: {
    top: '92px',
    width: '100%',
    position: 'relative',
    margin: '0 auto',
    // padding: '0 8px',
    textAlign: 'center',
    maxWidth: 1024,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 92,
  },
  appBar: {
    // width: '100vw',
    width: '100%',
    backgroundColor: 'white',
    // height: '177px',
    position: 'fixed',
    textAlign: 'center',
    // zIndex: '5',
    boxShadow: 'none',
    // boxShadow: '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
    // '-webkit-box-shadow': '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
    // '-moz-box-shadow': '2px 0 20px 0 rgba(155, 155, 155, 0.3)',
  },
  onFocus: {
    backgroundColor: 'rgba(0,170,210,0.1)',
  },
  menuButtonRoot: {
    color: '#00aad2',
    padding: '4px',
  },
  title: {
    fontFamily: 'HyundaiSansHead',
    color: '#323232',
    fontWeight: 600,
  },
  buttonContainer: {
    // position: 'absolute',
    // bottom: 4,
    // left: '25%',
    // width: '50vw',
    // display: 'flex',
    // alignItems: 'center',
    paddingTop: '10px'
  },
  rootButton: {
    // width: '90vw',
    width: '90%',
    margin: '10px auto',
    padding: '10px 16px 6px;',
    fontFamily: 'HyundaiSansHead',
    fontWeight: '600',
    letterSpacing: '0.4px',
    textTransform: 'initial',
  },
  labelButton: {
    fontWeight: '600',
  },
  buttonIcon: {
    paddingRight: 16,
  },
  listContainer: {
    // width: '90vw',
  },
  card: {
    // width: '90vw',
    width: '90%',
    margin: '16px auto',
    color: '#323232',
    borderRadius: '8px',
    boxShadow: '0 0 6px 0 rgba(45, 42, 108, 0.25)',
    // padding: 6,
    textAlign: 'start',
    display: 'flex',
    // justifyContent: 'space-around',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: 160,
  },
  data: {
    padding: 6,
  },
  name: {
    margin: 12,
    fontSize: 18,
  },
  email: {
    margin: 12,
    color: '#909090',
  },
  number: {
    margin: 12,
    fontSize: 18,
  },
  buttons: {
    display: 'flex',
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  button: {
    display: 'block',
    margin: 6,
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  loading: {
    top: '177px',
    width: '100%',
    height: 'calc(100% - 177px)',
    position: 'absolute',
    zIndex: 1,
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  hide: {
    display: 'none',
  },
});

class TechnicianList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      technicianId: null,
      remove: false,
      // technicians: [],
    };
  }

  componentDidMount() {
    // get data
    this.props.getTechnicians();
  }

  modalCloseHandle = () => {
    this.setState({ modalOpen: false, technicianId: null, remove: false });
  }

  render() {
    const { classes, technicians, listFetching } = this.props;
    console.log('TechnicianList', this);
    return (
      <div className={classes.technicians}>
        <div className={classes.loading} style={{ display: listFetching ? 'block' : 'none' }}>
          <GearLoading />
        </div>
        <div className={classes.buttonContainer}>
          { false && <Button
            color="primary"
            variant="contained"
            // disabled={openCarsFetching}
            classes={{
              root: classes.rootButton,
              label: classes.labelButton,
            }}
            onClick={() => this.setState({ modalOpen: true, technicianId: null, remove: false })}
          >
            <PersonAddIcon className={classes.buttonIcon} />
            Добавить техника
          </Button>}
        </div>
        <div className={classes.listContainer}>
          {!!technicians && technicians.map(tech => (
            <ButtonBase
              className={clsx(classes.card, tech.last_id === this.state.idOnFocus && classes.onFocus)}
              key={tech.last_id}
              onClick={() => this.setState({ idOnFocus: tech.last_id })}
              focusRipple
            >
            {/* <div className={classes.card} key={tech.last_id}> */}
              <div className={classes.data}>
                <div className={classes.name}>
                  {tech.fio ? tech.fio : '-'}
                </div>
                <div className={classes.email}>
                  {tech.email}
                </div>
                <div className={classes.number}>
                  {tech.number}
                </div>
              </div>
              <div className={clsx(classes.buttons, tech.last_id !== this.state.idOnFocus && classes.hide)}>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ modalOpen: true, technicianId: tech.last_id, remove: false })}
                  >
                  <div><img src={editIcon} height="25" width="25" alt="" /></div>
                  <div style={{ fontSize: '0.8em' }}>Редактировать</div>
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.setState({ modalOpen: true, technicianId: tech.last_id, remove: true })}
                >
                  <div><img src={removeIcon} height="25" width="25" alt="" /></div>
                  <div style={{ fontSize: '0.8em' }}>Удалить</div>
                </Button>
              </div>
            {/* </div> */}
            </ButtonBase>
            ))
            }
          <TechniciansCRUD
            technicianId={this.state.technicianId}
            technicians={technicians}
            remove={this.state.remove}
            // remove
            open={this.state.modalOpen}
            closeHandle={this.modalCloseHandle}
          />
        </div>
      </div>
      // </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    listFetching: state.technicians.listFetching,
    technicians: state.technicians.techs,
  };
}

export default withStyles(styles)(connect(mapStateToProps, {
  // getDocuments,
  getTechnicians,
})(TechnicianList));
