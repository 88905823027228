export const actions = {
    GET_CARS: 'GET_CARS',
    GET_CARS_SUCCESS: 'GET_CARS_SUCCESS',
    GET_CARS_FAIL: 'GET_CARS_FAIL',
    SET_STATUSES: 'SET_STATUSES',
    SET_SELECTED_STATUS: 'SET_SELECTED_STATUS',
    OPEN_CAR: 'OPEN_CAR',
    OPEN_CAR_SUCCESS: 'OPEN_CAR_SUCCESS',
    OPEN_CAR_FAIL: 'OPEN_CAR_FAIL',
    CLOSE_CAR: 'CLOSE_CAR',
    CLOSE_CAR_SUCCESS: 'CLOSE_CAR_SUCCESS',
    CLOSE_CAR_FAIL: 'CLOSE_CAR_FAIL',
    EDIT_SERVICE: 'EDIT_SERVICE',
    EDIT_SERVICE_SUCCESS: 'EDIT_SERVICE_SUCCESS',
    EDIT_SERVICE_FAIL: 'EDIT_SERVICE_FAIL',
    SET_DAMAGE: 'SET_DAMAGE',
    REMOVE_DAMAGE: 'REMOVE_DAMAGE',
    SET_DAMAGES: 'SET_DAMAGES',
    SET_EXPANSION: 'SET_EXPANSION',
    SET_ERRORS: 'SET_ERRORS',
    SET_QUIZ: 'SET_QUIZ',
    NEXT_QUIZ: 'NEXT_QUIZ',
    // SKIP_ONE_QUIZ: 'SKIP_ONE_QUIZ',
    PREVIOUS_QUIZ: 'PREVIOUS_QUIZ',
    // GET_ONE_CAR: 'GET_ONE_CAR',
    // GET_ONE_CAR_SUCCESS: 'GET_ONE_CAR_SUCCESS',
    // GET_ONE_CAR_FAIL: 'GET_ONE_CAR_FAIL',
};

export const emptyDamage = {
    // class: 'internal',
    part: '', // поврежденная деталь (наверно пока текстом) для enum придется все детали переводить на английский
    // damage_size: null, // опционально, [царапина 0.5 см, до 0.1 см 7 шт на формате А4, скол и т.д.] пока текстовое поле, заполнять вообще не будем пока
    // type: null, // [chip, scratch, damage, dirt, cut, crack, other]
    photo: [],
    description: '',
};

export const damagedParts = {
    internal: [
        'driver_seat',
        'passanger_seat',
        'back_seat',
        'upholstery_front_left_door',
        'upholstery_front_right_door',
        'upholstery_back_left_door',
        'upholstery_back_right_door',
        'upholstery_roof',
        'upholstery_weel',
        'front_board',
        'multimedia'],
    ext_left: [
        'front_fender',
        'front_door',
        'front_door_handle',
        'front_door_glass',
        'Rearview_mirror',
        'front_door_rack',
        'doorstep',
        'back_door',
        'back_door_handle',
        'back_door_glass',
        'back_door_rack',
        'back_door_fender',
        'front_wheel_tyres',
        'back_wheel_tyres',
    ],
    ext_right: [
        'front_fender',
        'front_door',
        'front_door_handle',
        'front_door_glass',
        'rearview_mirror',
        'front_door_rack',
        'doorstep',
        'back_door',
        'back_door_handle',
        'back_door_glass',
        'back_door_rack',
        'back_door_fender',
        'front_wheel_tyres',
        'back_wheel_tyres',
    ],
    ext_front: [
        'windshield',
        'left_rack',
        'right_rack',
        'windshield_wiper',
        'emblem',
        'hood',
        'bumper',
        'left_headlight',
        'right_headlight',
        'left_fog_lamp',
        'right_fog_lamp',
        'radiator_grill',
        'license_plate',
    ],
    ext_back: [
        'bumper',
        'wiper',
        'trunk',
        'trunk_glass',
        'left_lamp',
        'right_lamp',
        'left_reflector',
        'right_reflector',
        'license_plate',
        'emblem',
    ],
    // ext_top: [],
    // dashboard: [],
};

export const quizPages = {
    Dashboard: 'Dashboard',
    Start: 'Start',
    Documents: 'Documents',
    Accessories: 'Accessories',
    Internal: 'Internal',
    Smell: 'Smell',
    Wash: 'Wash',
    ExtLeft: 'ExtLeft',
    ExtBack: 'ExtBack',
    ExtRight: 'ExtRight',
    ExtFront: 'ExtFront',
    ExtRoof: 'ExtRoof',
    InspectionReport: 'InspectionReport',
    CheckNFill: 'CheckNFill',
    MissedRefueling: 'MissedRefueling',
    Refueling: 'Refueling',
    Finish: 'Finish',
}

export const quizPagesList = [
    {
        key: quizPages.Dashboard
    },
    {
        key: quizPages.Start
    },
    {
        key: quizPages.Documents
    },
    {
        key: quizPages.Accessories
    },
    {
        key: quizPages.Internal
    },
    {
        key: quizPages.Smell
    },
    {
        key: quizPages.Wash,
    },
    {
        key: quizPages.ExtLeft,
    },
    {
        key: quizPages.ExtBack,
    },
    {
        key: quizPages.ExtRight,
    },
    {
        key: quizPages.ExtFront,
    },
    {
        key: quizPages.ExtRoof,
    },
    {
        key: quizPages.InspectionReport,
        isHide: ({ withMileage, manufacturer }) => {
            if (manufacturer === "GENESIS") {
                return !withMileage;
            }
            return true;
        }
    },
    {
        key: quizPages.CheckNFill,
    },
    {
        key: quizPages.MissedRefueling,
        isHide: ({ showMissedRefuelPage }) => !showMissedRefuelPage
    },
    {
        key: quizPages.Refueling,
        isHide: ({ skipRefuel }) => skipRefuel
    },
    {
        key: quizPages.Finish,
    },
]

export const setQuizPagesListByCar = (state) => quizPagesList.filter(({ isHide }) => isHide ? !isHide(state): true );

const initialState = {
    cars: [],
    carsFetching: false,
    carsError: null,
    singleCar: null,
    openCarsFetching: false,
    closeCarsFetching: false,
    serviceFetching: false,
    statuses: {},
    quiz: 0,
    quizPagesList,
    service: {},
    damages: [],
    damages_old: [],
    expansion: [],
    damagesFieldsReady: [],
    // damagesErrors: [],
    noErrors: false,
    selectedStatus: 'ALL',
    isPreCheck: false, // true, если подготовка перед сессией
    skipRefuel: false,
    showMissedRefuelPage: false,
    withMileage: false, // Машина Б/У
    manufacturer: '',
    techPhotos: null,
};

export default function user(state = initialState, action = {}) {
    switch (action.type) {
        case actions.GET_CARS:
            return {
                ...state,
                carsFetching: true,
            };
        case actions.GET_CARS_SUCCESS:
            return {
                ...state,
                carsFetching: false,
                cars: action.payload.vehicleList,
                fuelCards: action.payload.fuelCards,
                lastUsedFuelCard: action.payload.lastUsedFuelCard,
                carsError: null,
            };
        case actions.GET_CARS_FAIL:
            return {
                ...state,
                carsFetching: false,
                cars: [],
                carsError: action.payload,
            };
        case actions.SET_STATUSES:
            return {
                ...state,
                statuses: action.payload,
            };
        case actions.SET_SELECTED_STATUS:
            return {
                ...state,
                selectedStatus: action.payload,
            };
        case actions.OPEN_CAR:
            return {
                ...state,
                openCarsFetching: true,
            };
        case actions.OPEN_CAR_SUCCESS:
            const { payload } = action;
            const { skipRefuel, showMissedRefuelPage, withMileage, manufacturer } = payload;

            return {
                ...state,
                quizPagesList: setQuizPagesListByCar({ skipRefuel, showMissedRefuelPage, withMileage, manufacturer }),
                openCarsFetching: false,
                service: payload.service,
                damages_old: payload.damages_old,
                damages: payload.damages,
                openCarsError: null,
                isPreCheck: payload.isPreCheck,
                skipRefuel,
                showMissedRefuelPage,
                techPhotos: payload.techPhotos,
            };
        case actions.OPEN_CAR_FAIL:
            return {
                ...state,
                openCarsFetching: false,
                service: {},
                openCarsError: action.payload,
            };
        case actions.CLOSE_CAR:
            return {
                ...state,
                closeCarsFetching: true,
            };
        case actions.CLOSE_CAR_SUCCESS:
            return {
                ...state,
                closeCarsFetching: false,
                service: {},
                damages_old: [],
                damages: [],
                quiz: 0,
                quizPagesList,
                closeCarsError: null,
                isPreCheck: null,
                skipRefuel: null,
                showMissedRefuelPage: null,
                techPhotos: null,
            };
        case actions.CLOSE_CAR_FAIL:
            return {
                ...state,
                closeCarsFetching: false,
                service: {},
                closeCarsError: action.payload,
            };
        case actions.EDIT_SERVICE:
            return {
                ...state,
                serviceFetching: true,
            };
        case actions.EDIT_SERVICE_SUCCESS:
            return {
                ...state,
                serviceFetching: false,
                service: action.payload,
                // service: { ...state.service, ...action.payload },
                // openCarsError: null,
            };
        case actions.EDIT_SERVICE_FAIL:
            return {
                ...state,
                serviceFetching: false,
                service: {},
                // openCarsError: action.payload,
            };
        case actions.SET_DAMAGE:
            return {
                ...state,
                damages: action.payload,
            };
        case actions.REMOVE_DAMAGE:
            return {
                ...state,
                damages: action.payload,
            };
        case actions.SET_DAMAGES:
            return {
                ...state,
                damages: action.payload,
                // service: { ...state.service, damages: action.payload },
            };
        case actions.SET_EXPANSION:
            return {
                ...state,
                expansion: action.payload,
            };
        case actions.SET_ERRORS:
            return {
                ...state,
                damagesFieldsReady: action.damagesFieldsReady,
                // damagesErrors: action.damagesErrors,
                noErrors: action.noErrors,
            };

        case actions.SET_QUIZ:
            return {
                ...state,
                quiz: action.payload,
            };
        case actions.NEXT_QUIZ:
            const quiz = state.quiz + 1;
            return {
                ...state,
                quiz,
                // Сохраняет шаг опроса на сервере
                service: { ...state.service, quiz_state: quiz },
            };
        case actions.PREVIOUS_QUIZ:
            const quizPrev = state.quiz <= 1 ? 1 : state.quiz - 1;
            return {
                ...state,
                quiz: quizPrev,
                service: { ...state.service, quiz_state: quizPrev },
            };
        default:
            return state;
    }
}
