import { createMuiTheme } from '@material-ui/core/styles';

export const authTheme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily: 'HyundaiSansText',
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00aad2',
      // main: '#ff0000',
      dark: 'rgba(255, 255, 255, 0.3)',
      contrastText: '#ffffff',
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: '#f50057',
      // dark: 'rgba(255, 255, 255, 0.3)',
      contrastText: '#ffffff',
    },
  },
});

export const technicianTheme = createMuiTheme({
  typography: {
    // Use the system font.
    fontFamily: 'HyundaiSansText',
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#00aad2',
      dark: '#028bab',
      contrastText: '#ffffff',
    },
    // primary: {
    //   light: blue[50],
    //   main: '#fff',
    //   dark: blue[200],
    //   contrastText: '#333355',
    // },
    // primary: blue,
    secondary: {
      light: '#ff3b81',
      main: '#f50057',
      dark: '#de0150',
      contrastText: '#ffffff',
    },
    default: {
      light: '#33bbdb',
      main: '#00aad2',
      dark: '#007693',
    },
    success: {
      main: '#ff0000',
      contrastText: '#0000ff',
    },
  },
});
